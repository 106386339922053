import axios from 'axios';
// import { store } from '../store/store';


export const getToken = () => {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);

    return tokenDetails?.token;
};

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

axiosInstance.interceptors.request.use((config) => {
    // const state = store?.getState();
    const token = getToken();

    config.headers = config.headers || {};
    if (token && token !== "") {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});

export default axiosInstance;
