import React, { Fragment, useEffect, useState } from 'react'
import PageLoader from '../../layouts/PageLoader'
import PageTitle from '../../layouts/PageTitle'
import Select from "react-select";
import { getMealType, getMenuItemsByMealType, saveMenuItemsByMealType } from '../../../helper/BackendHelper';
import { Accordion, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { notifyError } from '../../../helper/notification';



const AddMenu = () => {

    const [isLoading, setIsLoading] = useState(false);

    const [mealTypeList, setMealTypeList] = useState([])
    const [mealTypeListOption, setMealTypeListOption] = useState([])
    const [selectedMealType, setSelectedMealType] = useState()

    const [formData, setFormData] = useState({ deleteList: [] })

    const [menuData, setMenuData] = useState();

    const router = useHistory();


    useEffect(() => {

        getMealType().then((res) => {

            if (res?.status == "success") {
                setMealTypeList(res?.data)
            }

        })

    }, [])

    useEffect(() => {
        setMealTypeListOption(mealTypeList.map((mealType) => ({
            value: mealType.lable,
            label: mealType.lable,
        })))

        setSelectedMealType(mealTypeList[0])

        setFormData({ ...formData, meal_type_id: mealTypeList[0]?.value })

    }, [mealTypeList])

    useEffect(() => {
        setIsLoading(true)
        if (selectedMealType) {
            getMenuItemsByMealType(selectedMealType?.value).then((res) => {

                if (res?.status == "success") {
                    setMenuData(res?.data)
                    setFormData({ ...formData, menus: res?.data })
                    setIsLoading(false)

                }

            })
        }

    }, [selectedMealType])

    const addNewMenuItem = (menuIndex) => {

        let newMenu = [...formData?.menus]


        let newMenuItems = [...formData?.menus[menuIndex]?.items, {
            "name": "",
            "price": 0
        }];

        newMenu[menuIndex].items = newMenuItems;

        setFormData({ ...formData, menus: newMenu })

    }


    const removeMenuItem = ({ menuIndex, itemIndex }) => {
        let newMenu = [...formData?.menus]

        let newMenuItems = [...formData?.menus[menuIndex]?.items];

        let deleteList = newMenuItems[itemIndex]?.id ? [...formData?.deleteList, newMenuItems[itemIndex]] : [...formData?.deleteList]

        newMenuItems.splice(itemIndex, 1)

        newMenu[menuIndex].items = newMenuItems;

        setFormData({ ...formData, menus: newMenu, deleteList })
    }

    const onChange = ({ menuIndex, itemIndex, value, name }) => {

        let newMenu = [...formData?.menus]

        let newMenuItems = [...formData?.menus[menuIndex]?.items];

        let newItem = { ...formData?.menus[menuIndex]?.items[itemIndex], [name]: value }

        newMenuItems[itemIndex] = newItem;

        newMenu[menuIndex].items = newMenuItems;

        setFormData({ ...formData, menus: newMenu })

    }

    const formSubmit = async (e) => {
        e.preventDefault();
        // console.log({ formData });

        setIsLoading(true);


        const res = await saveMenuItemsByMealType(selectedMealType?.value, formData);

        if (res?.status === 'success') {

            swal(`Menu Saved`, ``, "success")
            setIsLoading(false);
            router.push('/existing-menu')

        } else {

            if (res.isAxiosError) {

                if (res?.response?.data?.errors) {
                    Object.values(res?.response?.data?.errors).map((errors) => errors.map((error) => notifyError(error)))
                } else {
                    notifyError(res?.response?.data?.message)
                }
            } else {
                swal("Oops", `${res?.response?.data?.message ? res?.response?.data?.message : res?.message}`, "error")
            }
            setIsLoading(false);

        }

    }


    // console.log({ formData });


    return (
        <Fragment>
            <PageLoader isLoading={isLoading} >
                {/* <PageTitle motherMenu="Meal Menu" /> */}
                <form onSubmit={formSubmit}>
                    <fieldset disabled={isLoading}>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="card">
                                    <div className="container-fluid" style={{ display: "block", padding: "35px" }}>
                                        <div class="row" style={{ alignItems: "center" }} >
                                            <div class="col-md-7">
                                                <h4 className="card-title" style={{ margin: "0" }} >Meal Menu Details</h4>
                                            </div>
                                            <div class="col-md-2">
                                                <Button type='submit' variant="primary">{isLoading ? "Saving...." : (<><i class="fa fa-save"></i> Save Menu</>)}</Button>

                                            </div>

                                            <div class="col-md-3 float-right">
                                                <Select
                                                    // defaultValue={selectedOption}
                                                    onChange={(data) => setSelectedMealType(mealTypeList.find((item) => item?.lable == data.value))}
                                                    options={mealTypeListOption}
                                                    style={{
                                                        lineHeight: "40px",
                                                        color: "#7e7e7e",
                                                        paddingLeft: " 15px",
                                                    }}
                                                    placeholder="Select Meal Type"
                                                    name='role'
                                                    value={
                                                        mealTypeListOption.filter(option =>
                                                            option.label === selectedMealType.lable)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {
                                formData?.menus?.map((menu, index) => (
                                    <div className="col-xl-6 col-lg-6" key={index}>
                                        <div className="card">
                                            <div className="card-header container-fluid" style={{ display: "block" }}>
                                                <div class="row">
                                                    <div class="col-md-10">
                                                        <h4 className="card-title">{menu?.name}</h4>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="card-body">

                                                <Accordion className="accordion accordion-rounded-stylish accordion-bordered" defaultActiveKey="0">
                                                    {menu?.items?.map((item, i) => (
                                                        <div className="row">
                                                            <div className="col-lg-10">
                                                                <Accordion.Item key={i} eventKey={`${i}`}>
                                                                    <Accordion.Header className="accordion-header accordion-header-primary">
                                                                        {(item?.name !== "" && item?.name) ? item?.name : `Menu Item ${i + 1}`}
                                                                        <span className="accordion-header-indicator "></span>

                                                                    </Accordion.Header>
                                                                    <Accordion.Collapse eventKey={`${i}`} className="accordion__body">
                                                                        <div className="accordion-body"><div className='event'>
                                                                            <div className="row">
                                                                                <div className="col-lg-6">
                                                                                    <div className="form-group mb-3">
                                                                                        <label htmlFor="">Menu Item Name</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control input-default "
                                                                                            placeholder={"Name of the Menu Item"}
                                                                                            name={`name`}
                                                                                            required
                                                                                            value={item?.name}
                                                                                            onChange={(e) => onChange({ itemIndex: i, menuIndex: index, name: e.currentTarget.name, value: e.currentTarget.value })}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-lg-6">
                                                                                    <div className="form-group mb-3">
                                                                                        <label htmlFor="">Units</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control input-default "
                                                                                            placeholder={"Units (Optional)"}
                                                                                            name={`units`}

                                                                                            value={item?.units}
                                                                                            onChange={(e) => onChange({ itemIndex: i, menuIndex: index, name: e.currentTarget.name, value: e.currentTarget.value })}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-lg-6">
                                                                                    <label htmlFor="">Price</label>
                                                                                    <div className="input-group mb-3">
                                                                                        <div className="input-group-text">₹</div>

                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control"
                                                                                            placeholder="Price"
                                                                                            name='price'
                                                                                            required
                                                                                            onChange={(e) => onChange({ itemIndex: i, menuIndex: index, name: e.currentTarget.name, value: e.currentTarget.value })}
                                                                                            min="1"
                                                                                            value={item?.price}
                                                                                        />
                                                                                    </div>
                                                                                </div>


                                                                            </div>

                                                                        </div>
                                                                        </div>
                                                                    </Accordion.Collapse>
                                                                </Accordion.Item>
                                                            </div>
                                                            <div className="col-lg-2">
                                                                <Button variant="danger" onClick={() => removeMenuItem({ itemIndex: i, menuIndex: index })}><i class="fa fa-trash"></i></Button>
                                                            </div>
                                                        </div>

                                                    ))}
                                                </Accordion>
                                            </div>
                                            <div class="card-footer text-muted">
                                                <Button onClick={() => addNewMenuItem(index)} variant="primary">Add New Menu Item</Button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                    </fieldset>
                </form>


            </PageLoader>
        </Fragment>
    )
}

export default AddMenu