import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUsers, getUsersByPage } from "../../helper/BackendHelper";


const VolunteerInitialLoad = createAsyncThunk(
    //action type string
    "volunteers/initial",
    // callback function
    async (data, thunkAPI) => {



        const response = await getUsers();


        // console.log({ res });

        if (response?.status === 500) {
            return thunkAPI.rejectWithValue("Server Error");
        } else {
            if (response?.data) {


                return response.data;
            }
        }
    }
);

const VolunteerPaggination = createAsyncThunk(
    //action type string
    "volunteers/paggination",
    // callback function
    async (data, thunkAPI) => {



        const response = await getUsersByPage(data);


        // console.log({ res });

        if (response?.status === 500) {
            return thunkAPI.rejectWithValue("Server Error");
        } else {
            if (response?.data) {


                return response.data;
            }
        }
    }
);

export { VolunteerInitialLoad, VolunteerPaggination }