import { download, get, post, put, uplaod } from "./ApiHelper";

export const userLogout = () => post(`/logout`);

export const getPaymentMethods = () => get(`/payment-methods`);
export const getEventTypes = () => get(`/event-types`);
export const getDashBoard = () => get(`/dashboard`);

export const getDoners = () => get(`/doners`);
export const getPayments = () => get(`/payment`);
export const getPayment = (id) => get(`/payment/${id}`);
export const getVolunteers = () => get(`/volunteers`);
export const getUsers = () => get(`/user`);
export const getDoner = (id) => get(`/user/${id}`);
export const getCampaignById = (id) => get(`/campaign/${id}`);
export const getDonersOptions = () => get(`/options/doner`);
export const getDonationOptions = (id) => get(`/donation/for-payment/${id}`);
export const getRoles = () => get(`/options/roles`);
export const getMealsTable = () => get(`/meals`);
export const getUsersByPage = ({ q, pageNumber, role }) => get(`/user?q=${q ? q : ""}&page=${pageNumber ? pageNumber : ""}&role=${role ? role : ""}`);
export const getVolunteersByPage = ({ q, pageNumber }) => get(`/volunteers?q=${q ? q : ""}&page=${pageNumber ? pageNumber : ""}`);
export const getDonersByPage = ({ q, pageNumber }) => get(`/doners?q=${q ? q : ""}&page=${pageNumber ? pageNumber : ""}`);
export const getCampaign = () => get(`/campaign`);
export const getEvent = (id) => get(`/event/${id}`);
export const getEventDetails = (id) => get(`/event/details/${id}`);
export const getCampaignEvents = () => get(`/campaign/events`);
export const getMealType = () => get(`/options/meal-type`);
export const getMenuItemsByMealType = (id) => get(`/meal-menu/by-meal-type/${id}`);
export const saveMenuItemsByMealType = (id, data) => post(`/meal-menu/by-meal-type/${id}`, data);
export const getCampaignByPage = ({ q, pageNumber }) => get(`/campaign?q=${q ? q : ""}&page=${pageNumber ? pageNumber : ""}`);
export const getPaymentsByPage = ({ q, pageNumber, dateQuary }) => get(`/payment?q=${q ? q : ""}&page=${pageNumber ? pageNumber : ""}&fromDate=${dateQuary?.fromDate ? dateQuary?.fromDate : ""}&toDate=${dateQuary?.toDate ? dateQuary?.toDate : ""}`);

export const trigerMails = () => get(`/queue/mail`);


export const addUser = (data) => post(`/user`, data);
export const addEvent = (data) => post(`/event`, data);
export const addDonation = (data) => post(`/donation/add`, data);
export const addPayment = (data) => post(`/payment`, data);

export const sendPaymentReceipt = (id) => post(`/receipt/payment/${id}`);

export const editUser = (data, id) => put(`/user/${id}`, data);

export const mealDateUpdate = (data, id) => put(`/connect/meal/${id}`, data);

export const updateEvent = (id, data) => put(`/event/${id}`, data);
export const imageUploadEvent = (id, data) => uplaod(`/event/${id}`, data);
export const imageUploadDhan = (id, data, time) => uplaod(`/connect/dhan/${id}?time=${time}`, data);

export const updateCampaign = (id, data) => put(`/campaign/${id}`, data);
export const getCampaignOptions = () => get(`/options/campaign`);
export const verifyPassToken = (token) => get(`/verify-token/${token}`);
export const createPassword = (token, data) => post(`/create-password/${token}`, data);
export const changePassword = (data) => post(`/user/change-password`, data);



export const getEvents = (dateQuary) => get(`/event?fromDate=${dateQuary?.fromDate ? dateQuary?.fromDate : ""}&toDate=${dateQuary?.toDate ? dateQuary?.toDate : ""}`);

export const getDonetions = () => get(`/donation`);
export const addCampaign = (data) => post(`/campaign`, data);
export const getDonetion = (id) => get(`/donation/${id}`);
export const getConnect = (id, time) => get(`/connect/${id}?date=${time}`);
export const getMealTime = () => get(`/settings/mealtime`);
export const getConnectList = () => get(`/connect`);
export const getDonetionReport = (dateQuary) => get(`report/donation?fromDate=${dateQuary?.fromDate ? dateQuary?.fromDate : ""}&toDate=${dateQuary?.toDate ? dateQuary?.toDate : ""}`);
export const downloadDonetionReport = (dateQuary) => download(`report/donation/download?fromDate=${dateQuary?.fromDate ? dateQuary?.fromDate : ""}&toDate=${dateQuary?.toDate ? dateQuary?.toDate : ""}`);
export const getDonorReport = () => get(`report/donor`);
export const downloadDonorReport = () => download(`report/donor/download`);

export const payDonetion = (id, data) => put(`/donation/payment/${id}`, data);
export const addDonetionToPayment = (paymentId, donationId) => put(`/payment/${paymentId}?donation=${donationId}`);
export const updateMealTime = (price, id) => put(`/settings/mealtime/${id}`, { price });
export const paymentConfirm = (razorpay_payment_id, donation) => post(`/payment/confirm/${razorpay_payment_id}`, donation);
export const paymentConfirmIppopay = (order_id) => post(`/payment/confirm/ippopay/${order_id}`);

export const getDonetionsByPage = ({ q, dateQuary, pageNumber, statusQuary }) => get(`/donation?q=${q ? q : ""}&fromDate=${dateQuary?.fromDate ? dateQuary?.fromDate : ""}&toDate=${dateQuary?.toDate ? dateQuary?.toDate : ""}&page=${pageNumber ? pageNumber : ""}&status=${statusQuary != undefined ? statusQuary ? 1 : 0 : ""}`);