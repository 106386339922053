import React, { Fragment, useEffect, useState } from 'react'
import PageTitle from '../../layouts/PageTitle'
import Select from "react-select";
import { useCallback } from "react";
import useRazorpay from "react-razorpay";


import countries from '../../../mock/countries.json';
import { Button } from 'react-bootstrap';
import swal from 'sweetalert';
import { addDonation, getCampaignOptions, getEventTypes, getPaymentMethods, paymentConfirm, paymentConfirmIppopay, trigerMails } from '../../../helper/BackendHelper';
import { useHistory } from 'react-router-dom';
import PageLoader from '../../layouts/PageLoader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DonerSelect from '../../components/Common/DonerSelect';
import moment from 'moment';
import SelectCampaignWithEvent from '../../components/Common/SelectCampaignWithEvent';
import PludgeMeal from '../../components/Common/PludgeMeal';
import { notifyError } from '../../../helper/notification';
import { Ippopay } from 'react-ippopay';

const initialData = {}

const paymentMethodSInitial = [
    {
        "value": 1,
        "lable": "Online"
    },
    {
        "value": 2,
        "lable": "UPI"
    },
    {
        "value": 3,
        "lable": "Cheque"
    },
    {
        "value": 4,
        "lable": "Wire Transfer"
    }
]

const DonetionAdd = () => {

    // console.log({countries});
    const Razorpay = useRazorpay();
    const [formData, setformData] = useState(initialData);
    const [campaignData, setCampaignData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isEvent, setIsEvent] = useState(false);
    const [eventDate, setEventDate] = useState(new Date());
    const [paymentMethods, setPaymentMethods] = useState(paymentMethodSInitial);
    const [paymentOptions, setPaymentOptions] = useState([])
    const [eventTypes, setEventTypes] = useState([])
    const [eventOptions, setEventOptions] = useState([])
    const [isMealOpen, setIsMealOpen] = useState(false);

    const [isIppopayOpen, setIsIppopayOpen] = useState(false);
    const [ippopayOrderId, setIppopayOrderId] = useState();

    const [isCampaign, setIsCampaign] = useState(true);

    // console.log({ isCampaign });

    const [userSelect, setUserSelect] = useState(false);
    const [selectedUser, setSelectedUser] = useState();

    const router = useHistory();

    const onChangeValue = (e) => {
        setformData({ ...formData, [e?.currentTarget?.name]: e?.currentTarget?.value })
    }

    const countriesOptions = countries.map((country) => ({
        value: country.name,
        label: country.name,
    }))

    const onDonerSelect = (data) => {

        let donerData = {
            "name": data?.name,
            "email": data?.email,
            "address": data?.address?.address,
            "city": data?.address?.city,
            "pincode": data?.address?.pincode,
            "country": data?.address?.country,
            "phone": data?.phone,
            "pan": data?.pan,
            "whatsapp": data?.whatsapp
        }

        setformData({ ...formData, ...donerData })

    }

    // console.log({ formData });



    useEffect(() => {

        setIsLoading(true);
        initialLoad().then(() => setIsLoading(false))
        if (window) {
            window.addEventListener('message', ippopayHandler);
        }

    }, [])

    const initialLoad = async () => {
        getPaymentMethods().then((res) => {
            if (res?.status === "success") {
                setPaymentMethods(res?.data)
            }
        })
        getEventTypes().then((res) => {
            if (res?.status === "success") {
                setEventTypes(res?.data)
            }
        })

        getCampaignOptions().then((res) => {

            if (res?.status == "success") {
                setCampaignData(res?.data)
            }

        })
    }

    useEffect(() => {
        setPaymentOptions(paymentMethods.map((payment) => ({
            value: payment.lable,
            label: payment.lable,
        })))

    }, [paymentMethods])

    useEffect(() => {
        setEventOptions(eventTypes.map((eventType) => ({
            value: eventType.lable,
            label: eventType.lable,
        })))

    }, [eventTypes])

    useEffect(() => {
        if (isEvent && !isCampaign) {
            setformData({ ...formData, event_date: moment(eventDate).format("YYYY-MM-DD"), event: null })
        }

    }, [isEvent, isCampaign])



    // console.log({ formData });


    const formSubmit = async (e) => {
        e.preventDefault();

        console.log({ formData });
        setIsLoading(true);

        const res = await addDonation(formData);

        if (res?.status === 'success') {

            if (res?.data?.onlinePayment) {

                // handlePayment(res?.data?.donation);
                handleIppopayPayment(res?.data?.donation);

            } else {
                swal(`Donations added`, ``, "success")
                setIsLoading(false);
                router.push('/list-of-donations')
                trigerMails();
            }



        } else {

            if (res.isAxiosError) {

                if (res?.response?.data?.errors) {
                    Object.values(res?.response?.data?.errors).map((errors) => errors.map((error) => notifyError(error)))
                } else {
                    notifyError(res?.response?.data?.message)
                }
            } else {
                swal("Oops", `${res?.response?.data?.message ? res?.response?.data?.message : res?.message}`, "error")
            }
            setIsLoading(false);

        }
    }

    const onEventDateSelect = (date) => {

        setEventDate(new Date(date))
        setformData({ ...formData, event_date: moment(date).format("YYYY-MM-DD") })
    }

    const handlePayment = useCallback(async (donation) => {

        // console.log({ donation });

        const options = {
            key: process.env.REACT_APP_RAZORPAY_API_KEY,
            amount: donation?.amount,
            currency: "INR",
            name: "Ech Kitchen",
            description: `I am pleased to pledge ₹${donation?.amount} towards Kathir Dhan.`,
            image: "https://www.ecokitchen.org/eco/kathirdhan/images/eco-kitchen-logo.jpg",
            order_id: donation?.payment?.order_id,
            handler: (res) => {
                // console.log(res);
                paymentConfirm(res?.razorpay_payment_id, donation).then((res) => {
                    if (res?.status === 'success') {
                        swal(`Donations added`, ``, "success")
                        setIsLoading(false);
                        router.push('/list-of-donations')
                    } else {

                        if (res.isAxiosError) {

                            if (res?.response?.data?.errors) {
                                Object.values(res?.response?.data?.errors).map((errors) => errors.map((error) => notifyError(error)))
                            } else {
                                notifyError(res?.response?.data?.message)
                            }
                        } else {
                            swal("Oops", `${res?.response?.data?.message ? res?.response?.data?.message : res?.message}`, "error")
                        }
                        setIsLoading(false);

                    }
                })
            },
            prefill: {
                name: donation?.donate_by?.name,
                email: donation?.donate_by?.email,
            },
            theme: {
                color: "#5c9c26",
            },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
    }, [Razorpay]);

    const ippopayHandler = (e) => {
        if (e.data.status == 'success') {
            paymentConfirmIppopay(e?.data?.order_id).then((res) => {
                if (res?.status === 'success') {
                    swal(`Donations added`, ``, "success")
                    router.push('/list-of-donations')
                    trigerMails();

                } else {

                    if (res.isAxiosError) {

                        if (res?.response?.data?.errors) {
                            Object.values(res?.response?.data?.errors).map((errors) => errors.map((error) => notifyError(error)))
                        } else {
                            notifyError(res?.response?.data?.message)
                        }
                    } else {
                        swal("Oops", `${res?.response?.data?.message ? res?.response?.data?.message : res?.message}`, "error")
                    }


                }
            })
        }
        if (e.data.status == 'failure') {
            console.log(e.data)
        }
    }

    const handleIppopayPayment = (donation) => {

        // console.log({ donation });

        setIsIppopayOpen(true);
        setIsLoading(false);
        setIppopayOrderId(donation?.payment?.order_id)

    };

    const onMealSelect = (data) => {
        if (data?.amount > 0 && data?.pax > 0) {
            setformData({ ...formData, meals: data, amount: data?.amount })
        }
    }


    const onAmountChange = (e) => {
        // setformData({ ...formData, meals: null, amount: e?.currentTarget?.value })
        setformData({ ...formData, amount: e?.currentTarget?.value })
    }



    // console.log({ formData });

    return (
        <Fragment>
            {
                ippopayOrderId &&
                (<Ippopay ippopayOpen={isIppopayOpen} ippopayClose={true} order_id={ippopayOrderId} public_key={process.env.REACT_APP_IPPOPAY_PUBLIC_KEY} />)
            }

            <PageLoader isLoading={isLoading} >
                {/* <PageTitle activeMenu="Doner" motherMenu="Add New Doner" /> */}
                <DonerSelect close={() => setUserSelect(false)} isOpen={userSelect} onSelelct={onDonerSelect} />
                <PludgeMeal close={() => setIsMealOpen(false)} isOpen={isMealOpen} onSelelct={onMealSelect} />

                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header container-fluid" style={{ display: "block" }}>
                                <div class="row">
                                    <div class="col-md-7">
                                        <h4 className="card-title">Donation Details</h4>
                                    </div>
                                    <div class="col-md-3 float-right">
                                        <button class="btn btn-primary"
                                            onClick={() => setUserSelect(true)}
                                        >Donation By Existing Donor</button>
                                    </div>
                                    <div class="col-md-2 float-right">
                                        <button class="btn btn-primary"
                                            onClick={() => setIsMealOpen(true)}
                                        >Add Meals</button>
                                    </div>

                                </div>
                            </div>
                            <div className="card-body">
                                <div className="basic-form">
                                    <form onSubmit={formSubmit}>
                                        <fieldset disabled={isLoading}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="col-auto">
                                                        <label className="sr-only">Donation Amount</label>
                                                        <div className="input-group mb-3">
                                                            <div className="input-group-text">₹</div>

                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                placeholder="Amount"
                                                                name='amount'
                                                                required
                                                                onChange={onAmountChange}
                                                                min="1"
                                                                value={formData?.amount}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">

                                                        <Select
                                                            // defaultValue={selectedOption}
                                                            onChange={(data) => setformData({ ...formData, paymentMethod: paymentMethods.find((item) => item?.lable == data.value)?.value })}
                                                            options={paymentOptions}
                                                            style={{
                                                                lineHeight: "40px",
                                                                color: "#7e7e7e",
                                                                paddingLeft: " 15px",
                                                            }}
                                                            placeholder="Select Your Payment Method"
                                                            name='paymentMethod'
                                                        />

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control input-default "
                                                            placeholder="Name"
                                                            name='name'
                                                            required
                                                            onChange={onChangeValue}
                                                            value={formData?.name}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="email"
                                                            className="form-control input-default "
                                                            placeholder="Email"
                                                            name='email'
                                                            required
                                                            onChange={onChangeValue}
                                                            value={formData?.email}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="number"
                                                            className="form-control input-default "
                                                            placeholder="Mobile Number"
                                                            name='phone'
                                                            required
                                                            onChange={onChangeValue}
                                                            value={formData?.phone}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="number"
                                                            className="form-control input-default "
                                                            placeholder="WhatsApp Number"
                                                            name='whatsapp'

                                                            onChange={onChangeValue}
                                                            value={formData?.whatsapp}

                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control input-default "
                                                            placeholder="PAN Number (optional)"
                                                            name='pan'
                                                            onChange={onChangeValue}
                                                            value={formData?.pan}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">

                                                        <Select

                                                            onChange={(data) => setformData({ ...formData, country: data?.value })}
                                                            options={countriesOptions}
                                                            style={{
                                                                lineHeight: "40px",
                                                                color: "#7e7e7e",
                                                                paddingLeft: " 15px",
                                                            }}
                                                            placeholder="Select Country"
                                                            name='country'
                                                            value={
                                                                countriesOptions.filter(option =>
                                                                    option.label === formData?.country)
                                                            }
                                                        />

                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <textarea name="address" id="address" className="form-control input-default "
                                                            placeholder="Address" rows="5"
                                                            onChange={onChangeValue}
                                                            value={formData?.address}

                                                        >

                                                        </textarea>

                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control input-default "
                                                            placeholder="City"
                                                            name='city'
                                                            required
                                                            onChange={onChangeValue}
                                                            value={formData?.city}

                                                        />
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="number"
                                                            className="form-control input-default "
                                                            placeholder="Pincode"
                                                            name='pincode'
                                                            required
                                                            onChange={onChangeValue}
                                                            value={formData?.pincode}

                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="col-auto">

                                                        <div className="input-group mb-3">
                                                            <textarea name="message" id="message" className="form-control input-default "
                                                                placeholder="Comments" rows="5"
                                                                onChange={onChangeValue}
                                                            >

                                                            </textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="input-group mb-3">
                                                        <div className="form-check">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="isEvent"
                                                                onChange={(e) => {
                                                                    setIsEvent(e.currentTarget.checked)

                                                                }}
                                                            />
                                                            <label className="form-check-label">
                                                                Path of Payment
                                                            </label>
                                                        </div>


                                                    </div>
                                                    {
                                                        isEvent && (
                                                            <div className="form-group mb-0">
                                                                <div className="radio">
                                                                    <label>
                                                                        <input type="radio" name="isCampaign" checked={isCampaign} value={true} onChange={(e) => setIsCampaign(e.currentTarget.value === "true" ? true : false)} />  Campaign
                                                                    </label>
                                                                </div>
                                                                <div className="radio">
                                                                    <label>
                                                                        <input type="radio" name="isCampaign" checked={!isCampaign} value={false} onChange={(e) => setIsCampaign(e.currentTarget.value === "true" ? true : false)} />  Milestones
                                                                    </label>
                                                                </div>
                                                                {/* <div className="radio">
                                                                    <label>
                                                                        <input type="radio" name="isCampaign" value={'no'} onChange={(e) => setIsEvent(e.currentTarget.value === "no" ? false : true)} />  Magic Pot
                                                                    </label>
                                                                </div> */}

                                                            </div>
                                                        )
                                                    }

                                                </div>

                                            </div>



                                            {
                                                isEvent && (
                                                    <>

                                                        {
                                                            isCampaign ? (<SelectCampaignWithEvent onSelect={(data) => setformData({ ...formData, ...data })} campaignData={campaignData} />) : (
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col-lg-6">
                                                                            <label htmlFor="">Select Event Date</label>
                                                                            <div className="form-group mb-3">
                                                                                <DatePicker className="form-control" selected={eventDate} onSelect={onEventDateSelect} placeholderText="Select Event Date" dateFormat="yyyy-MM-dd" name='event_date' />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <div className="form-group mb-3">
                                                                                <label htmlFor="">Occasion detail</label>
                                                                                <Select
                                                                                    // defaultValue={selectedOption}
                                                                                    onChange={(data) => setformData({ ...formData, event_type: eventTypes.find((item) => item?.lable == data.value)?.value })}
                                                                                    options={eventOptions}
                                                                                    style={{
                                                                                        lineHeight: "40px",
                                                                                        color: "#7e7e7e",
                                                                                        paddingLeft: " 15px",
                                                                                    }}
                                                                                    placeholder="Occasion detail"
                                                                                    name='event_type'
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-lg-6">
                                                                            <div className="form-group mb-3">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control input-default "
                                                                                    placeholder={formData?.event_type === 2 ? "Name of the person" : "Name of the person"}
                                                                                    name='event_name'
                                                                                    required
                                                                                    onChange={onChangeValue}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <div className="form-group mb-3">
                                                                                <textarea name="event_description" id="event_description" className="form-control input-default "
                                                                                    placeholder={`Describe about ${formData?.event_type === 2 ? "Person" : "person"} `} rows="5"
                                                                                    onChange={onChangeValue}

                                                                                >
                                                                                </textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }


                                                    </>
                                                )
                                            }

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <Button type='submit' variant="primary">{isLoading ? "Adding...." : "Add Donation"}</Button>

                                                </div>

                                            </div>
                                        </fieldset>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageLoader>

        </Fragment>
    )
}

export default DonetionAdd