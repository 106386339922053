export const numberFormater = new Intl.NumberFormat('en-US', { notation: 'compact' });

export const curencyFormater = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'INR', notation: 'compact' })
export const curencyFormaterNarmal = new Intl.NumberFormat('en-US', {
    style: 'currency', currency: 'INR', notation: 'standard', minimumFractionDigits: 1,
    maximumFractionDigits: 1,
})

export const sumDonationAmount = (donations) => {
    var total = 0;
    for (let i = 0; i < donations?.length; i++) {  //loop through the array

        // console.log(donations[i]);

        if (!(donations[i]?.payment_method_id === 1 && donations[i]?.is_paid === 0)) {
            total += donations[i]?.amount;  //Do the math!
        }



    }

    return total;
}

export const getCurrentFinancialYear = (date) => {
    var fiscalyear = "";
    var today = new Date(date);
    if ((today.getMonth() + 1) <= 3) {
        fiscalyear = (today.getFullYear() - 1) + "-" + today.getFullYear().toString().substr(-2)
    } else {
        fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1).toString().substr(-2)
    }
    return fiscalyear
}

export const formatDhanEvent = (dhans) => {

    var dhanList = [];



    dhans?.forEach((dhan) => {
        let newEvent = dhan?.meal_time?.map((time) => ({
            isDhan: true,
            date: time,
            start: new Date(time),
            title: `${dhan?.motive} ${dhan?.honoree}`,
            name: `${dhan?.motive} ${dhan?.honoree}`,
            donation_id: dhan?.donation?.id,
            amount: dhan?.donation?.amount,
            donate_by: dhan?.donation?.donate_by
        }))
        dhanList = [...dhanList, ...newEvent]
    })

    return dhanList




}