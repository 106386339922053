import { format } from 'date-fns'
import React from 'react'
import { Accordion } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { curencyFormater, getCurrentFinancialYear } from '../../../helper/formater'



function Pen() {
    return (
        <Link to={"#"} className="btn btn-danger light">Pending</Link>
    )
}
function Comp() {
    return (
        <Link to={"#"} className="btn btn-success light">Completed</Link>
    )
}
function Can() {
    return (
        <Link to={"#"} className="btn btn-dark light">Canceled</Link>
    )
}

const AccordionBlog = [
    { status: <Pen />, },
    { status: <Comp />, },
    { status: <Can />, },
    { status: <Pen /> },
    { status: <Comp />, }
];

const DonationHistory = ({ donation }) => {

    // console.log({ donation });

    return (
        <>
            <div>
                <div className="card">
                    <div className="card-header d-block d-sm-flex border-0 transactions-tab">
                        <div className="me-3">
                            <h4 className="card-title mb-2 font-w700">Donation History</h4>
                            <span className="fs-12">Lorem ipsum dolor sit amet, consectetur</span>
                        </div>

                    </div>
                    <div className="card-body tab-content p-0">
                        <Accordion className="style-1" defaultActiveKey="0" >
                            {donation.map((data, i) => (
                                <Accordion.Item className="accordion-item" key={i} eventKey={`${i}`}>
                                    <Accordion.Header as="div" variant="">
                                        <div className="d-flex align-items-center">

                                            <div className="user-info">
                                                <h6 className="fs-16 font-w700 mb-0"><Link to={"#"}>Donation ID</Link></h6>
                                                <span className="fs-14">#ECO/DHAN/{getCurrentFinancialYear(data?.created_at)}/{data?.id?.toLocaleString('en-US', {
                                                    minimumIntegerDigits: 2,
                                                    useGrouping: false
                                                })}</span>
                                            </div>
                                        </div>
                                        <span>{data?.created_at ? format(new Date(data?.created_at), "MMM dd, yyyy") : null}</span>
                                        <span>{curencyFormater.format(data?.amount)}</span>

                                        {data?.is_paid ?
                                            (<span className="badge badge-rounded badge-primary text-white">Paid</span>) :
                                            (<span className="badge badge-rounded badge-warning text-white">Not Paid</span>)}

                                    </Accordion.Header>
                                    <Accordion.Collapse eventKey={`${i}`} className="accordion_body" >

                                        <div className="payment-details accordion-body-text">

                                            {(data?.payment != null) && (data?.payment_method_id === 1 ? (
                                                <>

                                                    <div className="me-3 mb-3">
                                                        <p className="fs-12 mb-2">Payment Gateway</p>
                                                        <span className="font-w500">{data?.payment?.payment_gateway}</span>
                                                    </div>
                                                    <div className="me-3 mb-3">
                                                        <p className="fs-12 mb-2">Payment ID</p>
                                                        <span className="font-w500">{data?.payment?.transaction_code}</span>
                                                    </div>
                                                </>

                                            ) : data?.payment_method_id === 2 ? (
                                                <div className="me-3 mb-3">
                                                    <p className="fs-12 mb-2">Payment ID</p>
                                                    <span className="font-w500">{data?.payment?.transaction_code}</span>
                                                </div>
                                            ) : data?.payment_method_id === 3 ? (
                                                <>

                                                    <div className="me-3 mb-3">
                                                        <p className="fs-12 mb-2">Cheque no</p>
                                                        <span className="font-w500">{data?.payment?.cheque_no}</span>
                                                    </div>
                                                    <div className="me-3 mb-3">
                                                        <p className="fs-12 mb-2">Bank</p>
                                                        <span className="font-w500">{data?.payment?.bank}</span>
                                                    </div>
                                                    <div className="me-3 mb-3">
                                                        <p className="fs-12 mb-2">Memo</p>
                                                        <span className="font-w500">{data?.payment?.memo}</span>
                                                    </div>

                                                </>
                                            ) : data?.payment_method_id === 4 ? (
                                                <>
                                                    <div className="me-3 mb-3">
                                                        <p className="fs-12 mb-2">Payment ID</p>
                                                        <span className="font-w500">{data?.payment?.transaction_code}</span>
                                                    </div>
                                                    <div className="me-3 mb-3">
                                                        <p className="fs-12 mb-2">Bank</p>
                                                        <span className="font-w500">{data?.payment?.bank}</span>
                                                    </div>
                                                    <div className="me-3 mb-3">
                                                        <p className="fs-12 mb-2">Memo</p>
                                                        <span className="font-w500">{data?.payment?.memo}</span>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="me-3 mb-3">
                                                        <p className="fs-12 mb-2">Cheque no</p>
                                                        <span className="font-w500">{data?.payment?.cheque_no}</span>
                                                    </div>
                                                    <div className="me-3 mb-3">
                                                        <p className="fs-12 mb-2">Payment ID</p>
                                                        <span className="font-w500">{data?.payment?.transaction_code}</span>
                                                    </div>
                                                    <div className="me-3 mb-3">
                                                        <p className="fs-12 mb-2">Bank</p>
                                                        <span className="font-w500">{data?.payment?.bank}</span>
                                                    </div>
                                                    <div className="me-3 mb-3">
                                                        <p className="fs-12 mb-2">Memo</p>
                                                        <span className="font-w500">{data?.payment?.memo}</span>
                                                    </div>
                                                </>
                                            ))
                                            }

                                            <div className="me-3 mb-3">
                                                <p className="fs-12 mb-2">Payment Method</p>
                                                <span className="font-w500">{data?.paymentmethod?.name}</span>
                                            </div>


                                        </div>
                                    </Accordion.Collapse>
                                </Accordion.Item >
                            ))}
                        </Accordion>
                    </div>

                </div>
            </div>
        </>
    )
}

export default DonationHistory