import { createSlice } from "@reduxjs/toolkit";
import { CampaignInitialLoad, CampaignPaggination } from "../thunk/CampaignThunk";





const initialState = {
    campaigns: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};


export const CampaignSlice = createSlice({
    name: "Campaign",
    initialState,
    reducers: {
        resetCampaign: (state, action) => {
            return {
                ...initialState
            };

        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(CampaignInitialLoad.pending, (state, action) => {
                return { ...state, showLoading: true };
            })
            .addCase(CampaignInitialLoad.fulfilled, (state, action) => {

                // console.log({ action });

                return {
                    ...state,
                    campaigns: action.payload,
                    errorMessage: '',
                    successMessage: '',
                    showLoading: false,
                };
            })
            .addCase(CampaignInitialLoad.rejected, (state, action) => {
                let errorMessage;
                if (action.payload) {
                    // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                    errorMessage = action.payload;
                } else {
                    errorMessage = action.error.message?.toString();
                }

                return { ...state, errorMessage, showLoading: false }
            })
            .addCase(CampaignPaggination.pending, (state, action) => {
                return { ...state, showLoading: true };
            })
            .addCase(CampaignPaggination.fulfilled, (state, action) => {

                // console.log({ action });

                return {
                    ...state,
                    campaigns: action.payload,
                    errorMessage: '',
                    successMessage: '',
                    showLoading: false,
                };
            })
            .addCase(CampaignPaggination.rejected, (state, action) => {
                let errorMessage;
                if (action.payload) {
                    // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                    errorMessage = action.payload;
                } else {
                    errorMessage = action.error.message?.toString();
                }

                return { ...state, errorMessage, showLoading: false }
            });
    },
});


export const { resetCampaign } = CampaignSlice.actions;

export const CampaignReducer = CampaignSlice.reducer;