import { createAsyncThunk } from "@reduxjs/toolkit";
import { login, saveTokenInLocalStorage } from "../../services/AuthService";


export const loginAction = createAsyncThunk(
    //action type string
    "auth/login",
    // callback function
    async (credencials, thunkAPI) => {

        const { email, password, history } = credencials;



        const response = await login(email, password);

        saveTokenInLocalStorage(response.data);


        // console.log({ res });

        if (response?.status === 500) {
            return thunkAPI.rejectWithValue("Server Error");
        } else {
            if (response?.data?.token) {
                history.push('/dashboard');
                return response.data;
            } else {
                if (response?.data?.error_message) {
                    return thunkAPI.rejectWithValue(response?.data?.error_message);
                }
            }
        }
    }
);






