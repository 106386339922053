import FullCalendar from '@fullcalendar/react'
import React, { useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from '@fullcalendar/list';
import AddEvent from './AddEvent';
import { useDispatch } from 'react-redux';
import { EventInitialLoad } from '../../../store/thunk/EventThunk';
import moment from 'moment';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';


const renderEventContent = (eventInfo) => {

    const thisEvent = eventInfo?.event?.extendedProps;

    console.log({ thisEvent });

    return (
        <div className='event-item-holder'>
            {
                thisEvent?.isDhan ? (
                    <Link to={`/donation-details/${thisEvent?.donation_id}`}>
                        <div className="event-item">
                            <p className='name'> <strong>{thisEvent?.name} Dhan</strong> </p>
                            <p>Donation Amount: <span>₹{thisEvent?.amount}</span> </p>

                        </div>
                    </Link>
                ) : (
                    <Link to={`/event-details/${thisEvent?.event_id}`}>
                        <div className="event-item">
                            <p className='name'> <strong>{thisEvent?.name}</strong> </p>
                            {
                                thisEvent?.event_type ? (<p>({thisEvent?.event_type})</p>) : (<p>{thisEvent?.description}</p>)
                            }

                        </div>
                    </Link>
                )
            }

        </div>
    )
}

const EventFullCalendar = ({ events, dhans }) => {

    const [eventList, setEventList] = useState([])

    const [dateQuary, setDateQuary] = useState({
        fromDate: moment().startOf('month').format('YYYY-MM-DD'),
        toDate: moment().endOf('month').format('YYYY-MM-DD')
    });

    const dispatch = useDispatch();

    // console.log({ events });

    useEffect(() => {

        let newEventList = events?.map((event) => ({
            ...event,
            start: new Date(event?.date),
            title: event?.name,
            event_id: event?.id,
            event_type: event?.event_type_id !== 6 ? event?.type?.name : undefined
        }))



        if (dhans?.length > 0) {
            newEventList = [...newEventList, ...dhans]
        }

        setEventList(newEventList)


    }, [events, dhans])



    useEffect(() => {
        dispatch(EventInitialLoad(dateQuary))
    }, [dateQuary])

    const [isAddEventOpen, setIsAddEventOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState();

    const handleDateClick = (arg) => { // bind with an arrow function
        setIsAddEventOpen(true)
        setSelectedDate(arg?.date)
    }

    const addEventClose = () => {
        setIsAddEventOpen(false)
        setSelectedDate(undefined)
    }

    const handleMonthChange = (payload) => {
        if (payload) {
            setDateQuary({
                fromDate: moment(payload?.start).format('YYYY-MM-DD'),
                toDate: moment(payload?.end).format('YYYY-MM-DD')
            })
        }
    }

    console.log({ eventList });

    return (
        <div className="animated fadeIn demo-app">
            <AddEvent isOpen={isAddEventOpen} close={addEventClose} date={selectedDate} />
            <Row>


                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className="demo-app-calendar" id="mycalendartest">
                                <FullCalendar
                                    defaultView="dayGridMonth"
                                    eventContent={renderEventContent}
                                    rerenderDelay={10}
                                    eventDurationEditable={false}
                                    editable={false}
                                    plugins={[
                                        dayGridPlugin,
                                        timeGridPlugin,
                                        interactionPlugin,
                                        listPlugin
                                    ]}
                                    headerToolbar={{
                                        left: 'prev,next today',
                                        center: 'title',
                                        right: 'dayGridMonth,dayGridWeek,listMonth'
                                    }}
                                    dateClick={handleDateClick}
                                    weekends={true}
                                    events={eventList}
                                    datesSet={handleMonthChange}

                                />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default EventFullCalendar