import React, { Fragment, useEffect, useState } from 'react'
import PageTitle from '../../layouts/PageTitle'
import Select from "react-select";

import { Button } from 'react-bootstrap';
import swal from 'sweetalert';
import { addDonation, addPayment, getPaymentMethods } from '../../../helper/BackendHelper';
import { useHistory } from 'react-router-dom';
import PageLoader from '../../layouts/PageLoader';
import { notifyError } from '../../../helper/notification';



const initialData = {}

const paymentMethodSInitial = [
    {
        "value": 1,
        "lable": "Online"
    },
    {
        "value": 2,
        "lable": "UPI"
    },
    {
        "value": 3,
        "lable": "Cheque"
    },
    {
        "value": 4,
        "lable": "Wire Transfer"
    }
]

const PaymentAdd = () => {


    const [formData, setformData] = useState(initialData);
    const [isLoading, setIsLoading] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState(paymentMethodSInitial);
    const [paymentOptions, setPaymentOptions] = useState([])


    const router = useHistory();

    const onChangeValue = (e) => {
        setformData({ ...formData, [e?.currentTarget?.name]: e?.currentTarget?.value })
    }


    useEffect(() => {
        getPaymentMethods().then((res) => {
            if (res?.status === "success") {
                let data = res?.data;
                data?.shift();
                setPaymentMethods(data)
            }
        })

    }, [])

    useEffect(() => {
        setPaymentOptions(paymentMethods.map((payment) => ({
            value: payment.lable,
            label: payment.lable,
        })))

    }, [paymentMethods])




    const formSubmit = async (e) => {
        e.preventDefault();

        // console.log({ formData });
        setIsLoading(true);

        const res = await addPayment(formData);

        if (res?.status === 'success') {

            swal(`Payment added`, ``, "success")
            setIsLoading(false);
            router.push('/list-of-payments')

        } else {

            if (res.isAxiosError) {

                if (res?.response?.data?.errors) {
                    Object.values(res?.response?.data?.errors).map((errors) => errors.map((error) => notifyError(error)))
                } else {
                    notifyError(res?.response?.data?.message)
                }
            } else {
                swal("Oops", `${res?.response?.data?.message ? res?.response?.data?.message : res?.message}`, "error")
            }
            setIsLoading(false);

        }
    }






    // console.log({ formData });

    return (
        <Fragment>
            <PageLoader isLoading={isLoading} >
                {/* <PageTitle activeMenu="Payment" motherMenu="Add New Payment" /> */}

                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header container-fluid" style={{ display: "block" }}>
                                <div class="row">
                                    <div class="col-md-10">
                                        <h4 className="card-title">Payment Details</h4>
                                    </div>

                                </div>
                            </div>
                            <div className="card-body">
                                <div className="basic-form">
                                    <form onSubmit={formSubmit}>
                                        <fieldset disabled={isLoading}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="col-auto">
                                                        <label className="sr-only">Payment Amount</label>
                                                        <div className="input-group mb-3">
                                                            <div className="input-group-text">₹</div>

                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                placeholder="Amount"
                                                                name='amount'
                                                                required
                                                                onChange={onChangeValue}
                                                                min="1"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">

                                                        <Select
                                                            onChange={(data) => setformData({ ...formData, payment_method_id: paymentMethods.find((item) => item?.lable == data.value)?.value })}
                                                            options={paymentOptions}
                                                            style={{
                                                                lineHeight: "40px",
                                                                color: "#7e7e7e",
                                                                paddingLeft: " 15px",
                                                            }}
                                                            placeholder="Select Your Payment Method"
                                                            name='payment_method_id'
                                                            required
                                                        />

                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                formData?.payment_method_id && (formData?.payment_method_id === 2 ? (
                                                    <div className="row">

                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-3">

                                                                <input
                                                                    type="text"
                                                                    className="form-control input-default "
                                                                    placeholder="Transaction Id"
                                                                    name='transaction_code'
                                                                    onChange={onChangeValue}

                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : formData?.payment_method_id === 3 ? (
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-3">
                                                                <input
                                                                    type="text"
                                                                    className="form-control input-default "
                                                                    placeholder="Cheque Number"
                                                                    name='cheque_no'
                                                                    onChange={onChangeValue}

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-3">

                                                                <input
                                                                    type="text"
                                                                    className="form-control input-default "
                                                                    placeholder="Name of the Bank"
                                                                    name='bank'
                                                                    onChange={onChangeValue}

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-3">

                                                                <input
                                                                    type="text"
                                                                    className="form-control input-default "
                                                                    placeholder="Memo (Optional)"
                                                                    name='memo'
                                                                    onChange={onChangeValue}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : formData?.payment_method_id === 4 ? (
                                                    <div className="row">

                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-3">

                                                                <input
                                                                    type="text"
                                                                    className="form-control input-default "
                                                                    placeholder="Transaction Id"
                                                                    name='transaction_code'
                                                                    onChange={onChangeValue}

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group mb-3">

                                                                <input
                                                                    type="text"
                                                                    className="form-control input-default "
                                                                    placeholder="Name of the Bank"
                                                                    name='bank'
                                                                    onChange={onChangeValue}

                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group mb-3">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control input-default "
                                                                        placeholder="Cheque Number"
                                                                        name='cheque_no'
                                                                        onChange={onChangeValue}

                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group mb-3">

                                                                    <input
                                                                        type="text"
                                                                        className="form-control input-default "
                                                                        placeholder="Transaction Id"
                                                                        name='transaction_code'
                                                                        onChange={onChangeValue}

                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group mb-3">

                                                                    <input
                                                                        type="text"
                                                                        className="form-control input-default "
                                                                        placeholder="Name of the Bank"
                                                                        name='bank'
                                                                        onChange={onChangeValue}

                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group mb-3">

                                                                    <input
                                                                        type="text"
                                                                        className="form-control input-default "
                                                                        placeholder="Memo (Optional)"
                                                                        name='memo'
                                                                        onChange={onChangeValue}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                            }





                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <Button type='submit' variant="primary" disabled={formData?.payment_method_id ? false : true} >{isLoading ? "Adding...." : "Add Payment"}</Button>

                                                </div>

                                            </div>
                                        </fieldset>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageLoader>

        </Fragment>
    )
}

export default PaymentAdd