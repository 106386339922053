import { configureStore } from "@reduxjs/toolkit";
import { AuthReducer } from "./reducers/AuthReducer";
import { CampaignReducer } from "./reducers/Campaign";
import { DonetionReducer } from "./reducers/Donetion";
import { DonerReducer } from "./reducers/Donner";
import { EventReducer } from "./reducers/Event";
import { PaymentReducer } from "./reducers/Payment";
import { VolunteersReducer } from "./reducers/Volunteers";


export const store = configureStore({
    reducer: {
        auth: AuthReducer,
        doners: DonerReducer,
        volunteers: VolunteersReducer,
        donetions: DonetionReducer,
        payments: PaymentReducer,
        event: EventReducer,
        campaigns: CampaignReducer,
    },
});


