import React, { Fragment, useState } from 'react'
import PageTitle from '../../layouts/PageTitle'
import Select from "react-select";


import countries from '../../../mock/countries.json';
import { Button } from 'react-bootstrap';
import swal from 'sweetalert';
import { addUser } from '../../../helper/BackendHelper';
import { useHistory } from 'react-router-dom';
import PageLoader from '../../layouts/PageLoader';
import { notifyError } from '../../../helper/notification';

const initialData = {
    role_id: 3,
    salutation: 'Mr'
}

const salutations = [
    {
        value: "Mr",
        label: "Mr"
    },
    {
        value: "Ms",
        label: "Ms"
    },
    {
        value: "Dr",
        label: "Dr"
    },
    {
        value: "Prof",
        label: "Prof"
    },
]


const DonersAdd = () => {

    // console.log({countries});

    const [formData, setformData] = useState(initialData);
    const [isLoading, setIsLoading] = useState(false);

    const router = useHistory();

    const onChangeValue = (e) => {
        setformData({ ...formData, [e?.currentTarget?.name]: e?.currentTarget?.value })
    }

    const countriesOptions = countries.map((country) => ({
        value: country.name,
        label: country.name,
    }))




    const formSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const res = await addUser(formData);

        if (res?.status === 'success') {

            swal(`Donor added`, ``, "success")
            setIsLoading(false);
            router.push('/list-of-donors')

        } else {

            if (res.isAxiosError) {

                if (res?.response?.data?.errors) {
                    Object.values(res?.response?.data?.errors).map((errors) => errors.map((error) => notifyError(error)))
                } else {
                    notifyError(res?.response?.data?.message)
                }
            } else {
                swal("Oops", `${res?.response?.data?.message ? res?.response?.data?.message : res?.message}`, "error")
            }
            setIsLoading(false);

        }
    }

    // console.log({ formData });

    return (
        <Fragment>
            <PageLoader isLoading={isLoading} >
                {/* <PageTitle activeMenu="Donor" motherMenu="Add New Donor" /> */}
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Donor Details</h4>
                            </div>
                            <div className="card-body">
                                <div className="basic-form">
                                    <form onSubmit={formSubmit}>
                                        <fieldset disabled={isLoading}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <div className="row align-items-center" >
                                                            <div className="col-sm-3">
                                                                <Select
                                                                    // defaultValue={selectedOption}
                                                                    onChange={(data) => setformData({ ...formData, salutation: data?.value })}
                                                                    options={salutations}
                                                                    style={{
                                                                        lineHeight: "40px",
                                                                        color: "#7e7e7e",
                                                                        paddingLeft: " 15px",
                                                                    }}
                                                                    placeholder="Select Salutation"
                                                                    name='salutation'
                                                                    value={
                                                                        salutations.filter(option =>
                                                                            option.label === formData?.salutation)
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="text"
                                                                    className="form-control input-default "
                                                                    placeholder="Name"
                                                                    name='name'
                                                                    required
                                                                    onChange={onChangeValue}
                                                                />
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="email"
                                                            className="form-control input-default "
                                                            placeholder="Email"
                                                            name='email'
                                                            required
                                                            onChange={onChangeValue}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="number"
                                                            className="form-control input-default "
                                                            placeholder="Mobile Number"
                                                            name='phone'
                                                            required
                                                            onChange={onChangeValue}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="number"
                                                            className="form-control input-default "
                                                            placeholder="WhatsApp Number"
                                                            name='whatsapp'
                                                            required
                                                            onChange={onChangeValue}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control input-default "
                                                            placeholder="PAN Number (optional)"
                                                            name='pan'
                                                            onChange={onChangeValue}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">

                                                        <Select
                                                            // defaultValue={selectedOption}
                                                            onChange={(data) => setformData({ ...formData, country: data?.value })}
                                                            options={countriesOptions}
                                                            style={{
                                                                lineHeight: "40px",
                                                                color: "#7e7e7e",
                                                                paddingLeft: " 15px",
                                                            }}
                                                            placeholder="Select Country"
                                                            name='country'
                                                        />

                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <textarea name="address" id="address" className="form-control input-default "
                                                            placeholder="Address" rows="5"
                                                            onChange={onChangeValue}
                                                        >

                                                        </textarea>

                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control input-default "
                                                            placeholder="City"
                                                            name='city'
                                                            required
                                                            onChange={onChangeValue}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="number"
                                                            className="form-control input-default "
                                                            placeholder="Pincode"
                                                            name='pincode'
                                                            required
                                                            onChange={onChangeValue}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <Button type='submit' variant="primary">{isLoading ? "Adding...." : "Add Donor"}</Button>

                                                </div>

                                            </div>
                                        </fieldset>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageLoader>

        </Fragment>
    )
}

export default DonersAdd