import axiosInstance from "../services/AxiosInstance";


export async function post(url, data = {}, config = {}) {
    return axiosInstance
        .post(url, { ...data }, { ...config })
        .then((response) => response.data).catch((e) => e)
}

export async function get(url, config = {}) {
    return axiosInstance
        .get(url, { ...config })
        .then((response) => response.data).catch((e) => { return e })
}

export async function download(url, config = {}) {
    return axiosInstance
        .get(url, { ...config, responseType: 'arraybuffer', 'Content-Type': 'blob' })
        .then((response) => response).catch((e) => { return e })
}

export async function put(url, data, config = {}) {
    return axiosInstance
        .put(url, { ...data }, { ...config })
        .then((response) => response.data).catch((e) => { return e })
}


export async function uplaod(url, data = {}) {
    return axiosInstance
        .post(url, data, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => response.data).catch((e) => { return e })
}


export async function del(url, config = {}) {
    return axiosInstance
        .delete(url, { ...config })
        .then((response) => response.data).catch((e) => { return e })
}