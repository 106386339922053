import React, { Fragment, useState } from 'react'
import { Accordion, Button, Form, Table } from 'react-bootstrap';
import PageLoader from '../../layouts/PageLoader';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { useEffect } from 'react';
import { getEvent, getEventDetails, imageUploadEvent, updateEvent } from '../../../helper/BackendHelper';
import { Link, useHistory, useParams } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../helper/notification';
import { curencyFormaterNarmal } from '../../../helper/formater';



const initialData = {
    menus: [
    ],
    date: moment().format("YYYY-MM-DD")
}

const baseUrl = process.env.REACT_APP_API_URL;


const EventDetails = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [eventData, setEventData] = useState()

    console.log({ eventData });



    const router = useHistory();
    const { id } = useParams();




    useEffect(() => {
        setIsLoading(true)
        getEventData()

    }, [])

    const getEventData = () => {
        getEventDetails(id).then((res) => {
            if (res?.status === "success") {

                // let menu = res?.data?.details?.menu ? JSON.parse(res?.data?.details?.menu) : null;
                setEventData(res?.data)

                setIsLoading(false)

            }
        })
    }








    return (
        <Fragment>
            <PageLoader isLoading={isLoading} >
                <div className="h-80">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-sm-12">
                            <div className="card-img-holder">
                                <div className="card overflow-hidden">
                                    <div
                                        className="text-center p-3 top-card-bg"
                                        style={{
                                            background: eventData?.details?.image ? `url(${baseUrl}/${eventData?.details?.image})` : '#64a233'
                                        }}
                                    >
                                        <div className="profile-photo" style={{ minHeight: "200px" }}>
                                            {/* <img src={`${baseUrl}/${eventData?.details?.image}`} alt="" /> */}
                                        </div>
                                        <h3 className="mt-3 mb-1 text-white">{eventData?.name}</h3>
                                        <p className="text-white mb-0">{eventData?.details?.type ? eventData?.details?.type : eventData?.event_type_id === 6 ? thisDonation.events.description : eventData?.type?.name}</p>
                                    </div>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className="mb-0">Date</span>{" "}
                                            <strong className="text-muted">{moment(eventData?.date).format('DD MMMM YYYY')} </strong>
                                        </li>
                                        {
                                            eventData?.details?.menu && (
                                                <li className="list-group-item d-flex justify-content-between">
                                                    <span className="mb-0">Price</span>{" "}
                                                    <strong className="text-muted">₹ {JSON.parse(eventData?.details?.menu)?.price} </strong>
                                                </li>
                                            )
                                        }


                                    </ul>
                                    <div className="menu-items p-3">
                                        {
                                            eventData?.details?.menu && (
                                                <>
                                                    <h6 className="title">
                                                        Special Menu
                                                    </h6>
                                                    {
                                                        JSON.parse(eventData?.details?.menu)?.list?.map((menu, index) => (
                                                            <>

                                                                <span key={index}>{menu?.name}</span>{" "}|{" "}
                                                            </>
                                                        ))
                                                    }
                                                </>
                                            )
                                        }


                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="card">
                                    <div className="card-body pb-3">
                                        <div className="row align-items-center">
                                            <div className="col-xl-3 mb-3">
                                                <p className="mb-2 fs-16 font-w600">Event Name</p>
                                                <h2 className="mb-0 fs-20 font-w800">{eventData?.name}</h2>
                                            </div>
                                            <div className="col-xl-9 d-flex flex-wrap justify-content-between align-items-center">
                                                <div className="d-flex me-3 mb-3 ms-2 align-items-start payment">
                                                    <div>
                                                        <p className="mb-2 fs-16 font-w600">Event Date</p>

                                                        <h4 className="mb-0 fs-18 font-w700">{eventData?.date ? moment(eventData?.date).format("MMM DD, YYYY") : null}</h4>
                                                    </div>
                                                </div>

                                                {moment().isBefore(eventData?.date) && (
                                                    <div className="d-flex mb-3">
                                                        <Link to={`/event-edit/${eventData?.id}`} className="btn btn-primary"><i className="las la-share scale5 me-3"></i>Edit this Event</Link>
                                                    </div>
                                                )}


                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body pb-3 transaction-details d-flex flex-wrap justify-content-between align-items-center">

                                        <div className="amount-bx mb-3 border">
                                            <i className="fas fa-rupee-sign"></i>
                                            <div>
                                                <p className="mb-1">Total Amount raised this event</p>
                                                <h3 className="mb-0">{curencyFormaterNarmal?.format(eventData?.total)}</h3>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body pb-3">
                                        <div className="report-table" >
                                            <Table responsive >
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <strong>#</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Donor</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Payment Method</strong>
                                                        </th>
                                                        <th>
                                                            <strong>DATE</strong>
                                                        </th>
                                                        <th>
                                                            <strong>STATUS</strong>
                                                        </th>
                                                        <th>
                                                            <strong>PRICE</strong>
                                                        </th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        eventData?.donations?.map((donation, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <strong>{index + 1}</strong>
                                                                </td>
                                                                <td><Link to={`/donation-details/${donation?.id}`}> <a>{donation.donate_by.name}</a> </Link></td>
                                                                <td>{donation.paymentmethod.name}</td>
                                                                <td>{moment(donation.created_at).format('DD MMMM YYYY')}</td>
                                                                <td>
                                                                    {donation.is_paid ? ("Payment recived") : ("Payment not recived")}
                                                                </td>
                                                                <td>₹{donation.amount}</td>
                                                                <td>

                                                                </td>
                                                            </tr>
                                                        ))
                                                    }



                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </PageLoader>
        </Fragment>
    )
}

export default EventDetails