import { createSlice } from "@reduxjs/toolkit";
import { loginAction } from "../thunk/AuthThunk";


const initialState = {
    auth: {
        user: null,
        token: '',
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export const AuthSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: (state, action) => {
            localStorage.removeItem('userDetails');
            // action.payload?.history?.push('/login');

            return {
                ...state,
                errorMessage: '',
                successMessage: '',
                auth: {
                    email: '',
                    idToken: '',
                    localId: '',
                    expiresIn: '',
                    refreshToken: '',
                },
            };

        },
        loginConfirmedAction: (state, action) => {
            return {
                ...state,
                auth: action.payload,
                errorMessage: '',
                successMessage: 'Login Successfully Completed',
                showLoading: false,
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAction.pending, (state, action) => {
                return { ...state, showLoading: true };
            })
            .addCase(loginAction.fulfilled, (state, action) => {

                // console.log({ action });

                return {
                    ...state,
                    auth: action.payload,
                    errorMessage: '',
                    successMessage: 'Login Successfully Completed',
                    showLoading: false,
                };
            })
            .addCase(loginAction.rejected, (state, action) => {
                let errorMessage;
                if (action.payload) {
                    // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                    errorMessage = action.payload;
                } else {
                    errorMessage = action.error.message?.toString();
                }

                return { ...state, errorMessage, showLoading: false }
            });
    },
});


export const {
    logout,
    loginConfirmedAction
} = AuthSlice.actions;

export const AuthReducer = AuthSlice.reducer;
