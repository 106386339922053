import DateRangePicker from "react-bootstrap-daterangepicker";
import React, { Fragment, useEffect, useState } from 'react'
import PageLoader from '../../layouts/PageLoader'
import PageTitle from '../../layouts/PageTitle'
import moment from "moment";
import { Button, Table } from "react-bootstrap";
import { downloadDonetionReport, getDonetionReport } from "../../../helper/BackendHelper";
import { curencyFormater, curencyFormaterNarmal } from "../../../helper/formater";
import Pdf from "react-to-pdf";
// import Pdf from '../../components/Document/Pdf'

const ref = React.createRef();

const DonationReport = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [dateQuary, setDateQuary] = useState({
        fromDate: moment().startOf('month').format('YYYY-MM-DD'),
        toDate: moment().endOf('month').format('YYYY-MM-DD')
    });
    const [donationList, setDonationList] = useState();

    useEffect(() => {
        setIsLoading(true)

        getDonetionReport(dateQuary).then((res) => {
            if (res?.status === "success") {
                setDonationList(res?.data);
                setIsLoading(false)
            }
        })
    }, [dateQuary])

    console.log({ donationList });


    const downloadReportCSV = () => {
        downloadDonetionReport(dateQuary).then((res) => {


            const blob = new Blob([res?.data], { type: res?.headers?.["content-type"] });

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `report-donation-${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();

        })
    }



    return (
        <Fragment>
            <PageLoader isLoading={isLoading} >
                {/* <PageTitle activeMenu="Report" motherMenu="Donation Report" /> */}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="example rangeDatePicker">
                                            <p className="mb-1">Date</p>

                                            <DateRangePicker initialSettings={{ startDate: moment().startOf('month').format('MM/DD/YYYY'), endDate: moment().endOf('month').format('MM/DD/YYYY') }} onCallback={(start, end, label) => {
                                                setDateQuary({
                                                    fromDate: start?.format('YYYY-MM-DD'),
                                                    toDate: end?.format('YYYY-MM-DD')
                                                })
                                            }} >
                                                <input type="text" className="form-control input-daterange-timepicker" />
                                            </DateRangePicker>
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "flex-end" }}>

                                            <Button onClick={downloadReportCSV}><i className="las la-download scale5 me-3"></i> Download</Button>
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">

                    <div className="col-xl-4 col-sm-6">
                        <div className="card overflow-hidden">
                            <div className="card-header border-0">
                                <div className="d-flex">
                                    <span className="mt-1">
                                        <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M17.812 48.64L11.2 53.6C10.594 54.054 9.78401 54.128 9.10602 53.788C8.42802 53.45 8.00002 52.758 8.00002 52V16C8.00002 14.896 8.89602 14 10 14H38C39.104 14 40 14.896 40 16V52C40 52.758 39.572 53.45 38.894 53.788C38.216 54.128 37.406 54.054 36.8 53.6L30.188 48.64L25.414 53.414C24.634 54.196 23.366 54.196 22.586 53.414L17.812 48.64ZM36 48V18H12V48L16.8 44.4C17.596 43.802 18.71 43.882 19.414 44.586L24 49.172L28.586 44.586C29.29 43.882 30.404 43.802 31.2 44.4L36 48ZM22 34H26C27.104 34 28 33.104 28 32C28 30.896 27.104 30 26 30H22C20.896 30 20 30.896 20 32C20 33.104 20.896 34 22 34ZM18 26H30C31.104 26 32 25.104 32 24C32 22.896 31.104 22 30 22H18C16.896 22 16 22.896 16 24C16 25.104 16.896 26 18 26Z" fill="#44814E" />
                                            <circle cx="43.5" cy="14.5" r="12.5" fill="#09BD3C" stroke="white" strokeWidth="4" />
                                        </svg>
                                    </span>
                                    <div className="invoices">
                                        <h4>{curencyFormaterNarmal.format(donationList?.donetionPaid)}</h4>
                                        <span>Donation Paid </span>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                        <div className="card overflow-hidden">
                            <div className="card-header border-0">
                                <div className="d-flex">
                                    <span className="mt-1">
                                        <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M17.812 48.64L11.2 53.6C10.594 54.054 9.78401 54.128 9.10602 53.788C8.42802 53.45 8.00002 52.758 8.00002 52V16C8.00002 14.896 8.89602 14 10 14H38C39.104 14 40 14.896 40 16V52C40 52.758 39.572 53.45 38.894 53.788C38.216 54.128 37.406 54.054 36.8 53.6L30.188 48.64L25.414 53.414C24.634 54.196 23.366 54.196 22.586 53.414L17.812 48.64ZM36 48V18H12V48L16.8 44.4C17.596 43.802 18.71 43.882 19.414 44.586L24 49.172L28.586 44.586C29.29 43.882 30.404 43.802 31.2 44.4L36 48ZM22 34H26C27.104 34 28 33.104 28 32C28 30.896 27.104 30 26 30H22C20.896 30 20 30.896 20 32C20 33.104 20.896 34 22 34ZM18 26H30C31.104 26 32 25.104 32 24C32 22.896 31.104 22 30 22H18C16.896 22 16 22.896 16 24C16 25.104 16.896 26 18 26Z" fill="#44814E" />
                                            <circle cx="43.5" cy="14.5" r="12.5" fill="#FD5353" stroke="white" strokeWidth="4" />
                                        </svg>

                                    </span>
                                    <div className="invoices">
                                        <h4>{curencyFormaterNarmal.format(donationList?.donetionUnpaid)}</h4>
                                        <span>Donation Unpaid</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                        <div className="card overflow-hidden">
                            <div className="card-header border-0">
                                <div className="d-flex">
                                    <span className="mt-1">
                                        <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M17.812 48.64L11.2 53.6C10.594 54.054 9.784 54.128 9.106 53.788C8.428 53.45 8 52.758 8 52V16C8 14.896 8.896 14 10 14H38C39.104 14 40 14.896 40 16V52C40 52.758 39.572 53.45 38.894 53.788C38.216 54.128 37.406 54.054 36.8 53.6L30.188 48.64L25.414 53.414C24.634 54.196 23.366 54.196 22.586 53.414L17.812 48.64ZM36 48V18H12V48L16.8 44.4C17.596 43.802 18.71 43.882 19.414 44.586L24 49.172L28.586 44.586C29.29 43.882 30.404 43.802 31.2 44.4L36 48ZM22 34H26C27.104 34 28 33.104 28 32C28 30.896 27.104 30 26 30H22C20.896 30 20 30.896 20 32C20 33.104 20.896 34 22 34ZM18 26H30C31.104 26 32 25.104 32 24C32 22.896 31.104 22 30 22H18C16.896 22 16 22.896 16 24C16 25.104 16.896 26 18 26Z" fill="#44814E" />
                                            <circle cx="43.5" cy="14.5" r="12.5" fill="#FFAA2B" stroke="white" strokeWidth="4" />
                                        </svg>


                                    </span>
                                    <div className="invoices">
                                        <h4>{curencyFormaterNarmal.format(donationList?.donetionRaised)}</h4>
                                        <span>Donation Raised</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="report-table" ref={ref}>
                                            <Table responsive >
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <strong>#</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Date</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Donor name</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Address of Donor</strong>
                                                        </th>
                                                        <th>
                                                            <strong>PAN</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Payment Method</strong>
                                                        </th>

                                                        <th>
                                                            <strong>Status</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Amount</strong>
                                                        </th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        donationList?.donations?.map((donation) => (
                                                            <tr>
                                                                <td>
                                                                    <strong>{donation.id}</strong>
                                                                </td>
                                                                <td>{moment(donation.created_at).format('DD MMMM YYYY')}</td>
                                                                <td>{donation.donate_by.name}</td>
                                                                <td>{donation?.donate_by?.address ? <p dangerouslySetInnerHTML={{ __html: Object.values(donation?.donate_by?.address)?.join(",</br>") }} /> : "-"}</td>
                                                                <td>{donation.donate_by.pan}</td>
                                                                <td>{donation.paymentmethod.name}</td>
                                                                <td>
                                                                    {donation.is_paid ? ("Payment recived") : ("Payment not recived")}
                                                                </td>
                                                                <td>₹{donation.amount}</td>
                                                                <td>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }



                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </PageLoader>
        </Fragment>
    )
}

export default DonationReport