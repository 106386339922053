import { format } from 'date-fns'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
import { Button } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { getDoner } from '../../../helper/BackendHelper'
import { curencyFormater, curencyFormaterNarmal } from '../../../helper/formater'
import DonerPanUpdate from '../../components/Common/DonerPanUpdate'
import PageLoader from '../../layouts/PageLoader'
import PageTitle from '../../layouts/PageTitle'
import DonationHistory from './DonationHistory'



const DonerEdit = () => {

    const [isLoading, setIsLoading] = useState(false);

    const [donerData, setDonerData] = useState();

    const { id } = useParams();




    useEffect(() => {
        if (id) {
            setIsLoading(true)
            getDonerByID(id);
        }

    }, [id])

    const getDonerByID = (id) => {
        getDoner(id).then((res) => {
            if (res?.status === 'success') {
                setDonerData(res?.data)
                setIsLoading(false)

            }
        })
    }

    console.log({ donerData });





    return (
        <Fragment>
            <PageLoader isLoading={isLoading} >
                {/* <PageTitle activeMenu="Donor Edit" motherMenu="Donors" /> */}
                {
                    donerData && (
                        <div className="row">
                            <div className="col-xl-8">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body pb-3 d-flex flex-wrap justify-content-between align-items-center">
                                                <div className="user-bx-2 me-3 mb-3">

                                                    <div>
                                                        <h3 className="fs-20 font-w700">{donerData?.salutation ? `${donerData?.salutation}.` : ""}{donerData?.name}</h3>
                                                        <span className="font-w400">{donerData?.email}</span>
                                                    </div>
                                                </div>
                                                <div className="me-3 mb-3">
                                                    <p className="mb-2">Phone Number</p>
                                                    <h4 className="mb-0">{donerData?.phone}</h4>
                                                </div>
                                                <div className="me-3 mb-3">
                                                    <p className="mb-2">PAN Number</p>
                                                    <h4 className="mb-0">{donerData?.pan ? donerData?.pan : "No PAN Updated"}</h4>
                                                </div>
                                                <div className="me-3 mb-3">
                                                    <p className="mb-2">Registered on</p>
                                                    <h4 className="mb-0">{donerData?.created_at ? format(new Date(donerData?.created_at), "MMM dd, yyyy") : null}</h4>
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                    {
                                        donerData?.donation?.length > 0 && (
                                            <div className="col-lg-12">
                                                <DonationHistory donation={donerData?.donation} />

                                            </div>
                                        )
                                    }


                                </div>

                            </div>

                            <div className="col-xl-4">
                                <div className="row">

                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body action">
                                                <div className="">

                                                    <h4 className="fs-32 font-w800 text-white mt-3">{curencyFormaterNarmal.format(donerData?.donatedAmount)}</h4>
                                                    <span className="fs-18 font-w600 text-white">Total Amount Donated</span>

                                                </div>
                                            </div>
                                            {

                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between">

                                                        <Link to={`/donor-edit/${donerData?.id}`} className="btn btn-primary"><i className="las la-share scale5 me-3"></i>Edit this Doner</Link>


                                                    </div>
                                                </div>

                                            }

                                        </div>
                                    </div>
                                    <div className="col-lg-12">

                                        <div className="card">
                                            <div className="card-header d-block d-sm-flex border-0 transactions-tab">
                                                <div className="me-3">
                                                    <h4 className="card-title mb-2 font-w700">Address</h4>
                                                    <address className="fs-12">{donerData?.address?.address},<br />{donerData?.address?.city}-{donerData?.address?.pincode},<br />{donerData?.address?.country}</address>
                                                </div>

                                            </div>
                                        </div>

                                        {/* <DonerPanUpdate userId={donerData.id} /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

            </PageLoader>
        </Fragment>
    )
}

export default DonerEdit