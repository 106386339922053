import { format } from 'date-fns';
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Card, Modal, Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { getDonetion, mealDateUpdate, payDonetion, sendPaymentReceipt, trigerMails } from '../../../helper/BackendHelper';
import PageTitle from '../../layouts/PageTitle'
import swal from "sweetalert";
import PageLoader from '../../layouts/PageLoader';
import { curencyFormater, curencyFormaterNarmal, getCurrentFinancialYear } from '../../../helper/formater';
import { notifyError, notifySuccess } from '../../../helper/notification';
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import DhanImageUplaod from '../../components/Common/DhanImageUplaod';



const PaymentForm = ({ donation, closeModel, getThisDonation }) => {

    // console.log({ donation });

    const [formData, setFormData] = useState({})

    const [isLoading, setIsLoading] = useState(false);

    const onChangeValue = (e) => {
        setFormData({ ...formData, [e?.currentTarget?.name]: e?.currentTarget?.value })
    }








    const onSubmit = async (e) => {
        // console.log({ formData });
        setIsLoading(true)
        e.preventDefault();

        const res = await payDonetion(donation?.id, formData);

        if (res?.status === 'success') {
            // notifySuccess(res?.message);

            swal(`${res?.message}`, ``, "success")

            if (getThisDonation) {
                getThisDonation(donation?.id);
            }

            if (closeModel) {
                closeModel();
                setIsLoading(false)

            }
        } else {
            // notifyError(res?.message);

            if (res.isAxiosError) {

                if (res?.response?.data?.errors) {
                    Object.values(res?.response?.data?.errors).map((errors) => errors.map((error) => notifyError(error)))
                } else {
                    notifyError(res?.response?.data?.message)
                }
            } else {
                swal("Oops", `${res?.response?.data?.message ? res?.response?.data?.message : res?.message}`, "error")
            }
            setIsLoading(false)

        }


    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <Modal.Body>
                    <div>

                        <div className="basic-form">

                            {
                                donation?.payment_method_id === 1 ? (
                                    <Button>Send Payment Notification</Button>
                                ) : donation?.payment_method_id === 2 ? (
                                    <div className="form-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control input-default "
                                            placeholder="Transaction Id"
                                            name='transaction_code'
                                            required
                                            onChange={onChangeValue}
                                        />
                                    </div>
                                ) : donation?.payment_method_id === 3 ? (
                                    <>
                                        <div className="form-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control input-default "
                                                placeholder="Cheque Number"
                                                name='cheque_no'
                                                required
                                                onChange={onChangeValue}
                                            />
                                        </div>
                                        <div className="form-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control input-default "
                                                placeholder="Name of the Bank"
                                                name='bank'
                                                required
                                                onChange={onChangeValue}
                                            />
                                        </div>
                                        <div className="form-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control input-default "
                                                placeholder="Memo (Optional)"
                                                name='memo'
                                                onChange={onChangeValue}
                                            />
                                        </div>
                                    </>
                                ) : donation?.payment_method_id === 4 ? (
                                    <>
                                        <div className="form-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control input-default "
                                                placeholder="Transaction Id"
                                                name='transaction_code'
                                                required
                                                onChange={onChangeValue}
                                            />
                                        </div>
                                        <div className="form-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control input-default "
                                                placeholder="Name of the Bank"
                                                name='bank'
                                                required
                                                onChange={onChangeValue}
                                            />
                                        </div>
                                    </>

                                ) : (<>
                                    <div className="form-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control input-default "
                                            placeholder="Cheque Number"
                                            name='cheque_no'
                                            onChange={onChangeValue}

                                        />
                                    </div>
                                    <div className="form-group mb-3">

                                        <input
                                            type="text"
                                            className="form-control input-default "
                                            placeholder="Transaction Id"
                                            name='transaction_code'
                                            onChange={onChangeValue}

                                        />
                                    </div>
                                    <div className="form-group mb-3">

                                        <input
                                            type="text"
                                            className="form-control input-default "
                                            placeholder="Name of the Bank"
                                            name='bank'
                                            onChange={onChangeValue}

                                        />
                                    </div>
                                    <div className="form-group mb-3">

                                        <input
                                            type="text"
                                            className="form-control input-default "
                                            placeholder="Memo (Optional)"
                                            name='memo'
                                            onChange={onChangeValue}
                                        />
                                    </div></>)
                            }


                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={closeModel}
                        variant="danger light"
                        type='reset'
                    >
                        Close
                    </Button>
                    <Button type='submit' variant="primary">{isLoading ? "Loading..." : "Add Payment"}</Button>
                </Modal.Footer>
            </form>
        </>
    )
}

const baseUrl = process.env.REACT_APP_API_URL;



const DonationEdit = () => {

    const [thisDonation, setThisDonation] = useState();

    const [isAddPayment, setIsAddPayment] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [mealDate, setMealDate] = useState()


    const { id } = useParams();

    useEffect(() => {

        if (id) {
            getThisDonation(id);
        }

    }, [id])


    useEffect(() => {

        if (thisDonation?.connect?.meal_time) {
            setMealDate(thisDonation?.connect?.meal_time);
        }

    }, [thisDonation?.connect?.meal_time])

    const getThisDonation = (id) => {
        setIsLoading(true)
        if (id) {
            getDonetion(id).then((res) => {
                if (res?.status === "success") {
                    setThisDonation(res?.data);
                    setIsLoading(false)

                }
            })
        }
    }


    const onSendReceipt = async (id) => {
        setIsLoading(true);
        const res = await sendPaymentReceipt(id);

        if (res?.status === 'success') {
            notifySuccess(res?.message);
            setIsLoading(false);
            trigerMails();

        } else {
            // notifyError(res?.message);

            if (res.isAxiosError) {

                if (res?.response?.data?.errors) {
                    Object.values(res?.response?.data?.errors).map((errors) => errors.map((error) => notifyError(error)))
                } else {
                    notifyError(res?.response?.data?.message)
                }
            } else {
                notifyError(`${res?.response?.data?.message ? res?.response?.data?.message : res?.message}`)

            }

            setIsLoading(false);


        }
    }

    // console.log({ thisDonation });



    const onMealDateEdit = () => {
        if (mealDate) {

            let meal_time = JSON.stringify(mealDate?.map((date) => date?.format()))
            let donation = thisDonation?.id
            let id = thisDonation?.connect?.id

            if (id) {
                setIsLoading(true);

                mealDateUpdate({ donation, meal_time }, id)?.then((res) => {
                    if (res?.status === 'success') {

                        swal(`Meal Date Updated`, ``, "success")
                        getThisDonation(donation);
                        trigerMails();



                    } else {

                        if (res.isAxiosError) {

                            if (res?.response?.data?.errors) {
                                Object.values(res?.response?.data?.errors).map((errors) => errors.map((error) => notifyError(error)))
                            } else {
                                notifyError(res?.response?.data?.message)
                            }
                        } else {
                            swal("Oops", `${res?.response?.data?.message ? res?.response?.data?.message : res?.message}`, "error")
                        }
                        setIsLoading(false);

                    }
                })

            }


        }

    }

    console.log({ thisDonation });





    return (
        <Fragment>
            <PageLoader isLoading={isLoading} >
                {/* <PageTitle activeMenu="Donations Edit" motherMenu="Donations" /> */}

                <div className="row">
                    <div className="col-lg-12">
                        {
                            thisDonation && (
                                <div className="card">
                                    <div className="card-body pb-3">
                                        <div className="row align-items-center">
                                            <div className="col-xl-3 mb-3">
                                                <p className="mb-2 fs-16 font-w600">Donation ID</p>
                                                <h2 className="mb-0 fs-20 font-w800">#ECO/DHAN/{getCurrentFinancialYear(thisDonation?.created_at)}/{thisDonation?.id?.toLocaleString('en-US', {
                                                    minimumIntegerDigits: 2,
                                                    useGrouping: false
                                                })}</h2>
                                            </div>
                                            <div className="col-xl-9 d-flex flex-wrap justify-content-between align-items-center">
                                                <div className="d-flex me-3 mb-3 ms-2 align-items-start payment">
                                                    <i className="fas fa-phone-alt me-4 mt-2 scale5"></i>
                                                    <div>
                                                        <p className="mb-2 fs-16 font-w600">Mobile Number</p>
                                                        <h4 className="mb-0 fs-18 font-w700">{thisDonation?.donate_by?.phone}</h4>
                                                    </div>
                                                </div>
                                                <div className="d-flex me-3 mb-3 ms-2 align-items-start payment">
                                                    <i className="fab fa-brands fa-whatsapp me-4 mt-2 scale5"></i>

                                                    <div>
                                                        <p className="mb-2 fs-16 font-w600">WhatsApp Number</p>
                                                        <h4 className="mb-0 fs-18 font-w700">{thisDonation?.donate_by?.phone}</h4>
                                                    </div>
                                                </div>
                                                <div className="d-flex me-3 mb-3 ms-2 align-items-start payment">
                                                    <i className="fas fa-envelope scale5 me-4 mt-2"></i>
                                                    <div>
                                                        <p className="mb-2 fs-16 font-w600">Email</p>
                                                        <h4 className="mb-0 fs-18 font-w700">{thisDonation?.donate_by?.email}</h4>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-3">
                                                    {
                                                        !thisDonation?.is_paid
                                                            ? (<Button onClick={() => setIsAddPayment(true)} className="btn btn-primary light me-3"><i className="las la-plus me-3 scale5"></i>Payment Receipt</Button>)
                                                            : (<Button onClick={() => onSendReceipt(thisDonation?.payment?.id)} className="btn btn-primary"><i className="las la-envelope scale5 me-3"></i>Send Receipt </Button>)
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body pb-3 transaction-details d-flex flex-wrap justify-content-between align-items-center">
                                        <div className="user-bx-2 me-3 mb-3">
                                            <div>
                                                <h3 className="fs-20 font-w700">Donor Name</h3>
                                                <Link to={`/donor-details/${thisDonation?.donate_by?.id}`}>
                                                    <span className="font-w400">{thisDonation?.donate_by?.name}</span>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="me-3 mb-3">
                                            <p className="mb-2">Payment Method</p>
                                            <h4 className="mb-0">{thisDonation?.payment_method?.name}</h4>
                                        </div>
                                        <div className="me-3 mb-3">
                                            <p className="mb-2">Path of Payment</p>
                                            <h4 className="mb-1">

                                                {thisDonation?.path !== null && thisDonation?.path !== undefined ? (<>{thisDonation?.path}</>) : thisDonation?.magic_pot !== null ? (
                                                    <>
                                                        {thisDonation?.pledgedMeal ? "MILESTONES" : thisDonation?.connect ? "CONNECT" : "MAGIC POT"}
                                                    </>
                                                ) : <>MAGIC POT</>}
                                                {
                                                    thisDonation?.magic_pot && (<> ({thisDonation?.magic_pot}) </>)
                                                }
                                            </h4>

                                        </div>
                                        <div className="me-3 mb-3">
                                            <p className="mb-2">Date of Donation</p>
                                            <h4 className="mb-0">{thisDonation?.created_at ? format(new Date(thisDonation?.created_at), "MMM dd, yyyy") : null}</h4>
                                        </div>



                                        <div className="amount-bx mb-3 border">
                                            <i class="fas fa-rupee-sign"></i>

                                            <div>
                                                <p className="mb-1">Amount Contributed</p>
                                                <h3 className="mb-0">₹ {thisDonation?.amount}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                    </div>
                </div>

                <div className="row">

                    {
                        (thisDonation && thisDonation?.events !== null) && (

                            <div className="col-xl-4 col-lg-12 col-sm-12">
                                <div className="card overflow-hidden">
                                    <div
                                        className="text-center p-3 top-card-bg"
                                        style={{ background: thisDonation?.events?.details?.image ? `url(${baseUrl}/${thisDonation?.events?.details?.image})` : '#64a233' }}
                                    >
                                        <div className="profile-photo" style={{ minHeight: "200px" }}>

                                        </div>
                                        <h3 className="mt-3 mb-1 text-white">{thisDonation?.events?.name}</h3>
                                        <p className="text-white mb-0">{thisDonation?.events?.details?.type ? thisDonation?.events?.details?.type : thisDonation?.events?.event_type_id === 6 ? thisDonation.events.description : thisDonation?.events?.type?.name}</p>
                                    </div>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className="mb-0">Date</span>{" "}
                                            <strong className="text-muted">{thisDonation?.events?.date} </strong>
                                        </li>
                                        {
                                            thisDonation?.events?.details?.menu && thisDonation?.events?.details?.menu !== "{}" && (
                                                <li className="list-group-item d-flex justify-content-between">
                                                    <span className="mb-0">Price</span>{" "}
                                                    <strong className="text-muted">₹ {JSON.parse(thisDonation?.events?.details?.menu)?.price} </strong>
                                                </li>
                                            )
                                        }

                                    </ul>
                                    {
                                        thisDonation?.events?.details?.menu && thisDonation?.events?.details?.menu !== "{}" && (
                                            <div className="menu-items p-3">
                                                <h6 className="title">
                                                    Special Menu
                                                </h6>
                                                {
                                                    JSON.parse(thisDonation?.events?.details?.menu)?.list?.map((menu, index) => (
                                                        <>

                                                            <span key={index}>{menu?.name}</span>{" "}|{" "}
                                                        </>
                                                    ))
                                                }

                                            </div>
                                        )
                                    }

                                </div>
                            </div>

                        )
                    }
                    {
                        (thisDonation && thisDonation?.pledgedMeal !== null) && (
                            <div className="col-lg-8">
                                <Card>
                                    <Card.Header>
                                        <Card.Title>Pledged Dhan</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <Table responsive>
                                            <thead>
                                                <tr>

                                                    {/* <th>
                                                        <strong>Name</strong>
                                                    </th> */}
                                                    <th className="width80">
                                                        <strong>Meal type</strong>
                                                    </th>
                                                    <th className="width80">
                                                        <strong>Package</strong>
                                                    </th>
                                                    {/* <th className="width80">
                                                        <strong>Unit</strong>
                                                    </th> */}
                                                    <th className="width80">
                                                        <strong>Price</strong>
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    thisDonation?.pledgedMeal?.menu?.map((item, j) => (
                                                        <tr>
                                                            {/* <td>{item?.[0]?.name}</td> */}
                                                            <td>{item?.[0]?.meal_type?.name}</td>
                                                            <td>{item?.[0]?.menu_type?.name}</td>
                                                            {/* <td>{item?.[0]?.units}</td> */}
                                                            <td>{curencyFormater.format(item?.[0]?.price)}</td>
                                                        </tr>
                                                    ))
                                                }

                                                <tr>
                                                    <td><strong>Total</strong></td>
                                                    {/* <td></td>
                                                    <td></td> */}
                                                    <td><strong>{thisDonation?.pledgedMeal?.pax} pax</strong></td>
                                                    <td><strong>{curencyFormaterNarmal.format(thisDonation?.pledgedMeal?.amount)}</strong></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </div>
                        )
                    }



                    {(thisDonation && thisDonation?.payment !== null) && (

                        <div className="col-lg-4">
                            <div className="payment-holder">
                                <div class="card text-white bg-warning text-black">
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item d-flex justify-content-between">
                                            <span class="mb-0">Payment Details</span>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between">
                                            <span class="mb-0">Payment Id :</span><strong>#PAYMENT{thisDonation?.payment?.id}</strong>
                                        </li>
                                        {
                                            thisDonation?.payment_method_id === 1 ? (
                                                <>
                                                    <li class="list-group-item d-flex justify-content-between">
                                                        <span class="mb-0">Payment Gateway :</span><strong>{thisDonation?.payment?.payment_gateway}</strong>
                                                    </li>
                                                    <li class="list-group-item d-flex justify-content-between">
                                                        <span class="mb-0">Transaction Id :</span><strong>{thisDonation?.payment?.transaction_code}</strong>
                                                    </li>
                                                </>

                                            ) : thisDonation?.payment_method_id === 2 ? (
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <span class="mb-0">Transaction Id :</span><strong>{thisDonation?.payment?.transaction_code}</strong>
                                                </li>
                                            ) : thisDonation?.payment_method_id === 3 ? (
                                                <>
                                                    <li class="list-group-item d-flex justify-content-between">
                                                        <span class="mb-0">Cheque no :</span><strong>{thisDonation?.payment?.cheque_no}</strong>
                                                    </li>
                                                    <li class="list-group-item d-flex justify-content-between">
                                                        <span class="mb-0">Bank :</span><strong>{thisDonation?.payment?.bank}</strong>
                                                    </li>
                                                    <li class="list-group-item d-flex justify-content-between">
                                                        <span class="mb-0">Memo :</span><strong>{thisDonation?.payment?.memo}</strong>
                                                    </li>
                                                </>
                                            ) : thisDonation?.payment_method_id === 4 ? (
                                                <>
                                                    <li class="list-group-item d-flex justify-content-between">
                                                        <span class="mb-0">Transaction Id :</span><strong>{thisDonation?.payment?.transaction_code}</strong>
                                                    </li>
                                                    <li class="list-group-item d-flex justify-content-between">
                                                        <span class="mb-0">Bank :</span><strong>{thisDonation?.payment?.bank}</strong>
                                                    </li>
                                                    <li class="list-group-item d-flex justify-content-between">
                                                        <span class="mb-0">Memo :</span><strong>{thisDonation?.payment?.memo}</strong>
                                                    </li>
                                                </>
                                            ) : (
                                                <>
                                                    <li class="list-group-item d-flex justify-content-between">
                                                        <span class="mb-0">Transaction Id :</span><strong>{thisDonation?.payment?.transaction_code}</strong>
                                                    </li>
                                                    <li class="list-group-item d-flex justify-content-between">
                                                        <span class="mb-0">Cheque no :</span><strong>{thisDonation?.payment?.cheque_no}</strong>
                                                    </li>
                                                    <li class="list-group-item d-flex justify-content-between">
                                                        <span class="mb-0">Bank :</span><strong>{thisDonation?.payment?.bank}</strong>
                                                    </li>
                                                    <li class="list-group-item d-flex justify-content-between">
                                                        <span class="mb-0">Memo :</span><strong>{thisDonation?.payment?.memo}</strong>
                                                    </li>
                                                </>
                                            )
                                        }


                                    </ul>
                                </div>
                            </div>

                        </div>

                    )}

                    {
                        (thisDonation && thisDonation?.connect !== null) && (
                            <>
                                <div className="col-lg-4">
                                    <div className="payment-holder">
                                        <div class="card text-white bg-success text-black">
                                            <ul class="list-group list-group-flush">
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <h4 class="mb-0">  Connect Details</h4>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <span class="mb-0"> <b>{thisDonation?.connect?.motive} {thisDonation?.connect?.honoree}</b> </span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <span class="mb-0"> <b>Message :</b> </span><strong>{thisDonation?.connect?.message}</strong>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <span class="mb-0"> <b>Number of beneficiaries :</b> </span><strong>{thisDonation?.connect?.no_of_beneficiaries}</strong>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <span class="mb-0"> <b>Number of weeks :</b> </span><strong>{thisDonation?.connect?.weeks}</strong>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <span class="mb-0"> <b>Recipient’s Name :</b> </span><strong>{thisDonation?.connect?.recipients}</strong>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <span class="mb-0"> <b>Selected Mealtime :</b> </span><strong>{thisDonation?.connect?.meals?.map((meal) => meal?.meal)?.join(", ")}</strong>
                                                </li>

                                                <li class="list-group-item d-flex justify-content-between">
                                                    <span class="mb-0"> <b>Selected Date for Connect :</b> </span><strong>{thisDonation?.connect?.meal_time?.join(",\n")}</strong>

                                                </li>

                                                <li class="list-group-item d-flex justify-content-between">

                                                    <span class="mb-0"> <b>Select Date for Connect :</b> </span>
                                                    <div className='form-group'>
                                                        <DatePicker value={mealDate} onChange={setMealDate} multiple={true} inputClass='form-control input-default' plugins={[
                                                            <DatePanel />
                                                        ]} placeholder='Select Dates' minDate={new Date(thisDonation?.created_at)} />
                                                    </div>
                                                </li>

                                                <Button variant="primary" onClick={onMealDateEdit}>Submit Meal Date</Button>





                                            </ul>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-12">
                                    <div className="row">
                                        {

                                            mealDate?.map((date) => date?.format ? date?.format() : date)?.map((time) => (
                                                (
                                                    <div className="col-lg-4">
                                                        <DhanImageUplaod id={thisDonation?.connect?.id} time={time} />
                                                    </div>
                                                )
                                            ))


                                        }

                                    </div>


                                </div>
                            </>

                        )
                    }

                </div>

                <Modal className="fade" show={isAddPayment} onHide={() => setIsAddPayment(false)}>
                    <Modal.Header>
                        <Modal.Title>Payment Receipt</Modal.Title>
                        <Button onClick={() => setIsAddPayment(false)} variant="" className="btn-close"></Button>
                    </Modal.Header>
                    <PaymentForm donation={thisDonation} closeModel={() => setIsAddPayment(false)} getThisDonation={getThisDonation} />

                </Modal>
            </PageLoader>

        </Fragment >
    )
}

export default DonationEdit