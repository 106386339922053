export const isAdmin = (role) => {
    return role?.id === 1
}
export const isVolunteer = (role) => {
    return role?.id === 2
}
export const isDoner = (role) => {
    return role?.id === 3
}
export const isExecutive = (role) => {
    return role?.id === 4
}
export const isAccounts = (role) => {
    return role?.id === 5
}
