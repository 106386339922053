import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDoners, getDonersByPage } from "../../helper/BackendHelper";


const DonerInitialLoad = createAsyncThunk(
    //action type string
    "doner/initial",
    // callback function
    async (data, thunkAPI) => {



        const response = await getDoners();


        // console.log({ res });

        if (response?.status === 500) {
            return thunkAPI.rejectWithValue("Server Error");
        } else {
            if (response?.data) {


                return response.data;
            }
        }
    }
);

const DonerPaggination = createAsyncThunk(
    //action type string
    "doner/paggination",
    // callback function
    async (data, thunkAPI) => {



        const response = await getDonersByPage(data);


        // console.log({ res });

        if (response?.status === 500) {
            return thunkAPI.rejectWithValue("Server Error");
        } else {
            if (response?.data) {


                return response.data;
            }
        }
    }
);

export { DonerInitialLoad, DonerPaggination }