import { format } from 'date-fns';
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom'
import { addDonetionToPayment, getPayment, trigerMails } from '../../../helper/BackendHelper';
import PageTitle from '../../layouts/PageTitle'
import swal from "sweetalert";
import PageLoader from '../../layouts/PageLoader';
import DonationSelect from '../../components/Common/DonationSelect';
import { notifyError } from '../../../helper/notification';



const PaymentEdit = () => {

    const [thisPayment, setThisPayment] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isDonationAdd, setIsDonationAdd] = useState(false);



    const { id } = useParams();

    useEffect(() => {

        if (id) {
            getThisPayment(id);
        }

    }, [id])

    const getThisPayment = (id) => {
        setIsLoading(true)
        if (id) {
            getPayment(id).then((res) => {
                if (res?.status === "success") {
                    setThisPayment(res?.data);
                    setIsLoading(false)

                }
            })
        }
    }

    const linkPayment = (data) => {

        addDonetionToPayment(id, data).then((res) => {
            if (res?.status === 'success') {
                // notifySuccess(res?.message);

                swal(`${res?.message}`, ``, "success")

                getThisPayment(id);

                trigerMails();


            } else {
                // notifyError(res?.message);

                if (res.isAxiosError) {

                    if (res?.response?.data?.errors) {
                        Object.values(res?.response?.data?.errors).map((errors) => errors.map((error) => notifyError(error)))
                    } else {
                        notifyError(res?.response?.data?.message)
                    }
                } else {
                    swal("Oops", `${res?.response?.data?.message ? res?.response?.data?.message : res?.message}`, "error")
                }

            }
        })

    }

    // console.log({ thisPayment });


    return (
        <Fragment>
            <PageLoader isLoading={isLoading} >
                {/* <PageTitle activeMenu="Payment Edit" motherMenu="Payment" /> */}

                <DonationSelect close={() => setIsDonationAdd(false)} isOpen={isDonationAdd} id={id} onSubmit={linkPayment} />

                <div className="row">
                    <div className="col-lg-12">
                        {
                            thisPayment && (
                                <div className="card">
                                    <div className="card-body pb-3">
                                        <div className="row align-items-center">
                                            <div className="col-xl-3 mb-3">
                                                <p className="mb-2 fs-16 font-w600">Payment ID</p>
                                                <h2 className="mb-0 fs-32 font-w800">#PAYMENT{thisPayment?.id}</h2>
                                            </div>
                                            <div className="col-xl-9 d-flex flex-wrap justify-content-between align-items-center">
                                                <div className="d-flex me-3 mb-3 ms-2 align-items-start payment">
                                                    <div>
                                                        <p className="mb-2 fs-16 font-w600">Payment Method</p>
                                                        <h4 className="mb-0 fs-18 font-w700">{thisPayment?.paymentmethod?.name}</h4>
                                                    </div>
                                                </div>
                                                <div className="d-flex me-3 mb-3 ms-2 align-items-start payment">
                                                    <div>
                                                        <p className="mb-2 fs-16 font-w600">Payment Added on</p>
                                                        <h4 className="mb-0 fs-18 font-w700">{thisPayment?.created_at ? format(new Date(thisPayment?.created_at), "MMM dd, yyyy") : null}</h4>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-3">
                                                    {
                                                        !(thisPayment?.donation != null)
                                                            ? (<Button onClick={() => setIsDonationAdd(true)} className="btn btn-primary light me-3"><i className="las la-plus me-3 scale5"></i>Link to existing Donation</Button>)
                                                            : (<Link to={`/donation-details/${thisPayment?.donation?.id}`} className="btn btn-primary"><i className="las la-share scale5 me-3"></i>Go to this Donation</Link>)
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body pb-3 transaction-details d-flex flex-wrap justify-content-between align-items-center">






                                        <div className="amount-bx mb-3 border">
                                            <i className="fas fa-rupee-sign"></i>
                                            <div>
                                                <p className="mb-1">Amount</p>
                                                <h3 className="mb-0">₹ {thisPayment?.amount}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                    </div>
                </div>

                {thisPayment && (
                    <div className="row">
                        <div className="col-lg-4">
                            <div class="card text-white bg-warning text-black">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item d-flex justify-content-between">
                                        <span class="mb-0">Payment Details</span>
                                    </li>
                                    {
                                        thisPayment?.payment_method_id === 1 ? (
                                            <>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <span class="mb-0">Payment Gateway :</span><strong>{thisPayment?.payment_gateway}</strong>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <span class="mb-0">Transaction Id :</span><strong>{thisPayment?.transaction_code}</strong>
                                                </li>
                                            </>

                                        ) : thisPayment?.payment_method_id === 2 ? (
                                            <li class="list-group-item d-flex justify-content-between">
                                                <span class="mb-0">Transaction Id :</span><strong>{thisPayment?.transaction_code}</strong>
                                            </li>
                                        ) : thisPayment?.payment_method_id === 3 ? (
                                            <>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <span class="mb-0">Cheque no :</span><strong>{thisPayment?.cheque_no}</strong>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <span class="mb-0">Bank :</span><strong>{thisPayment?.bank}</strong>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <span class="mb-0">Memo :</span><strong>{thisPayment?.memo}</strong>
                                                </li>
                                            </>
                                        ) : thisPayment?.payment_method_id === 4 ? (
                                            <>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <span class="mb-0">Transaction Id :</span><strong>{thisPayment?.transaction_code}</strong>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <span class="mb-0">Bank :</span><strong>{thisPayment?.bank}</strong>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <span class="mb-0">Memo :</span><strong>{thisPayment?.memo}</strong>
                                                </li>
                                            </>
                                        ) : (
                                            <>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <span class="mb-0">Transaction Id :</span><strong>{thisPayment?.transaction_code}</strong>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <span class="mb-0">Cheque no :</span><strong>{thisPayment?.cheque_no}</strong>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <span class="mb-0">Bank :</span><strong>{thisPayment?.bank}</strong>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <span class="mb-0">Memo :</span><strong>{thisPayment?.memo}</strong>
                                                </li>
                                            </>
                                        )
                                    }


                                </ul>
                            </div>
                        </div>
                    </div>
                )}




            </PageLoader>

        </Fragment>
    )
}

export default PaymentEdit