import React, { Fragment, useState } from 'react'
import PageTitle from '../../layouts/PageTitle'
import Select from "react-select";


import countries from '../../../mock/countries.json';
import { Button } from 'react-bootstrap';
import swal from 'sweetalert';
import { addUser, getRoles } from '../../../helper/BackendHelper';
import { useHistory } from 'react-router-dom';
import PageLoader from '../../layouts/PageLoader';
import { useEffect } from 'react';
import { notifyError } from '../../../helper/notification';

const initialData = {}

const VolunteerAdd = () => {

    // console.log({countries});

    const [formData, setformData] = useState(initialData);
    const [isLoading, setIsLoading] = useState(false);

    const router = useHistory();

    const [roleList, setRoleList] = useState([])
    const [roleOption, setRoleOption] = useState([])

    useEffect(() => {

        getRoles().then((res) => {

            if (res?.status == "success") {
                setRoleList(res?.data)
            }

        })

    }, [])


    useEffect(() => {
        setRoleOption(roleList.map((role) => ({
            value: role.lable,
            label: role.lable,
        })))
    }, [roleList])



    const onChangeValue = (e) => {
        setformData({ ...formData, [e?.currentTarget?.name]: e?.currentTarget?.value })
    }

    const countriesOptions = countries.map((country) => ({
        value: country.name,
        label: country.name,
    }))


    const formSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const res = await addUser(formData);

        if (res?.status === 'success') {

            swal(`User added`, ``, "success")
            setIsLoading(false);
            router.push('/list-of-users')

        } else {

            if (res.isAxiosError) {

                if (res?.response?.data?.errors) {
                    Object.values(res?.response?.data?.errors).map((errors) => errors.map((error) => notifyError(error)))
                } else {
                    notifyError(res?.response?.data?.message)
                }
            } else {
                swal("Oops", `${res?.response?.data?.message ? res?.response?.data?.message : res?.message}`, "error")
            }
            setIsLoading(false);

        }
    }

    // console.log({ formData });

    return (
        <Fragment>
            <PageLoader isLoading={isLoading} >
                {/* <PageTitle activeMenu="User" motherMenu="Add New User" /> */}
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">User Details</h4>
                            </div>
                            <div className="card-body">
                                <div className="basic-form">
                                    <form onSubmit={formSubmit}>
                                        <fieldset disabled={isLoading}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control input-default "
                                                            placeholder="Name"
                                                            name='name'
                                                            required
                                                            onChange={onChangeValue}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="email"
                                                            className="form-control input-default "
                                                            placeholder="Email"
                                                            name='email'
                                                            required
                                                            onChange={onChangeValue}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="number"
                                                            className="form-control input-default "
                                                            placeholder="Mobile Number"
                                                            name='phone'
                                                            required
                                                            onChange={onChangeValue}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="number"
                                                            className="form-control input-default "
                                                            placeholder="WhatsApp Number"
                                                            name='whatsapp'

                                                            onChange={onChangeValue}
                                                            value={formData?.whatsapp}

                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control input-default "
                                                            placeholder="PAN Number (optional)"
                                                            name='pan'
                                                            onChange={onChangeValue}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">

                                                        <Select
                                                            // defaultValue={selectedOption}
                                                            onChange={(data) => setformData({ ...formData, country: data?.value })}
                                                            options={countriesOptions}
                                                            style={{
                                                                lineHeight: "40px",
                                                                color: "#7e7e7e",
                                                                paddingLeft: " 15px",
                                                            }}
                                                            placeholder="Select Country"
                                                            name='country'
                                                        />

                                                    </div>
                                                </div>


                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <textarea name="address" id="address" className="form-control input-default "
                                                            placeholder="Address" rows="5"
                                                            onChange={onChangeValue}
                                                        >

                                                        </textarea>

                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">

                                                        <Select
                                                            // defaultValue={selectedOption}
                                                            onChange={(data) => setformData({ ...formData, role_id: roleList.find((item) => item?.lable == data.value)?.value })}
                                                            options={roleOption}
                                                            style={{
                                                                lineHeight: "40px",
                                                                color: "#7e7e7e",
                                                                paddingLeft: " 15px",
                                                            }}
                                                            placeholder="Select Role"
                                                            name='role'
                                                        />

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control input-default "
                                                            placeholder="City"
                                                            name='city'

                                                            onChange={onChangeValue}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="number"
                                                            className="form-control input-default "
                                                            placeholder="Pincode"
                                                            name='pincode'

                                                            onChange={onChangeValue}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <Button type='submit' variant="primary">{isLoading ? "Adding...." : "Add User"}</Button>

                                                </div>

                                            </div>
                                        </fieldset>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageLoader>

        </Fragment>
    )
}

export default VolunteerAdd