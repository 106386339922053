import { format } from 'date-fns';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getCampaignEvents } from '../../../helper/BackendHelper';
import { sumDonationAmount } from '../../../helper/formater';
import PageLoader from '../../layouts/PageLoader';

const EventList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [eventList, setEventList] = useState([]);

    useEffect(() => {
        setIsLoading(true)

        getCampaignEvents().then((res) => {
            if (res?.status === "success") {
                setEventList(res?.data)
                setIsLoading(false)

            }
        })

    }, [])

    // console.log({ eventList });


    return (
        <Fragment>
            <PageLoader isLoading={isLoading} >
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">

                                <div className="table-responsive">
                                    <table className="table mb-0 table-striped">
                                        <thead>
                                            <tr>

                                                <th>S.No</th>
                                                <th>Event</th>
                                                <th>Price per Meal</th>
                                                <th>Date</th>
                                                <th>Amount Raised</th>

                                                <th>Event Details</th>
                                                <th>Status</th>

                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody id="Donetions">

                                            {
                                                eventList?.map((event, index) => (
                                                    <tr className="btn-reveal-trigger">
                                                        <td>
                                                            {index + 1}
                                                        </td>
                                                        <td className="py-3">
                                                            <Link to={`/event-details/${event?.id}`}>
                                                                <div className="media d-flex align-items-center">

                                                                    <div className="media-body">
                                                                        <h5 className="mb-0 fs--1">
                                                                            {event?.name}
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </td>

                                                        <td className="py-2">
                                                            {
                                                                event?.details?.menu && (
                                                                    <>
                                                                        ₹{JSON.parse(event?.details?.menu)?.price}
                                                                    </>
                                                                )
                                                            }

                                                        </td>
                                                        <td className="py-2 ">

                                                            {format(new Date(event?.date), "dd-MM-yyyy")}
                                                        </td>
                                                        <td className="py-2">
                                                            ₹{sumDonationAmount(event?.donations)}
                                                        </td>
                                                        <td className="py-2">
                                                            {event?.description}
                                                        </td>
                                                        <td className="py-2">
                                                            {moment().isBefore(event?.date) ?
                                                                (<span className="badge badge-rounded badge-primary">Active</span>) :
                                                                (<span className="badge badge-rounded badge-danger">Ended</span>)}
                                                        </td>



                                                        <td className="py-2">
                                                            {
                                                                moment().isBefore(event?.date) &&
                                                                (<Dropdown>
                                                                    <Dropdown.Toggle variant="" className="btn btn-primary tp-btn-light sharp i-false">
                                                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                                                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                                                                                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                                                                                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                                                                            </g>
                                                                        </svg>
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item ><Link to={`/event-edit/${event?.id}`}>Edit </Link></Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>)

                                                            }

                                                        </td>

                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </PageLoader>
        </Fragment>
    )
}

export default EventList