import React, { Fragment } from 'react'
import { Nav, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getUserMe } from '../../../store/selectors/UserSelectors';
import PageTitle from '../../layouts/PageTitle';
import profile from "../../../images/user.png";
import PasswordChangeForm from '../../components/Common/PasswordChangeForm';
import ProfileEditForm from '../../components/Common/ProfileEditForm';
import { isAdmin, isExecutive } from '../../../helper/roleHelper';
import MealEditForm from '../../components/Common/MealEditForm';
// import { ToastContainer } from 'react-toastify';


const UserSettings = ({ user }) => {



    return (
        <Fragment>
            {/* <PageTitle activeMenu="Profile" motherMenu="App" /> */}

            <div className="row">
                <div className="col-lg-12">
                    <div className="profile card card-body px-3 pt-3 pb-0">
                        <div className="profile-head">
                            <div className="photo-content ">
                                <div className="cover-photo rounded"></div>
                            </div>
                            <div className="profile-info">
                                <div className="profile-photo">
                                    <img
                                        src={profile}
                                        className="img-fluid rounded-circle"
                                        alt="profile"
                                    />
                                </div>
                                <div className="profile-details">
                                    <div className="profile-name px-3 pt-2">
                                        <h4 className="text-primary mb-0">{user?.name}</h4>
                                        <p>{user?.role?.name}</p>
                                    </div>
                                    <div className="profile-email px-2 pt-2">
                                        <h4 className="text-muted mb-0">{user?.email}</h4>
                                        <p>Email</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">

                <div className="col-xl-8">
                    <div className="card">
                        <div className="card-body">
                            <div className="profile-tab">
                                <div className="custom-tab-1">
                                    <Tab.Container defaultActiveKey='Password'>
                                        <Nav as='ul' className="nav nav-tabs">


                                            <Nav.Item as='li' className="nav-item">
                                                <Nav.Link to="#settings-password" eventKey='Password'>Change Password</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as='li' className="nav-item">
                                                <Nav.Link to="#settings-profile" eventKey='Profile Edit'>Profile Edit</Nav.Link>
                                            </Nav.Item>

                                            {
                                                (isAdmin(user?.role) || isExecutive(user?.role)) && (
                                                    <Nav.Item as='li' className="nav-item">
                                                        <Nav.Link to="#settings-mealtime" eventKey='Meal Time Edit'>Meal Time Edit</Nav.Link>
                                                    </Nav.Item>
                                                )
                                            }

                                        </Nav>
                                        <Tab.Content>


                                            <Tab.Pane id="settings-password" eventKey='Password'>
                                                <div className="pt-3">
                                                    <div className="settings-form">
                                                        {/* <h4 className="text-primary">Change Password</h4> */}
                                                        <PasswordChangeForm />
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane id="settings-profile" eventKey='Profile Edit'>
                                                <div className="pt-3">
                                                    <div className="settings-form">
                                                        {/* <h4 className="text-primary">Profile Edit</h4> */}
                                                        <ProfileEditForm user={user} />
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane id="settings-mealtime" eventKey='Meal Time Edit'>
                                                <div className="pt-3">
                                                    <div className="settings-form">
                                                        {/* <h4 className="text-primary">Profile Edit</h4> */}
                                                        <MealEditForm />
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        user: getUserMe(state),
    };
};

export default connect(mapStateToProps)(UserSettings);