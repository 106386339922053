import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Button, Dropdown } from "react-bootstrap";
import { DonerInitialLoad, DonerPaggination } from "../../../store/thunk/DonnerThunk";


/// images
import avartar5 from "../../../images/avatar/5.png";
import avartar1 from "../../../images/avatar/1.png";


import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";


import Pagination from "react-js-pagination";
import { DonerIsLoading, DonerListWithPaggination } from "../../../store/selectors/DonnerSelector";
import PageLoader from "../../layouts/PageLoader";
import moment from "moment";
import { sumDonationAmount } from "../../../helper/formater";

const Doners = ({ doners, isLoading }) => {

   console.log({ doners });
   const [searchQuary, setSearchQuary] = useState("");

   const dispatch = useDispatch();

   useEffect(() => {

      if (DonerInitialLoad) {
         dispatch(DonerInitialLoad());

      }


      // getDoners().then((res) => console.log({ res }))

   }, [])

   useEffect(() => {

      dispatch(DonerPaggination({ q: searchQuary, pageNumber: 1 }))


   }, [searchQuary])

   const clearFilter = () => {
      setSearchQuary("");
   }








   return (
      <Fragment>
         <PageLoader isLoading={isLoading} >
            {/* <PageTitle activeMenu="Donors List" motherMenu="Donors" /> */}
            <div className="row">
               <div className="col-lg-12">
                  <div className="card">
                     <div className="card-body">
                        <div className="row">
                           <div className="col-lg-4">
                              <div className="input-group search-area">
                                 <input type="text"
                                    className={`form-control active`}
                                    placeholder="Search"
                                    onChange={(e) => setSearchQuary(e.currentTarget.value)}
                                    value={searchQuary}
                                 />
                                 <span className="input-group-text" >
                                    <i className="flaticon-381-search-2"></i>
                                 </span>
                              </div>
                           </div>
                           <div className="col-lg-2">
                              <div className="input-group">

                                 <Button onClick={clearFilter}>Clear Filter</Button>
                              </div>
                           </div>

                        </div>
                        <br />
                        <div className="table-responsive">
                           <table className="table mb-0 table-striped">
                              <thead>
                                 <tr>

                                    <th>Name</th>
                                    <th>Email ID</th>
                                    <th>Mobile Number</th>
                                    <th>WhatsApp</th>
                                    <th >
                                       PAN
                                    </th>
                                    <th>Date of Registration</th>
                                    <th>Total Contribution</th>
                                    <th></th>
                                 </tr>
                              </thead>
                              <tbody id="Doners">

                                 {
                                    doners?.data?.map((doner, index) => {
                                       let totalAmount = sumDonationAmount(doner?.donation)
                                       return (
                                          <tr className="btn-reveal-trigger" key={index}>

                                             <td className="py-3">
                                                <Link to={`/donor-details/${doner?.id}`}>
                                                   <div className="media d-flex align-items-center">

                                                      <div className="media-body">
                                                         <h5 className="mb-0 fs--1">
                                                            {doner?.name}
                                                         </h5>
                                                      </div>
                                                   </div>
                                                </Link>
                                             </td>
                                             <td className="py-2">
                                                <a href={`mailto:${doner?.email}`}>
                                                   {doner?.email}

                                                </a>
                                             </td>
                                             <td className="py-2">

                                                <a href={`tel:${doner?.phone}`}>{doner?.phone}
                                                </a>
                                             </td>
                                             <td className="py-2">

                                                <a href={`tel:${doner?.phone}`}>{doner?.phone}
                                                </a>
                                             </td>
                                             <td className="py-2 ">
                                                {doner?.pan ? doner?.pan : "No PAN Updated"}
                                             </td>

                                             <td className="py-2">{moment(doner?.created_at).format("DD MMM YYYY")}</td>
                                             <td className="py-2">₹{totalAmount}</td>
                                             <td className="py-2 text-right">
                                                <Dropdown>
                                                   <Dropdown.Toggle variant="" className="btn btn-primary tp-btn-light sharp i-false">
                                                      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                         <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24"></rect>
                                                            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                                                            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                                                            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                                                         </g>
                                                      </svg>
                                                   </Dropdown.Toggle>
                                                   <Dropdown.Menu>
                                                      <Link to={`/donor-edit/${doner?.id}`}>
                                                         <Dropdown.Item href={`/donor-edit/${doner?.id}`} >Edit</Dropdown.Item>
                                                      </Link>
                                                      <Link to={`/donor-details/${doner?.id}`}>
                                                         <Dropdown.Item href={`/donor-details/${doner?.id}`} >View</Dropdown.Item>
                                                      </Link>
                                                      {/* <Dropdown.Item href="#" className="text-danger">
                                                      Delete
                                                   </Dropdown.Item> */}
                                                   </Dropdown.Menu>
                                                </Dropdown>
                                             </td>
                                          </tr>
                                       )
                                    })
                                 }



                              </tbody>
                           </table>
                        </div>
                        <br />
                        <div>
                           <Pagination
                              activePage={doners?.current_page ? doners?.current_page : 0}
                              itemsCountPerPage={doners?.per_page ? doners?.per_page : 0}
                              totalItemsCount={doners?.total ? doners?.total : 0}
                              onChange={(pageNumber) => {

                                 dispatch(DonerPaggination({ q: searchQuary, pageNumber }))
                              }}
                              pageRangeDisplayed={8}
                              itemClass="page-item"
                              linkClass="page-link"
                              firstPageText="First Page"
                              lastPageText="Last Page"
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </PageLoader>

      </Fragment>
   );
};


const mapStateToProps = (state) => {
   return {
      doners: DonerListWithPaggination(state),
      isLoading: DonerIsLoading(state)
   };
};

export default connect(mapStateToProps)(Doners);


