import React from 'react'

const PageLoader = ({ isLoading, children }) => {
    return (
        <div className="loading-wraper">
            {
                isLoading &&
                (<div className='loader-holder'>
                    <div className="loader">
                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </div>
                </div>)
            }
            <div className="main-contet-holder">
                {children}
            </div>
        </div>
    )
}

export default PageLoader