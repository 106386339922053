import { createAsyncThunk } from "@reduxjs/toolkit";
import { getEvents } from "../../helper/BackendHelper";


const EventInitialLoad = createAsyncThunk(
    //action type string
    "event/initial",
    // callback function
    async (data, thunkAPI) => {



        const response = await getEvents(data);


        // console.log({ res });

        if (response?.status === 500) {
            return thunkAPI.rejectWithValue("Server Error");
        } else {
            if (response?.data) {


                return response.data;
            }
        }
    }
);



export { EventInitialLoad }