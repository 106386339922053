import DateRangePicker from "react-bootstrap-daterangepicker";
import React, { Fragment, useEffect, useState } from 'react'
import PageLoader from '../../layouts/PageLoader'
import PageTitle from '../../layouts/PageTitle'
import moment from "moment";
import { Button, Table } from "react-bootstrap";
import { downloadDonetionReport, downloadDonorReport, getDonetionReport, getDonorReport } from "../../../helper/BackendHelper";
import { curencyFormater, sumDonationAmount } from "../../../helper/formater";
import Pdf from "react-to-pdf";
import { Link } from "react-router-dom";
// import Pdf from '../../components/Document/Pdf'



const DonerReport = () => {

    const [isLoading, setIsLoading] = useState(false);

    const [donorList, setDonorList] = useState();

    useEffect(() => {
        setIsLoading(true)

        getDonorReport().then((res) => {
            if (res?.status === "success") {
                setDonorList(res?.data);
                setIsLoading(false)
            }
        })
    }, [])

    // console.log({ donationList });


    const downloadReportCSV = () => {
        downloadDonorReport().then((res) => {

            const blob = new Blob([res?.data], { type: res?.headers?.["content-type"] });

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `report-donors-${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
    }



    return (
        <Fragment>
            <PageLoader isLoading={isLoading} >
                {/* <PageTitle activeMenu="Report" motherMenu="Donation Report" /> */}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">


                                    <div className="col-lg-3">
                                        <div>

                                            <Button onClick={downloadReportCSV}><i className="las la-download scale5 me-3"></i> Download</Button>
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="report-table" >
                                            <div className="table-responsive">
                                                <table className="table mb-0 table-striped">
                                                    <thead>
                                                        <tr>

                                                            <th>Name</th>
                                                            <th>Email ID</th>
                                                            <th>Mobile Number</th>
                                                            <th>WhatsApp</th>
                                                            <th >
                                                                PAN
                                                            </th>
                                                            <th>Date of Registration</th>
                                                            <th>Total Contribution</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody id="Doners">

                                                        {
                                                            donorList?.map((doner, index) => {
                                                                let totalAmount = sumDonationAmount(doner?.donation)
                                                                return (
                                                                    <tr className="btn-reveal-trigger" key={index}>

                                                                        <td className="py-3">
                                                                            <Link to={`/donor-details/${doner?.id}`}>
                                                                                <div className="media d-flex align-items-center">

                                                                                    <div className="media-body">
                                                                                        <h5 className="mb-0 fs--1">
                                                                                            {doner?.name}
                                                                                        </h5>
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        </td>
                                                                        <td className="py-2">
                                                                            <a href={`mailto:${doner?.email}`}>
                                                                                {doner?.email}

                                                                            </a>
                                                                        </td>
                                                                        <td className="py-2">

                                                                            <a href={`tel:${doner?.phone}`}>{doner?.phone}
                                                                            </a>
                                                                        </td>
                                                                        <td className="py-2">

                                                                            <a href={`tel:${doner?.phone}`}>{doner?.phone}
                                                                            </a>
                                                                        </td>
                                                                        <td className="py-2 ">
                                                                            {doner?.pan ? doner?.pan : "No PAN Updated"}
                                                                        </td>

                                                                        <td className="py-2">{moment(doner?.created_at).format("DD MMM YYYY")}</td>
                                                                        <td className="py-2">₹{totalAmount}</td>

                                                                    </tr>
                                                                )
                                                            })
                                                        }



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </PageLoader>
        </Fragment>
    )
}

export default DonerReport