import React, { useEffect, useState } from "react";

// import EventCalendar from "./EventCalendar";

import PageTitle from "../../layouts/PageTitle";
import EventFullCalendar from "../../components/Common/EventFullCalendar";
import { connect } from "react-redux";
import { EventIsLoading, EventList } from "../../../store/selectors/EventsSelectors";
import { Fragment } from "react";
import PageLoader from "../../layouts/PageLoader";
import { getConnectList } from "../../../helper/BackendHelper";
import { formatDhanEvent } from "../../../helper/formater";

const Calendar = ({ events, isLoading }) => {

   const [dhanList, setdhanList] = useState([])

   useEffect(() => {
      getConnectList().then((res) => {
         // console.log({ res });

         if (res?.status === "success") {
            setdhanList(formatDhanEvent(res?.data))
         }

      })
   }, [])


   return (
      <Fragment>
         <PageLoader isLoading={isLoading} >
            <div className="h-80">
               {/* <PageTitle activeMenu="Calerdar" motherMenu="Event" /> */}
               <EventFullCalendar events={events} dhans={dhanList} />
            </div>
         </PageLoader>
      </Fragment>
   );
};

const mapStateToProps = (state) => {
   return {
      events: EventList(state),
      isLoading: EventIsLoading(state)
   };
};

export default connect(mapStateToProps)(Calendar);


