import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Select from "react-select";
import { getDonationOptions } from '../../../helper/BackendHelper';
import swal from "sweetalert";


const DonationSelect = ({ isOpen, onSubmit, close, id }) => {

    const [donationList, setDonationList] = useState([]);
    const [donationOptions, setDonationOptions] = useState([]);
    const [selectedDonation, setSelectedDonation] = useState();


    useEffect(() => {

        getDonationOptions(id).then((res) => {

            if (res?.status == "success") {
                setDonationList(res?.data)
            }

        })

    }, [])

    useEffect(() => {
        setDonationOptions(donationList.map((donation) => ({
            value: donation.lable,
            label: donation.lable,
        })))

    }, [donationList])

    const onDonationSelect = (data) => {


        setSelectedDonation(donationList.find((item) => item?.lable == data.value)?.value);

    }

    const onFormSubmit = () => {
        if (onSubmit) {
            onSubmit(selectedDonation);
            close()
        }
    }

    return (
        <>
            <Modal className="fade" show={isOpen} onHide={close}>
                <Modal.Header>
                    <Modal.Title>Select Donation</Modal.Title>
                    <Button onClick={close} variant="" className="btn-close"></Button>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="donner-select">
                            <Select
                                // defaultValue={selectedOption}
                                onChange={onDonationSelect}
                                options={donationOptions}
                                style={{
                                    lineHeight: "40px",
                                    color: "#7e7e7e",
                                    paddingLeft: " 15px",
                                }}
                                placeholder="Select Existing Donation to Link"
                                name='Donation'
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <Button onClick={onFormSubmit} variant="primary">Select</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DonationSelect