import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCampaign, getCampaignByPage, getDonetions, getDonetionsByPage } from "../../helper/BackendHelper";


const CampaignInitialLoad = createAsyncThunk(
    //action type string
    "campaign/initial",
    // callback function
    async (data, thunkAPI) => {



        const response = await getCampaign();


        // console.log({ res });

        if (response?.status === 500) {
            return thunkAPI.rejectWithValue("Server Error");
        } else {
            if (response?.data) {


                return response.data;
            }
        }
    }
);

const CampaignPaggination = createAsyncThunk(
    //action type string
    "campaign/paggination",
    // callback function
    async (data, thunkAPI) => {



        const response = await getCampaignByPage(data);


        // console.log({ res });

        if (response?.status === 500) {
            return thunkAPI.rejectWithValue("Server Error");
        } else {
            if (response?.data) {


                return response.data;
            }
        }
    }
);



export { CampaignInitialLoad, CampaignPaggination }