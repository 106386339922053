import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPayments, getPaymentsByPage, getUsers, getUsersByPage } from "../../helper/BackendHelper";


const PaymentInitialLoad = createAsyncThunk(
    //action type string
    "payment/initial",
    // callback function
    async (data, thunkAPI) => {



        const response = await getPayments();


        // console.log({ res });

        if (response?.status === 500) {
            return thunkAPI.rejectWithValue("Server Error");
        } else {
            if (response?.data) {


                return response.data;
            }
        }
    }
);

const PaymentPaggination = createAsyncThunk(
    //action type string
    "payment/paggination",
    // callback function
    async (data, thunkAPI) => {



        const response = await getPaymentsByPage(data);


        // console.log({ res });

        if (response?.status === 500) {
            return thunkAPI.rejectWithValue("Server Error");
        } else {
            if (response?.data) {


                return response.data;
            }
        }
    }
);

export { PaymentInitialLoad, PaymentPaggination }