import React, { useEffect } from 'react'
import { useState } from 'react'
import { changePassword } from '../../../helper/BackendHelper'
import { notifyError, notifySuccess } from '../../../helper/notification'

const ProfileEditForm = ({ user }) => {

    // console.log({ user });

    const [formData, setformData] = useState({})
    const [isloading, setIsloading] = useState(false)


    useEffect(() => {
        let userData = {
            "name": user?.name,
            "email": user?.email,
            "phone": user?.phone,
            "pan": user?.pan,
            "whatsapp": user?.whatsapp
        }

        setformData({ ...formData, ...userData })
    }, [user])


    const onChangeValue = (e) => {
        setformData({ ...formData, [e?.currentTarget?.name]: e?.currentTarget?.value })
    }

    const onFormSubmit = async (e) => {
        e.preventDefault();
        setIsloading(true)

        const res = await changePassword(formData);

        if (res?.status === 'success') {
            notifySuccess(res?.message);

            // swal(`${res?.message}`, ``, "success")
            setIsloading(false)


        } else {

            if (res.isAxiosError) {

                if (res?.response?.data?.errors) {
                    Object.values(res?.response?.data?.errors).map((errors) => errors.map((error) => notifyError(error)))
                } else {
                    notifyError(res?.response?.data?.message)
                }
            } else {
                notifyError(res?.message);
            }

            // console.log({ res });

            // swal("Oops", `${res?.response?.data?.message ? res?.response?.data?.message : res?.message}`, "error")
            setIsloading(false)

        }

    }

    return (
        <form onSubmit={onFormSubmit}>
            <fieldset disabled={isloading}>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-group mb-3">
                            <input
                                type="text"
                                className="form-control input-default "
                                placeholder="Name"
                                name='name'
                                required
                                onChange={onChangeValue}
                                value={formData?.name}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group mb-3">
                            <input
                                type="email"
                                className="form-control input-default "
                                placeholder="Email"
                                name='email'
                                required
                                onChange={onChangeValue}
                                value={formData?.email}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group mb-3">
                            <input
                                type="number"
                                className="form-control input-default "
                                placeholder="Mobile Number"
                                name='phone'
                                required
                                onChange={onChangeValue}
                                value={formData?.phone}

                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group mb-3">
                            <input
                                type="number"
                                className="form-control input-default "
                                placeholder="WhatsApp Number"
                                name='whatsapp'
                                required
                                onChange={onChangeValue}
                                value={formData?.whatsapp}

                            />
                        </div>
                    </div>
                    {/* <div className="col-lg-6">
                        <div className="form-group mb-3">
                            <input
                                type="text"
                                className="form-control input-default "
                                placeholder="PAN Number (optional)"
                                name='pan'
                                onChange={onChangeValue}
                                value={formData?.pan}

                            />
                        </div>
                    </div> */}
                </div>

                <button className="btn btn-primary" type="submit">{isloading ? "Editing...." : "Edit Profile"}</button>
            </fieldset>

        </form>
    )
}

export default ProfileEditForm