import React, { Fragment, useState } from 'react'
import { Accordion, Button, Form } from 'react-bootstrap';
import PageLoader from '../../layouts/PageLoader';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { useEffect } from 'react';
import { getEvent, imageUploadEvent, updateEvent } from '../../../helper/BackendHelper';
import { useHistory, useParams } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../helper/notification';



const initialData = {
    menus: [
    ],
    date: moment().format("YYYY-MM-DD")
}

const baseUrl = process.env.REACT_APP_API_URL;


const EventEdit = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [formData, setformData] = useState(initialData);

    const [isUploading, setIsUploading] = useState(false)

    const router = useHistory();
    const { id } = useParams();

    const [imageFile, setImageFile] = useState();




    const onChangeValue = (e) => {
        setformData({ ...formData, [e?.currentTarget?.name]: e?.currentTarget?.value })
    }

    useEffect(() => {
        setIsLoading(true)
        getEventData()

    }, [])

    const getEventData = () => {
        getEvent(id).then((res) => {
            if (res?.status === "success") {

                let menu = res?.data?.details?.menu ? JSON.parse(res?.data?.details?.menu) : null;

                setformData({
                    ...formData, name: res?.data?.name,
                    description: res?.data?.description,
                    date: moment(res?.data?.date).format("YYYY-MM-DD"),
                    details: res?.data?.details,
                    menus: menu?.list,
                    price: menu?.price,
                    tags: res?.data?.details?.tags,
                    type: res?.data?.details?.type,
                })
                setIsLoading(false)

            }
        })
    }


    const formSubmit = async (e) => {
        e.preventDefault();

        let newFormData = {
            name: formData?.name,
            description: formData?.description,
            date: formData?.date,
            details: {
                menu: JSON.stringify({
                    list: formData?.menus,
                    price: formData?.price
                }),
                id: formData?.details?.id ? formData?.details?.id : null,
                type: formData?.type ? formData?.type : null,
                tags: formData?.tags ? formData?.tags : null
            },

        }

        updateEvent(id, newFormData).then((res) => {
            if (res?.status === "success") {
                notifySuccess(res?.message);
                setIsLoading(false)
                router.push('/list-of-events')
            } else {
                // notifyError(res?.message);

                if (res.isAxiosError) {

                    if (res?.response?.data?.errors) {
                        Object.values(res?.response?.data?.errors).map((errors) => errors.map((error) => notifyError(error)))
                    } else {
                        notifyError(res?.response?.data?.message)
                    }
                } else {
                    notifyError(`${res?.response?.data?.message ? res?.response?.data?.message : res?.message}`)

                }

                setIsLoading(false);


            }
        })


    }

    const menuDataChangeHandle = (value, name, i) => {

        let newArray = formData?.menus ? [...formData?.menus] : []
        newArray[i] = newArray[i] ? { ...newArray[i], [name]: value } : { [name]: value };
        setformData({ ...formData, menus: newArray })

    }

    const removeThisMenu = (i) => {
        let newArray = [...formData?.menus];
        newArray.splice(i, 1);
        setformData({ ...formData, menus: newArray })

    }

    const uploadHandle = () => {

        if (imageFile) {
            setIsUploading(true)
            let fd = new FormData();
            fd.append('image', imageFile[0])

            imageUploadEvent(id, fd).then((res) => {

                if (res?.status === "success") {
                    getEventData();
                    notifySuccess(res?.message);
                    setIsUploading(false)
                } else {
                    // notifyError(res?.message);

                    if (res.isAxiosError) {

                        if (res?.response?.data?.errors) {
                            Object.values(res?.response?.data?.errors).map((errors) => errors.map((error) => notifyError(error)))
                        } else {
                            notifyError(res?.response?.data?.message)
                        }
                    } else {
                        notifyError(`${res?.response?.data?.message ? res?.response?.data?.message : res?.message}`)

                    }

                    setIsLoading(false);


                }
            })
        }

    }

    // console.log({ formData });



    return (
        <Fragment>
            <PageLoader isLoading={isLoading} >
                <div className="h-80">
                    {/* <PageTitle activeMenu="Calerdar" motherMenu="Event" /> */}
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="card">
                                <div className="card-header container-fluid" style={{ display: "block" }}>
                                    <div className="row">
                                        <div className="col-md-10">
                                            <h4 className="card-title">Event Details</h4>
                                        </div>

                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="basic-form">
                                        <form onSubmit={formSubmit}>
                                            <fieldset disabled={isLoading}>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="">Enter Event Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control input-default "
                                                                placeholder={"Name of the Event"}
                                                                name={`name`}
                                                                required
                                                                onChange={onChangeValue}
                                                                value={formData?.name}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <label htmlFor="">Select Event Date</label>
                                                        <div className="form-group mb-3">
                                                            <DatePicker className="form-control" selected={new Date(formData?.date)} onSelect={(date) => setformData({ ...formData, date: moment(date).format("YYYY-MM-DD") })} placeholderText="Select Event Date" dateFormat="yyyy-MM-dd" />

                                                        </div>
                                                    </div>



                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="">Enter Event Type</label>
                                                            <input
                                                                type="text"
                                                                className="form-control input-default "
                                                                placeholder={"Type of the Event"}
                                                                name={`type`}
                                                                required
                                                                onChange={onChangeValue}
                                                                value={formData?.type}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="">Enter Event Tags</label>
                                                            <input
                                                                type="text"
                                                                className="form-control input-default "
                                                                placeholder={"Event Tags"}
                                                                name={`tags`}
                                                                required
                                                                onChange={onChangeValue}
                                                                value={formData?.tags}

                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group mb-3">
                                                            <textarea
                                                                name={`description`}
                                                                className="form-control input-default "
                                                                placeholder={`Describe about Event `} rows="5"
                                                                onChange={onChangeValue}

                                                                value={formData?.description}
                                                            >
                                                            </textarea>
                                                        </div>

                                                        {
                                                            formData?.menus?.length > 0 && (
                                                                <>
                                                                    <h4>Meal Menu</h4>


                                                                    <Accordion className="accordion accordion-rounded-stylish accordion-bordered" defaultActiveKey="0">
                                                                        {formData?.menus.map((menu, i) => (
                                                                            <Accordion.Item key={i} eventKey={`${i}`}>
                                                                                <Accordion.Header className="accordion-header accordion-header-primary">
                                                                                    {(menu?.name !== "" && menu?.name) ? menu?.name : `Menu Item ${i + 1}`}
                                                                                    <span className="accordion-header-indicator "></span>

                                                                                </Accordion.Header>
                                                                                <Accordion.Collapse eventKey={`${i}`} className="accordion__body">
                                                                                    <div className="accordion-body"><div className='event'>
                                                                                        <div className="row">
                                                                                            <div className="col-lg-6">
                                                                                                <div className="form-group mb-3">
                                                                                                    <label htmlFor="">Select Menu Item Name</label>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        className="form-control input-default "
                                                                                                        placeholder={"Name of the Menu Item"}
                                                                                                        name={`name`}
                                                                                                        required
                                                                                                        value={menu?.name}

                                                                                                        onChange={(e) => menuDataChangeHandle(e.currentTarget.value, e.currentTarget.name, i)}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* <div className="col-lg-6">
                                                                            <div className="form-group mb-3">
                                                                                <label htmlFor="">Select Event Name</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control input-default "
                                                                                    placeholder={"Name of the Event"}
                                                                                    name={`name`}
                                                                                    required
                                                                                    onChange={(e) => eventDataChangeHandle(e.currentTarget.value, e.currentTarget.name, i)}
                                                                                />
                                                                            </div>
                                                                        </div> */}


                                                                                        </div>
                                                                                        <div className="row">


                                                                                            <div className="col-lg-6">
                                                                                                {
                                                                                                    (formData?.menus?.length - 1 === i)
                                                                                                        ? (<Button variant="primary" onClick={() => setformData({
                                                                                                            ...formData, menus: [...formData?.menus, {
                                                                                                            }]
                                                                                                        })} >Add Menu item</Button>)
                                                                                                        : (
                                                                                                            <Button variant="danger" onClick={() => removeThisMenu(i)} >Remove this item</Button>
                                                                                                        )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div></div>
                                                                                </Accordion.Collapse>
                                                                            </Accordion.Item>
                                                                        ))}
                                                                    </Accordion>


                                                                    <div className="form-group mb-3">
                                                                        <label htmlFor="">Meal Price</label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control input-default "
                                                                            placeholder={"Meal Price"}
                                                                            name={`price`}
                                                                            required
                                                                            onChange={onChangeValue}
                                                                            value={formData?.price}
                                                                        />
                                                                    </div>
                                                                </>

                                                            )
                                                        }
                                                    </div>

                                                    <div className="col-lg-6">



                                                        <Form.Label>Select Image</Form.Label>
                                                        <div className="row">
                                                            <div className="col-lg-8">
                                                                <Form.Group controlId="formFile" className="mb-3">

                                                                    <Form.Control type="file" onChange={(e) => setImageFile(e.currentTarget.files)} />
                                                                </Form.Group>

                                                            </div>
                                                            <div className="col-lg-4">

                                                                <Button variant="primary" onClick={uploadHandle} disabled={isUploading} >{isUploading ? "Uploading" : "Upload"}</Button>
                                                            </div>
                                                        </div>

                                                        {
                                                            formData?.details?.image && (
                                                                <div className="image-holder">
                                                                    <picture>
                                                                        <img src={`${baseUrl}/${formData?.details?.image}`} alt="image" />
                                                                    </picture>
                                                                </div>
                                                            )
                                                        }

                                                    </div>



                                                </div>



                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <Button type='submit' variant="primary">{isLoading ? "Editing...." : "Edit Event"}</Button>

                                                    </div>
                                                    {
                                                        formData?.menus?.length > 0 ? (

                                                            <div className="col-lg-6">
                                                                <Button variant="danger" onClick={() => setformData({
                                                                    ...formData, menus: []
                                                                })}>Remove Meal Menu</Button>

                                                            </div>

                                                        ) : (


                                                            <div className="col-lg-6">
                                                                <Button variant="primary" onClick={() => setformData({
                                                                    ...formData, menus: formData?.menus ? [...formData?.menus, {}] : [{}]
                                                                })}>Add Meal Menu</Button>

                                                            </div>
                                                        )
                                                    }

                                                </div>
                                            </fieldset>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageLoader>
        </Fragment>
    )
}

export default EventEdit