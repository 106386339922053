import { lazy, Suspense, useEffect } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import { useLocalStorage } from './hooks/useLocalStorage';
import { useClearCache } from 'react-clear-cache';

const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
    });
});

function App(props) {
    const dispatch = useDispatch();
    useEffect(() => {
        checkAutoLogin(dispatch, props.history);
    }, [dispatch, props.history]);

    const [needToReaload] = useLocalStorage("needToReaload");

    const { isLatestVersion, latestVersion, emptyCacheStorage } = useClearCache();

    // console.log({ props });

    useEffect(() => {

        if (localStorage) {
            if (!needToReaload || !isLatestVersion) {

                console.log("Cache Clearing");
                localStorage.setItem('needToReaload', true);
                emptyCacheStorage()

            }
        }

    }, [needToReaload, isLatestVersion])


    let routes = (
        <>
            <ToastContainer />
            <Switch>

                <Route path='/login' component={Login} />
                <Route exact path="/">
                    <Redirect to="/login" />
                </Route>
                <Route path='/create-password/:token' component={SignUp} />
                <Route path='/forgot-password' component={ForgotPassword} />
            </Switch>
        </>

    );
    if (props.isAuthenticated) {
        return (
            <>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    <Index />
                </Suspense>
            </>
        );

    } else {
        return (
            <>

                <div className="vh-100">

                    <Suspense fallback={
                        <div id="preloader">
                            <div className="sk-three-bounce">
                                <div className="sk-child sk-bounce1"></div>
                                <div className="sk-child sk-bounce2"></div>
                                <div className="sk-child sk-bounce3"></div>
                            </div>
                        </div>
                    }
                    >

                        {routes}
                    </Suspense>
                </div>
            </>

        );
    }
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App));

