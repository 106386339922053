import React, { Fragment, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Button, Dropdown } from "react-bootstrap";




import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";


import Pagination from "react-js-pagination";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

import PageLoader from "../../layouts/PageLoader";
import { CampaignInitialLoad, CampaignPaggination } from "../../../store/thunk/CampaignThunk";
import { CampaignIsLoading, CampaignListWithPaggination } from "../../../store/selectors/CampaignSelector";
import moment from "moment";


const Campaigns = ({ campaigns, isLoading }) => {

   // console.log({ campaigns });

   const dispatch = useDispatch();

   const [searchQuary, setSearchQuary] = useState("");

   useEffect(() => {
      dispatch(CampaignInitialLoad());

   }, [])

   useEffect(() => {
      dispatch(CampaignPaggination({ q: searchQuary, pageNumber: 1 }))

   }, [searchQuary])

   // const clearFilter = () => {
   //    setSearchQuary("");
   // }

   // console.log((donetions));





   return (
      <Fragment>
         <PageLoader isLoading={isLoading} >

            {/* <PageTitle activeMenu="Campaigns List" motherMenu="Campaigns" /> */}

            <div className="row">
               <div className="col-lg-12">
                  <div className="card">
                     <div className="card-body">

                        <div className="table-responsive">
                           <table className="table mb-0 table-striped">
                              <thead>
                                 <tr>
                                    <th>Name of the Campaign</th>
                                    <th>Description</th>
                                    <th>From</th>
                                    <th>To</th>
                                    <th></th>
                                 </tr>
                              </thead>
                              <tbody id="campaigns">

                                 {
                                    campaigns?.data?.map((campaign, index) => (
                                       <tr className="btn-reveal-trigger">

                                          <td className="py-3">
                                             <Link to={`/view-campaign/${campaign?.id}`}>
                                                <div className="media d-flex align-items-center">

                                                   <div className="media-body">
                                                      <h5 className="mb-0 fs--1">
                                                         {campaign?.name}
                                                      </h5>
                                                   </div>
                                                </div>
                                             </Link>
                                          </td>
                                          <td className="py-2">
                                             {campaign?.description}
                                          </td>
                                          <td className="py-2">
                                             {moment(campaign?.start_date).format("DD MMM YYYY")}

                                          </td>
                                          <td className="py-2 ">

                                             {moment(campaign?.end_date).format("DD MMM YYYY")}
                                          </td>


                                          <td className="py-2">
                                             <Link to={`/campaign-edit/${campaign?.id}`}>
                                                <div className="btn btn-primary tp-btn-light sharp i-false">
                                                   <i className="fas fa-edit"></i>
                                                </div>
                                             </Link>

                                          </td>

                                       </tr>
                                    ))
                                 }

                              </tbody>
                           </table>
                        </div>
                        <br />
                        <div>
                           <Pagination
                              activePage={campaigns?.current_page ? campaigns?.current_page : 0}
                              itemsCountPerPage={campaigns?.per_page ? campaigns?.per_page : 0}
                              totalItemsCount={campaigns?.total ? campaigns?.total : 0}
                              onChange={(pageNumber) => {
                                 dispatch(CampaignPaggination({ q: searchQuary, pageNumber }))
                              }}
                              pageRangeDisplayed={8}
                              itemClass="page-item"
                              linkClass="page-link"
                              firstPageText="First Page"
                              lastPageText="Last Page"
                           />
                        </div>


                     </div>
                  </div>
               </div>
            </div>
         </PageLoader>
      </Fragment>
   );
};


const mapStateToProps = (state) => {
   return {
      campaigns: CampaignListWithPaggination(state),
      isLoading: CampaignIsLoading(state)
   };
};

export default connect(mapStateToProps)(Campaigns);


