import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { Card, Col, Row, Table } from 'react-bootstrap'
import { curencyFormater } from '../../../helper/formater';
import { getMealsTable } from '../../../helper/BackendHelper';

const PludgeMeal = ({ isOpen, onSelelct, close }) => {

    const [mealsData, setMealsData] = useState([]);
    const [formData, setformData] = useState({ menus: [], amount: 0, pax: 1 });
    const [selectedMenu, setSelectedMenu] = useState([]);
    const [selectedMealTypes, setSelectedMealTypes] = useState([])


    useEffect(() => {

        getMealsTable().then((res) => {

            if (res?.status == "success") {
                setMealsData(res?.data)
            }

        })

    }, [])

    useEffect(() => {

        if (formData?.pax > 0 && selectedMenu?.length > 0) {

            let totalAmount = 0;

            for (let i = 0; i < selectedMenu.length; i++) {  //loop through the array
                totalAmount += selectedMenu[i].price;  //Do the math!
            }


            setformData({ ...formData, amount: formData?.pax * totalAmount })


        }

    }, [formData?.pax, selectedMenu])

    const onMealTypeCheck = (isChecked, type) => {
        if (isChecked) {

            let menus = [...type?.menus]
            menus[0] = { ...menus[0], active: true };

            let newMenuTypes = [...selectedMealTypes, type = { ...type, menus }]
            setSelectedMealTypes(newMenuTypes)

        } else {
            let newMenuTypes = [...selectedMealTypes];

            let index = -1;

            let filteredObj = selectedMealTypes.find(function (item, i) {
                if (item?.id === type?.id) {
                    index = i;
                    return i;
                }
            });

            if (index !== -1) {

                // console.log(newMenuTypes[index]);
                newMenuTypes.splice(index, 1);

            }



            setSelectedMealTypes(newMenuTypes)
        }
    }


    useEffect(() => {
        let menuIds = [];
        let menus = [];

        selectedMealTypes?.map((meal) => {
            meal?.menus?.map((menu) => {
                if (menu?.active) {
                    menu?.items?.map((item) => {
                        menuIds?.push(item?.id)
                        menus?.push(item)
                    })

                }
            })
        })

        setformData({ ...formData, menus: menuIds })
        setSelectedMenu(menus)
        // console.log({ menuIds, menus });

    }, [selectedMealTypes])




    // const onMealCheck = (isChecked, menu) => {
    //     // console.log({ isChecked, menu });
    //     if (isChecked) {
    //         let newMenus = [...formData?.menus, menu?.id]
    //         setformData({ ...formData, menus: newMenus })


    //         setSelectedMenu([...selectedMenu, menu])


    //     } else {
    //         let newMenus = [...formData?.menus];

    //         let index = newMenus.indexOf(menu?.id);
    //         if (index !== -1) {
    //             newMenus.splice(index, 1);
    //         }

    //         console.log({ newMenus });

    //         setformData({ ...formData, menus: newMenus })

    //         let newMenus2 = [...selectedMenu];

    //         let index2 = -1;

    //         let filteredObj = selectedMenu.find(function (item, i) {
    //             if (item?.id === menu?.id) {
    //                 index2 = i;
    //                 return i;
    //             }
    //         });



    //         if (index2 !== -1) {
    //             newMenus2.splice(index2, 1);
    //         }

    //         setSelectedMenu(newMenus2)


    //     }
    // }


    const onSubmit = () => {
        if (onSelelct) {
            if (formData?.amount > 0 && formData?.pax > 0) {
                onSelelct(formData)
            }
            close()
        }
    }

    const onPaxChange = (e) => {
        setformData({ ...formData, pax: e?.currentTarget?.value })
    }

    const onMenuSelect = (isChecked, meal, menu) => {
        let menus = [...meal?.menus]
        let mealIndex = selectedMealTypes?.findIndex((type) => type?.id == meal?.id)
        let tempIndex = menus?.findIndex((type) => type?.id == menu?.id)
        menus[tempIndex] = { ...menus[tempIndex], active: true };
        let newMenuTypes = [...selectedMealTypes];
        newMenuTypes[mealIndex] = { ...meal, menus }
        setSelectedMealTypes(newMenuTypes)
    }



    // console.log({ formData });

    return (
        <>
            <Modal className="fade" show={isOpen} onHide={close}>
                <Modal.Header>
                    <Modal.Title>Select Your Meal</Modal.Title>
                    <Button onClick={close} variant="" className="btn-close"></Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="meal-menu-form-holder">
                        <div className="form-holder">
                            <div className="row">



                                <div className="col-lg-12">

                                    <div className="col-auto">
                                        <label>Number of pax you would like to serve:</label>
                                        <div className="input-group mb-3">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="pax"
                                                name='pax'
                                                required
                                                onChange={onPaxChange}
                                                min="1"
                                                value={formData?.pax}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="menu-list">
                            {
                                mealsData?.map((meal, index) => {

                                    let isSelected = selectedMealTypes?.find((type) => type?.id == meal?.id);



                                    return (<>

                                        <strong>
                                            <Form.Check
                                                type={`checkbox`}
                                                id={meal?.name}
                                                label={meal?.name}
                                                onChange={(e) => onMealTypeCheck(e.currentTarget?.checked, meal)}
                                            />
                                        </strong>

                                        {
                                            isSelected && (
                                                <div className="menu-list-holder" style={{ marginLeft: "1rem" }}>
                                                    <Row key={index}>
                                                        {
                                                            isSelected?.menus?.map((menu, i) => {

                                                                return (<Col lg={4} md={6}>
                                                                    <Form.Check
                                                                        type={`radio`}
                                                                        id={menu?.name}
                                                                        label={menu?.name}
                                                                        name={meal?.name}
                                                                        value={menu?.id}
                                                                        checked={menu?.active}
                                                                        onChange={(e) => onMenuSelect(e?.currentTarget?.checked, meal, menu)}
                                                                    />
                                                                </Col>)
                                                            })
                                                        }

                                                    </Row>
                                                </div>
                                            )
                                        }



                                    </>)

                                })
                            }
                        </div>
                        <br />
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "space-between" }}>
                    <div className="transaction-details">
                        <div className="amount-bx mb-3 border">
                            <i className="fas fa-rupee-sign"></i>
                            <div>
                                <p className="mb-1">Total Amount</p>
                                <h3 className="mb-0">₹ {formData?.amount}</h3>
                            </div>
                        </div>
                    </div>

                    <Button onClick={onSubmit} variant="primary">Select</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PludgeMeal