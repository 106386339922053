import React, { Fragment, useState } from 'react'
import PageTitle from '../../layouts/PageTitle'
import Select from "react-select";


import countries from '../../../mock/countries.json';
import { Button, Form } from 'react-bootstrap';
import swal from 'sweetalert';
import { addUser, editUser, getDoner, getRoles } from '../../../helper/BackendHelper';
import { useHistory, useParams } from 'react-router-dom';
import PageLoader from '../../layouts/PageLoader';
import { useEffect } from 'react';
import { notifyError } from '../../../helper/notification';

const initialData = {}

const EditVolunteer = () => {

    // console.log({countries});

    const [formData, setformData] = useState(initialData);
    const [isLoading, setIsLoading] = useState(false);

    const router = useHistory();
    const { id } = useParams();


    const [roleList, setRoleList] = useState([])
    const [roleOption, setRoleOption] = useState([])

    useEffect(() => {
        setIsLoading(true);

        getRoles().then((res) => {

            if (res?.status == "success") {
                setRoleList(res?.data)
            }

        })

        getDoner(id).then((res) => {

            if (res?.status == "success") {
                let userData = {
                    "name": res?.data?.name,
                    "email": res?.data?.email,
                    "address": res?.data?.address?.address,
                    "city": res?.data?.address?.city,
                    "pincode": res?.data?.address?.pincode,
                    "country": res?.data?.address?.country,
                    "phone": res?.data?.phone,
                    "pan": res?.data?.pan,
                    "role": res?.data?.role,
                    "is_active": res?.data?.is_active,
                    "salutation": res?.data?.salutation,
                    "is_indian": res?.data?.is_indian,
                    "role_id": res?.data?.role?.id,
                    "whatsapp": res?.data?.whatsapp
                }

                setIsLoading(false);

                setformData({ ...formData, ...userData })
            }

        })

    }, [])


    useEffect(() => {
        setRoleOption(roleList.map((role) => ({
            value: role.lable,
            label: role.lable,
        })))
    }, [roleList])



    const onChangeValue = (e) => {
        setformData({ ...formData, [e?.currentTarget?.name]: e?.currentTarget?.value })
    }

    const countriesOptions = countries.map((country) => ({
        value: country.name,
        label: country.name,
    }))


    const formSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const res = await editUser(formData, id);

        if (res?.status === 'success') {

            swal(`User edited`, ``, "success")
            setIsLoading(false);
            router.push('/list-of-users')

        } else {

            if (res.isAxiosError) {

                if (res?.response?.data?.errors) {
                    Object.values(res?.response?.data?.errors).map((errors) => errors.map((error) => notifyError(error)))
                } else {
                    notifyError(res?.response?.data?.message)
                }
            } else {
                swal("Oops", `${res?.response?.data?.message ? res?.response?.data?.message : res?.message}`, "error")
            }
            setIsLoading(false);

        }
    }

    // console.log({ formData });

    return (
        <Fragment>
            <PageLoader isLoading={isLoading} >
                {/* <PageTitle activeMenu="User" motherMenu="Add New User" /> */}
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">User Details</h4>
                            </div>
                            <div className="card-body">
                                <div className="basic-form">
                                    <form onSubmit={formSubmit}>
                                        <fieldset disabled={isLoading}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control input-default "
                                                            placeholder="Name"
                                                            name='name'
                                                            required
                                                            onChange={onChangeValue}
                                                            value={formData?.name}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="email"
                                                            className="form-control input-default "
                                                            placeholder="Email"
                                                            name='email'
                                                            required
                                                            onChange={onChangeValue}
                                                            value={formData?.email}

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="number"
                                                            className="form-control input-default "
                                                            placeholder="Mobile Number"
                                                            name='phone'
                                                            required
                                                            onChange={onChangeValue}
                                                            value={formData?.phone}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="number"
                                                            className="form-control input-default "
                                                            placeholder="WhatsApp Number"
                                                            name='whatsapp'

                                                            onChange={onChangeValue}
                                                            value={formData?.whatsapp}

                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control input-default "
                                                            placeholder="PAN Number (optional)"
                                                            name='pan'
                                                            onChange={onChangeValue}
                                                            value={formData?.pan}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">

                                                        <Select
                                                            // defaultValue={selectedOption}
                                                            onChange={(data) => setformData({ ...formData, country: data?.value })}
                                                            options={countriesOptions}
                                                            style={{
                                                                lineHeight: "40px",
                                                                color: "#7e7e7e",
                                                                paddingLeft: " 15px",
                                                            }}
                                                            placeholder="Select Country"
                                                            name='country'
                                                            value={
                                                                countriesOptions.filter(option =>
                                                                    option.label === formData?.country)
                                                            }
                                                        />

                                                    </div>
                                                </div>


                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <textarea name="address" id="address" className="form-control input-default "
                                                            placeholder="Address" rows="5"
                                                            onChange={onChangeValue}
                                                            value={formData?.address}
                                                        >

                                                        </textarea>

                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">

                                                        <Select
                                                            // defaultValue={selectedOption}
                                                            onChange={(data) => setformData({ ...formData, role_id: roleList.find((item) => item?.lable == data.value)?.value })}
                                                            options={roleOption}
                                                            style={{
                                                                lineHeight: "40px",
                                                                color: "#7e7e7e",
                                                                paddingLeft: " 15px",
                                                            }}
                                                            placeholder="Select Role"
                                                            name='role'
                                                            value={
                                                                roleOption.filter(option =>
                                                                    option.label === formData?.role?.name)
                                                            }
                                                        />

                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <Form.Check
                                                            type="switch"
                                                            id="isActive"
                                                            label="is Active"
                                                            onChange={(e) => setformData({
                                                                ...formData,
                                                                is_active: e.currentTarget.checked
                                                            })}
                                                            checked={formData?.is_active}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control input-default "
                                                            placeholder="City"
                                                            name='city'
                                                            value={formData?.city}
                                                            onChange={onChangeValue}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            type="number"
                                                            className="form-control input-default "
                                                            placeholder="Pincode"
                                                            name='pincode'
                                                            value={formData?.pincode}

                                                            onChange={onChangeValue}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <Button type='submit' variant="primary">{isLoading ? "Editing...." : "Edit User"}</Button>

                                                </div>

                                            </div>
                                        </fieldset>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageLoader>

        </Fragment>
    )
}

export default EditVolunteer