import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { addEvent, getEventTypes } from '../../../helper/BackendHelper';
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import { EventInitialLoad } from '../../../store/thunk/EventThunk';
import { notifyError } from '../../../helper/notification';




const initialData = {}


const AddEvent = ({ isOpen, close, date }) => {

    const [formData, setformData] = useState(initialData);
    const [eventTypes, setEventTypes] = useState([])
    const [eventOptions, setEventOptions] = useState([])

    const dispatch = useDispatch();


    const onChangeValue = (e) => {
        setformData({ ...formData, [e?.currentTarget?.name]: e?.currentTarget?.value })
    }



    useEffect(() => {
        getEventTypes().then((res) => {
            if (res?.status === "success") {
                setEventTypes(res?.data)
            }
        })
    }, [])

    useEffect(() => {
        if (date) {
            setformData({ ...formData, date: moment(date).format("YYYY-MM-DD") })
        }
    }, [date])



    useEffect(() => {
        setEventOptions(eventTypes.map((eventType) => ({
            value: eventType.lable,
            label: eventType.lable,
        })))

    }, [eventTypes])

    const onSubmit = async (e) => {
        e.preventDefault();

        const res = await addEvent(formData);

        if (res?.status === 'success') {
            // notifySuccess(res?.message);

            swal(`${res?.message}`, ``, "success")

            dispatch(EventInitialLoad({
                fromDate: moment().startOf('month').format('YYYY-MM-DD'),
                toDate: moment().endOf('month').format('YYYY-MM-DD')
            }))

            if (close) {
                close();
            }
        } else {
            // notifyError(res?.message);

            if (res.isAxiosError) {

                if (res?.response?.data?.errors) {
                    Object.values(res?.response?.data?.errors).map((errors) => errors.map((error) => notifyError(error)))
                } else {
                    notifyError(res?.response?.data?.message)
                }
            } else {
                swal("Oops", `${res?.response?.data?.message ? res?.response?.data?.message : res?.message}`, "error")
            }



        }

        // console.log({ formData });
    }


    return (
        <Modal className="fade" show={isOpen} onHide={close}>
            <Modal.Header>
                <Modal.Title>New Event on {moment(date).format("DD MMM YYYY")}</Modal.Title>
                <Button onClick={close} variant="" className="btn-close"></Button>
            </Modal.Header>
            <form onSubmit={onSubmit}>
                <Modal.Body>
                    <div>

                        <div className="basic-form">

                            <div className="row">

                                <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control input-default "
                                            placeholder={formData?.event_type_id === 2 ? "Name of the person" : "Name of the Event"}
                                            name='name'
                                            required
                                            onChange={onChangeValue}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                        <textarea name="description" id="description" className="form-control input-default "
                                            placeholder={`Describe about ${formData?.event_type_id === 2 ? "Person" : "Event"} `} rows="5"
                                            onChange={onChangeValue}
                                            required
                                        >
                                        </textarea>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={close}
                        variant="danger light"
                        type='reset'
                    >
                        Close
                    </Button>
                    <Button type='submit' variant="primary">Add Event</Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default AddEvent