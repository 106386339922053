/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import LogoutPage from './Logout';

/// Image
//import user from "../../../images/user.jpg";
import profile from "../../../images/user.png";
import { getUserMe } from "../../../store/selectors/UserSelectors";
import { connect } from "react-redux";
import { isAdmin } from "../../../helper/roleHelper";


class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = ({ user }) => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);


  // console.log({ user });

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    // //sidebar icon Heart blast
    // var handleheartBlast = document.querySelector('.heart');
    // function heartBlast() {
    //   return handleheartBlast.classList.toggle("heart-blast");
    // }
    // handleheartBlast.addEventListener('click', heartBlast);

  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let deshBoard = [
    "",
  ]

  let donersMenu = [
    "donor-add",
    "list-of-donors",
    "donor-details",
    "donor-view",
  ]


  let userMenu = [
    "user-add",
    "list-of-users",
    "user-edit",
    "user-view",
  ]



  let donetionMenu = [
    "donations-add",
    "list-of-donations",
    "donation-details",
    "donations-view",
  ]

  let paymentsMenu = [
    "add-payment",
    "list-of-payments",
    "payments-edit",
    "payments-view",
  ]

  let campaignMenu = [
    "add-campaign",
    "existing-campaigns",
    "campaign-edit",
    "view-campaign",
  ]

  let eventMenu = [
    "event-calendar",
    "list-of-events",
    "event-edit",
    "event-view",
  ]

  let mealMenuItemMenu = [
    "modify-menu",
    "existing-menu",
  ]

  let reportMenu = [
    "report-donation",
    "report-donor",
    "report-edit",
    "report-view",
    "report-calender",
  ]

  let settingsMenu = [
    "profile-settings",
  ]



  // donetions-list




  return (
    <div
      className={`dlabnav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? scrollPosition > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <Dropdown className="dropdown header-profile2">
          <Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
            <div className="header-info2 d-flex align-items-center border">
              <img src={profile} width={20} alt="" />
              <div className="d-flex align-items-center sidebar-info">
                <div>
                  <span className="font-w700 d-block mb-2 user-name">{user?.name}</span>
                  <small className="text-end font-w400">{user?.role?.name}</small>
                </div>
                <i className="fas fa-sort-down ms-4"></i>
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu align="right" className=" dropdown-menu dropdown-menu-end">
            <Link to="/profile-settings" className="dropdown-item ai-icon">
              <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary me-1"
                width={18} height={18} viewBox="0 0 24 24" fill="none"
                stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                <circle cx={12} cy={7} r={4} />
              </svg>
              <span className="ms-2">Profile </span>
            </Link>
            {/* <Link to="/email-inbox" className="dropdown-item ai-icon">
              <svg id="icon-inbox" xmlns="http://www.w3.org/2000/svg" className="text-success me-1" width={18}
                height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2}
                strokeLinecap="round" strokeLinejoin="round"
              >
                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                <polyline points="22,6 12,13 2,6" />
              </svg>
              <span className="ms-2">Inbox</span>
            </Link> */}
            <LogoutPage />
          </Dropdown.Menu>
        </Dropdown>
        <MM className="metismenu" id="menu">
          <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
            <Link to="/dashboard">
              <i className="fas fa-home"></i>
              <span className="nav-text">Dashboard</span>
            </Link>

          </li>

          <li className={`${donetionMenu.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="flaticon-381-gift"></i><span className="nav-text">Donations</span>
            </Link>
            <ul>
              <li><Link className={`${path === donetionMenu[0] ? "mm-active" : ""}`} to={`/${donetionMenu[0]}`}>Add Donations</Link></li>
              <li><Link className={`${path === donetionMenu[1] ? "mm-active" : ""}`} to={`/${donetionMenu[1]}`}>View Donations</Link></li>
            </ul>
          </li>

          <li className={`${donersMenu.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="flaticon-381-user-7"></i><span className="nav-text">Donors</span>
            </Link>
            <ul>
              <li><Link className={`${path === donersMenu[0] ? "mm-active" : ""}`} to={`/${donersMenu[0]}`}>Add new Donor</Link></li>
              <li><Link className={`${path === donersMenu[1] ? "mm-active" : ""}`} to={`/${donersMenu[1]}`}>View Donors List</Link></li>
            </ul>
          </li>

          {
            isAdmin(user?.role) && (
              <li className={`${userMenu.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#" >
                  <i className="flaticon-381-user-8"></i><span className="nav-text">Users</span>
                </Link>
                <ul>
                  <li><Link className={`${path === userMenu[0] ? "mm-active" : ""}`} to={`/${userMenu[0]}`}>Add new User</Link></li>
                  <li><Link className={`${path === userMenu[1] ? "mm-active" : ""}`} to={`/${userMenu[1]}`}>View Users List</Link></li>
                </ul>
              </li>
            )
          }

          {/* <li className={`${mealMenuItemMenu.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i class="fa fa-utensils"></i><span className="nav-text">Meals Menu</span>
            </Link>
            <ul>
              {
                isAdmin(user?.role) && (
                  <li><Link className={`${path === mealMenuItemMenu[0] ? "mm-active" : ""}`} to={`/${mealMenuItemMenu[0]}`}>Modify Menu</Link></li>
                )
              }
              <li><Link className={`${path === mealMenuItemMenu[1] ? "mm-active" : ""}`} to={`/${mealMenuItemMenu[1]}`}>Existing Menu</Link></li>
            </ul>
          </li> */}



          <li className={`${paymentsMenu.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="fas fa-rupee-sign"></i><span className="nav-text">Payments</span>
            </Link>
            <ul>
              {
                isAdmin(user?.role) && (
                  <li><Link className={`${path === paymentsMenu[0] ? "mm-active" : ""}`} to={`/${paymentsMenu[0]}`}>Add new Payments</Link></li>
                )
              }

              <li><Link className={`${path === paymentsMenu[1] ? "mm-active" : ""}`} to={`/${paymentsMenu[1]}`}>View Payments List</Link></li>
            </ul>
          </li>

          <li className={`${eventMenu.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >

              <i className="flaticon-381-note"></i><span className="nav-text">Events</span>
            </Link>
            <ul>

              <li><Link className={`${path === eventMenu[0] ? "mm-active" : ""}`} to={`/${eventMenu[0]}`}>Events Calendar</Link></li>
              <li><Link className={`${path === eventMenu[1] ? "mm-active" : ""}`} to={`/${eventMenu[1]}`}>Events List</Link></li>
            </ul>
          </li>
          <li className={`${campaignMenu.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >

              <i className="flaticon-381-notification"></i><span className="nav-text">Campaigns</span>
            </Link>
            <ul>

              <li><Link className={`${path === campaignMenu[0] ? "mm-active" : ""}`} to={`/${campaignMenu[0]}`}>Add new Campaign</Link></li>
              <li><Link className={`${path === campaignMenu[1] ? "mm-active" : ""}`} to={`/${campaignMenu[1]}`}>Existing Campaigns</Link></li>
            </ul>
          </li>


          <li className={`${reportMenu.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" >
              <i className="flaticon-381-calendar-1"></i><span className="nav-text">Reports</span>
            </Link>

            <ul>
              <li><Link className={`${path === reportMenu[0] ? "mm-active" : ""}`} to={`/${reportMenu[0]}`}>Donation Reports</Link></li>
              <li><Link className={`${path === reportMenu[1] ? "mm-active" : ""}`} to={`/${reportMenu[1]}`}>Donors Reports</Link></li>

            </ul>

          </li>


          <li className={`${settingsMenu.includes(path) ? "mm-active" : ""}`}>
            <Link className="ai-icon" to={`/${settingsMenu[0]}`} >
              <i className="flaticon-381-settings-1"></i><span className="nav-text">Settings </span>
            </Link>

          </li>

        </MM>
        {/* <div className="copyright">
          <p><strong>Echo Kitchen Admin Dashboard</strong> © 2022 All Rights Reserved</p>
          <p className="fs-12">Developed with <span className="heart"></span> by VinoCrazy</p>
        </div> */}
      </PerfectScrollbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: getUserMe(state),
  };
};

export default connect(mapStateToProps)(SideBar);
