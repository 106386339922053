import React, { useEffect, useState } from 'react'
import Select from "react-select";


const SelectCampaignWithEvent = ({ onSelect, campaignData }) => {

    const [campaignOptions, setCampaignOptions] = useState([])
    const [selectedCampaign, setSelectedCampaign] = useState();
    const [selectEvent, setSelectEvent] = useState()

    const [eventOptions, setEventOptions] = useState([])

    // console.log({ selectedCampaign });



    useEffect(() => {
        setCampaignOptions(campaignData.map((campaign) => ({
            value: campaign.lable,
            label: campaign.lable,
        })))

        setSelectedCampaign(campaignData[0]);



    }, [campaignData])

    useEffect(() => {
        if (selectedCampaign) {
            setEventOptions(selectedCampaign?.events.map((event) => ({
                value: event.lable,
                label: event.lable,
            })))

            setSelectEvent(selectedCampaign?.events[0])
        }

    }, [selectedCampaign])

    useEffect(() => {
        if (onSelect) {
            if (selectedCampaign && selectEvent) {
                onSelect({
                    event: selectEvent?.value,
                    campaign: selectedCampaign?.value
                })
            }
        }
    }, [selectEvent, selectedCampaign])



    const onChangeEvent = (data) => {
        // console.log({ data });

        setSelectEvent(selectedCampaign?.events.find((item) => item?.lable == data.value));

    }

    const onChangeCampaign = (data) => {
        // console.log({ data });
        setSelectedCampaign(campaignData.find((item) => item?.lable === data.value));

    }


    return (
        <>
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group mb-3">
                        <label htmlFor="">Select Campaign</label>
                        {

                            <Select
                                onChange={onChangeCampaign}
                                options={campaignOptions}
                                style={{
                                    lineHeight: "40px",
                                    color: "#7e7e7e",
                                    paddingLeft: " 15px",
                                }}
                                placeholder="Select Campaign"
                                name='event_type'
                                value={
                                    campaignOptions.filter(option =>
                                        option.label === selectedCampaign.lable)
                                }

                            />

                        }

                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group mb-3">
                        <label htmlFor="">Select Event</label>
                        <Select
                            // defaultValue={selectedOption}
                            onChange={onChangeEvent}
                            options={eventOptions}
                            style={{
                                lineHeight: "40px",
                                color: "#7e7e7e",
                                paddingLeft: " 15px",
                            }}
                            placeholder="Select Event"
                            name='event_type'
                            value={
                                eventOptions.filter(option =>
                                    option.label === selectEvent.lable)
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelectCampaignWithEvent