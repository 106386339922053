import React, { useContext, useEffect } from 'react';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";


//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import { getDashBoard } from '../../../helper/BackendHelper';
import { useState } from 'react';
import PageLoader from '../../layouts/PageLoader';
import { curencyFormater, curencyFormaterNarmal, numberFormater } from '../../../helper/formater';
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from 'moment';
import { connect } from 'react-redux';
import { getUserMe } from '../../../store/selectors/UserSelectors';
import { isAdmin } from '../../../helper/roleHelper';
import { Link } from 'react-router-dom';



const DashBoard = ({ user }) => {
	const { changeBackground } = useContext(ThemeContext);
	const [isLoading, setIsLoading] = useState(false);


	useEffect(() => {
		changeBackground({ value: "light", label: "Light" });
	}, []);



	const [dashBoardData, setDashBoardData] = useState();

	useEffect(() => {
		setIsLoading(true)
		getDashBoard().then((res) => {
			if (res?.status === 'success') {
				setDashBoardData(res?.data)
				setIsLoading(false)

			}
		})

	}, [])

	console.log({ dashBoardData });


	return (
		<>
			<PageLoader isLoading={isLoading} >
				{
					dashBoardData && (<div className="row">
						<div className="col-xl-12">
							<div className="row">

								<div className="col-xl-4 col-sm-6">
									<div className="card overflow-hidden">
										<Link to={`/list-of-donations`}>
											<div className="card-header border-0">
												<div className="d-flex">
													<span className="mt-1">
														<svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path fillRule="evenodd" clipRule="evenodd" d="M17.812 48.64L11.2 53.6C10.594 54.054 9.78401 54.128 9.10602 53.788C8.42802 53.45 8.00002 52.758 8.00002 52V16C8.00002 14.896 8.89602 14 10 14H38C39.104 14 40 14.896 40 16V52C40 52.758 39.572 53.45 38.894 53.788C38.216 54.128 37.406 54.054 36.8 53.6L30.188 48.64L25.414 53.414C24.634 54.196 23.366 54.196 22.586 53.414L17.812 48.64ZM36 48V18H12V48L16.8 44.4C17.596 43.802 18.71 43.882 19.414 44.586L24 49.172L28.586 44.586C29.29 43.882 30.404 43.802 31.2 44.4L36 48ZM22 34H26C27.104 34 28 33.104 28 32C28 30.896 27.104 30 26 30H22C20.896 30 20 30.896 20 32C20 33.104 20.896 34 22 34ZM18 26H30C31.104 26 32 25.104 32 24C32 22.896 31.104 22 30 22H18C16.896 22 16 22.896 16 24C16 25.104 16.896 26 18 26Z" fill="#44814E" />
															<circle cx="43.5" cy="14.5" r="12.5" fill="#09BD3C" stroke="white" strokeWidth="4" />
														</svg>
													</span>
													<div className="invoices">
														<h4>{curencyFormaterNarmal.format(dashBoardData?.donetionPaid)}</h4>
														<span>Donation Paid </span>
													</div>
												</div>
											</div>
										</Link>

									</div>
								</div>
								<div className="col-xl-4 col-sm-6">
									<div className="card overflow-hidden">
										<Link to={`/list-of-donations`}>
											<div className="card-header border-0">
												<div className="d-flex">
													<span className="mt-1">
														<svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path fillRule="evenodd" clipRule="evenodd" d="M17.812 48.64L11.2 53.6C10.594 54.054 9.78401 54.128 9.10602 53.788C8.42802 53.45 8.00002 52.758 8.00002 52V16C8.00002 14.896 8.89602 14 10 14H38C39.104 14 40 14.896 40 16V52C40 52.758 39.572 53.45 38.894 53.788C38.216 54.128 37.406 54.054 36.8 53.6L30.188 48.64L25.414 53.414C24.634 54.196 23.366 54.196 22.586 53.414L17.812 48.64ZM36 48V18H12V48L16.8 44.4C17.596 43.802 18.71 43.882 19.414 44.586L24 49.172L28.586 44.586C29.29 43.882 30.404 43.802 31.2 44.4L36 48ZM22 34H26C27.104 34 28 33.104 28 32C28 30.896 27.104 30 26 30H22C20.896 30 20 30.896 20 32C20 33.104 20.896 34 22 34ZM18 26H30C31.104 26 32 25.104 32 24C32 22.896 31.104 22 30 22H18C16.896 22 16 22.896 16 24C16 25.104 16.896 26 18 26Z" fill="#44814E" />
															<circle cx="43.5" cy="14.5" r="12.5" fill="#FD5353" stroke="white" strokeWidth="4" />
														</svg>

													</span>
													<div className="invoices">
														<h4>{curencyFormaterNarmal.format(dashBoardData?.donetionUnpaid)}</h4>
														<span>Donation Unpaid</span>
													</div>
												</div>
											</div>
										</Link>

									</div>
								</div>
								<div className="col-xl-4 col-sm-6">
									<div className="card overflow-hidden">
										<Link to={`/list-of-donations`}>
											<div className="card-header border-0">
												<div className="d-flex">
													<span className="mt-1">
														<svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path fillRule="evenodd" clipRule="evenodd" d="M17.812 48.64L11.2 53.6C10.594 54.054 9.784 54.128 9.106 53.788C8.428 53.45 8 52.758 8 52V16C8 14.896 8.896 14 10 14H38C39.104 14 40 14.896 40 16V52C40 52.758 39.572 53.45 38.894 53.788C38.216 54.128 37.406 54.054 36.8 53.6L30.188 48.64L25.414 53.414C24.634 54.196 23.366 54.196 22.586 53.414L17.812 48.64ZM36 48V18H12V48L16.8 44.4C17.596 43.802 18.71 43.882 19.414 44.586L24 49.172L28.586 44.586C29.29 43.882 30.404 43.802 31.2 44.4L36 48ZM22 34H26C27.104 34 28 33.104 28 32C28 30.896 27.104 30 26 30H22C20.896 30 20 30.896 20 32C20 33.104 20.896 34 22 34ZM18 26H30C31.104 26 32 25.104 32 24C32 22.896 31.104 22 30 22H18C16.896 22 16 22.896 16 24C16 25.104 16.896 26 18 26Z" fill="#44814E" />
															<circle cx="43.5" cy="14.5" r="12.5" fill="#FFAA2B" stroke="white" strokeWidth="4" />
														</svg>


													</span>
													<div className="invoices">
														<h4>{curencyFormaterNarmal.format(dashBoardData?.donetionRaised)}</h4>
														<span>Donation Raised</span>
													</div>
												</div>
											</div>
										</Link>
									</div>
								</div>
								<div className="col-xl-4 col-sm-6">
									<Link to={`/list-of-donors`}>
										<div className="widget-stat card bg-primary">
											<div className="card-body  p-4">
												<div className="media">
													<span className="me-3">
														<i className="la la-users"></i>
													</span>
													<div className="media-body text-white text-end">
														<p className="mb-1">Total Donors Registered</p>
														<h3 className="text-white">{numberFormater.format(dashBoardData?.donersCount)}</h3>
													</div>
												</div>
											</div>
										</div>
									</Link>
								</div>
								<div className="col-xl-4 col-sm-6">
									<Link to={`/list-of-payments`}>
										<div className="widget-stat card">
											<div className="card-body  p-4">
												<div className="media ai-icon">
													<span className="me-3 bgl-danger text-danger">
														<svg
															id="icon-revenue"
															xmlns="http://www.w3.org/2000/svg"
															width="30"
															height="30"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
															className="feather feather-dollar-sign"
														>
															<line x1="12" y1="1" x2="12" y2="23"></line>
															<path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
														</svg>
													</span>
													<div className="media-body">
														<p className="mb-1">Total Unknown Payments</p>
														<h4 className="mb-0">{curencyFormaterNarmal.format(dashBoardData?.unknownPayment?.amount)}</h4>
														<span className="badge badge-danger">{numberFormater.format(dashBoardData?.unknownPayment?.total)}</span>
													</div>
												</div>
											</div>
										</div>
									</Link>
								</div>
								{/* <div className="col-xl-4 col-sm-6">
									<div className="widget-stat card">
										<div className="card-body p-4">
											<div className="media ai-icon">
												<span className="me-3 bgl-primary text-primary">
													<i className="fa fa-utensils"></i>

												</span>
												<div className="media-body">
													<p className="mb-1">Total Meals pledged</p>
													<h4 className="mb-0">{numberFormater.format(dashBoardData?.pledgedMeal?.total)}</h4>
													<span className="badge badge-primary">{curencyFormater.format(dashBoardData?.pledgedMeal?.amount)}</span>
												</div>
											</div>
										</div>
									</div>
								</div> */}
							</div>
						</div>

						<div className="col-xl-4 col-xxl-6 col-lg-6">
							<div className="row">
								{
									isAdmin(user?.role) && (
										<div className="col-xl-12">
											<div className="card">
												<div className="card-header border-0 pb-0">
													<h4 className="card-title">Recent activities</h4>
												</div>
												<div className="card-body">
													<PerfectScrollbar
														style={{ height: "370px" }}
														id="DZ_W_TimeLine"
														className="widget-timeline dlab-scroll height370 ps ps--active-y"
													>
														<ul className="timeline">

															{
																dashBoardData?.lastLoggedActivity?.map((log, index) => (
																	<li key={index}>
																		<div className="timeline-badge primary"></div>
																		<div
																			className="timeline-panel text-muted"

																		>
																			<span>{moment(log?.created_at).fromNow()}</span>
																			<h6 className="mb-0">
																				{log?.description}
																			</h6>
																		</div>
																	</li>
																))
															}



														</ul>
													</PerfectScrollbar>
												</div>
											</div>
										</div>
									)
								}

							</div>
						</div>

					</div>)

				}
			</PageLoader>

		</>
	)
}


const mapStateToProps = (state) => {
	return {
		user: getUserMe(state)
	};
};

export default connect(mapStateToProps)(DashBoard);

