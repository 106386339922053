import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { getDoner, getDonersOptions } from '../../../helper/BackendHelper';
import Select from "react-select";


const DonerSelect = ({ isOpen, onSelelct, close }) => {

  const [donerList, setDonerList] = useState([]);
  const [donerOptions, setDonerOptions] = useState([]);

  useEffect(() => {

    getDonersOptions().then((res) => {

      if (res?.status == "success") {
        setDonerList(res?.data)
      }

    })

  }, [])

  useEffect(() => {
    setDonerOptions(donerList.map((doner) => ({
      value: doner.lable,
      label: doner.lable,
    })))

  }, [donerList])

  const onDonerSelect = (data) => {
    let selectedDonerID = donerList.find((item) => item?.lable == data.value)?.value;
    getDoner(selectedDonerID).then((res) => {

      if (res?.status == "success") {
        if (onSelelct) {
          onSelelct(res?.data);
        }
      }

    })
  }


  return (
    <>
      <Modal className="fade" show={isOpen} onHide={close}>
        <Modal.Header>
          <Modal.Title>Select Doners</Modal.Title>
          <Button onClick={close} variant="" className="btn-close"></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="donner-select">
              <Select
                // defaultValue={selectedOption}
                onChange={onDonerSelect}
                options={donerOptions}
                style={{
                  lineHeight: "40px",
                  color: "#7e7e7e",
                  paddingLeft: " 15px",
                }}
                placeholder="Select Existing Donor"
                name='doner'
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>

          <Button onClick={close} variant="primary">Select</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DonerSelect