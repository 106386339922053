import React, { useEffect, useState } from 'react'
import { getMealTime, updateMealTime } from '../../../helper/BackendHelper'
import { notifyError, notifySuccess } from '../../../helper/notification'

const MealEditForm = () => {

    const [mealTimeList, setMealTimeList] = useState([])

    useEffect(() => {
        getMealTime()?.then((res) => {

            if (res?.status === "success") {
                setMealTimeList(res?.data)
            }
        })
    }, [])

    // console.log({ mealTimeList });

    return (
        <div>
            {
                mealTimeList?.map((meal) => (
                    <div className="row">
                        <MealItem meal={meal} />
                    </div>
                ))
            }

        </div>
    )
}



const MealItem = ({ meal }) => {

    const [Price, setPrice] = useState(meal?.price)
    const [isloading, setIsloading] = useState(false)

    const onUpdate = async (e) => {
        e.preventDefault();
        setIsloading(true)

        const res = await updateMealTime(parseInt(Price), meal?.id);

        if (res?.status === 'success') {
            notifySuccess(res?.message);

            // swal(`${res?.message}`, ``, "success")
            setIsloading(false)


        } else {

            if (res.isAxiosError) {

                if (res?.response?.data?.errors) {
                    Object.values(res?.response?.data?.errors).map((errors) => errors.map((error) => notifyError(error)))
                } else {
                    notifyError(res?.response?.data?.message)
                }
            } else {
                notifyError(res?.message);
            }

            // console.log({ res });

            // swal("Oops", `${res?.response?.data?.message ? res?.response?.data?.message : res?.message}`, "error")
            setIsloading(false)

        }

    }


    return (
        <>
            <div className="col-lg-4">
                <p>{meal?.name}</p>
            </div>
            <div className="col-lg-4">
                <div className="input-group mb-3">
                    <div className="input-group-text">₹</div>
                    <input
                        type="number"
                        className="form-control input-default "
                        placeholder="Price"
                        name='Price'
                        value={Price}
                        onChange={(e) => setPrice(parseInt(e?.currentTarget?.value))}

                    />
                </div>

            </div>
            <div className="col-lg-4">
                <button className="btn btn-primary" type="submit" onClick={onUpdate}>{isloading ? "Updating...." : "Update"}</button>
            </div>
        </>
    )
}


export default MealEditForm