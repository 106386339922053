import React, { Fragment } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap'
import { getMealsTable } from '../../../helper/BackendHelper';
import { curencyFormater } from '../../../helper/formater';
import PageTitle from '../../layouts/PageTitle'

const MenuTable = () => {

    const [mealsData, setMealsData] = useState([]);


    useEffect(() => {

        getMealsTable().then((res) => {

            if (res?.status == "success") {
                setMealsData(res?.data)
            }

        })

    }, [])

    // console.log({ mealsData });

    return (
        <Fragment>
            {
                mealsData?.map((meal, index) => (
                    <>
                        <h3>{meal?.name}</h3>
                        <br />
                        <Row key={index}>
                            {
                                meal?.menus?.map((menu, i) => (
                                    <Col lg={6}>
                                        <Card>
                                            <Card.Header>
                                                <Card.Title>{menu?.name}</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Table responsive>
                                                    <thead>
                                                        <tr>

                                                            <th>
                                                                <strong>Name</strong>
                                                            </th>
                                                            <th className="width80">
                                                                <strong>Unit</strong>
                                                            </th>
                                                            <th className="width80">
                                                                <strong>Price</strong>
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            menu?.items?.map((item, j) => (
                                                                <tr>
                                                                    <td>{item?.name}</td>
                                                                    <td>{item?.units}</td>
                                                                    <td>{curencyFormater.format(item?.price)}</td>
                                                                </tr>
                                                            ))
                                                        }


                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))
                            }

                        </Row>
                    </>

                ))
            }

        </Fragment>
    )
}

export default MenuTable