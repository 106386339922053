import React from 'react'
import { useState } from 'react'
import { changePassword } from '../../../helper/BackendHelper'
import { notifyError, notifySuccess } from '../../../helper/notification'

const PasswordChangeForm = () => {

    const [formData, setformData] = useState({})
    const [isloading, setIsloading] = useState(false)

    const onChangeValue = (e) => {
        setformData({ ...formData, [e?.currentTarget?.name]: e?.currentTarget?.value })
    }

    const onFormSubmit = async (e) => {
        e.preventDefault();
        setIsloading(true)

        const res = await changePassword(formData);

        if (res?.status === 'success') {
            notifySuccess(res?.message);

            // swal(`${res?.message}`, ``, "success")
            setIsloading(false)


        } else {

            if (res.isAxiosError) {

                if (res?.response?.data?.errors) {
                    Object.values(res?.response?.data?.errors).map((errors) => errors.map((error) => notifyError(error)))
                } else {
                    notifyError(res?.response?.data?.message)
                }
            } else {
                notifyError(res?.message);
            }

            // console.log({ res });

            // swal("Oops", `${res?.response?.data?.message ? res?.response?.data?.message : res?.message}`, "error")
            setIsloading(false)

        }

    }

    return (
        <form onSubmit={onFormSubmit}>
            <fieldset disabled={isloading}>
                <div className="row">
                    <div className="form-group mb-3 col-md-6">
                        <label className="form-label" >Email</label>
                        <input type="email" placeholder="Email" name='email' className="form-control" required onChange={onChangeValue} />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                        <label className="form-label">Password</label>
                        <input type="password" placeholder="Password" name='password' className="form-control" required onChange={onChangeValue} />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                        <label className="form-label">New Password</label>
                        <input type="password" placeholder="New Password" name='newpassword' className="form-control" required onChange={onChangeValue} />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                        <label className="form-label">Confirm Password</label>
                        <input type="password" placeholder="Confirm Password" name='newpassword_confirmation' className="form-control" required onChange={onChangeValue} />
                    </div>
                </div>

                <button className="btn btn-primary" type="submit">Change Password</button>
            </fieldset>

        </form>
    )
}

export default PasswordChangeForm