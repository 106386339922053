import moment from 'moment/moment';
import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { getCampaignById } from '../../../helper/BackendHelper';
import PageLoader from '../../layouts/PageLoader';
import PageTitle from '../../layouts/PageTitle';
import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap';


const CampaignView = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [campaignData, setCampaignData] = useState();

    const router = useHistory();

    const { id } = useParams();


    useEffect(() => {
        if (id) {
            setIsLoading(true)
            getCampaign(id);
        }
    }, [id])

    const getCampaign = (id) => {
        getCampaignById(id).then((res) => {
            if (res?.status === 'success') {
                setCampaignData(res?.data)
                setIsLoading(false)

            }
        })
    }

    // console.log({ campaignData });

    return (
        <Fragment>
            <PageLoader isLoading={isLoading} >
                {/* <PageTitle activeMenu="Campaign View" motherMenu="Campaigns" /> */}
                {
                    campaignData && (
                        <div className="row">
                            <div className="col-lg-12">

                                <div className="card">
                                    <div className="card-body pb-3">
                                        <div className="row align-items-center">
                                            <div className="col-xl-3 mb-3">
                                                <p className="mb-2 fs-16 font-w600">Campaign Name</p>
                                                <h2 className="mb-0 fs-32 font-w800">{campaignData?.name}</h2>
                                            </div>
                                            <div className="col-xl-9 d-flex flex-wrap justify-content-between align-items-center">
                                                <div className="d-flex me-3 mb-3 ms-2 align-items-start payment">
                                                    <div>
                                                        <p className="mb-2 fs-16 font-w600">Campaign Start</p>
                                                        <h4 className="mb-0 fs-18 font-w700">{campaignData?.created_at ? moment(campaignData?.start_date).format("MMM DD, YYYY") : null}</h4>
                                                    </div>
                                                </div>
                                                <div className="d-flex me-3 mb-3 ms-2 align-items-start payment">
                                                    <div>
                                                        <p className="mb-2 fs-16 font-w600">Campaign End</p>
                                                        <h4 className="mb-0 fs-18 font-w700">{campaignData?.created_at ? moment(campaignData?.end_date).format("MMM DD, YYYY") : null}</h4>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-3">
                                                    <Link to={`/campaign-edit/${campaignData?.id}`} className="btn btn-primary"><i className="las la-share scale5 me-3"></i>Edit this Campaign</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="card-body pb-3 transaction-details d-flex flex-wrap justify-content-between align-items-center">

                                        <div className="amount-bx mb-3 border">
                                            <i className="fas fa-rupee-sign"></i>
                                            <div>
                                                <p className="mb-1">Total Amount raised this campaign</p>
                                                <h3 className="mb-0">₹ {campaignData?.totalDonationAmount}</h3>
                                            </div>
                                        </div>



                                    </div> */}
                                </div>


                            </div>
                            <div className="col-lg-6">
                                <div className="card">
                                    <div class="card-header"><div class="card-title h5">Events</div></div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="report-table" >
                                                    <Table responsive >
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <strong>#</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>Name</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>Date</strong>
                                                                </th>


                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                campaignData?.events?.map((event, index) => (
                                                                    <tr>
                                                                        <td>
                                                                            <strong>{index + 1}</strong>
                                                                        </td>
                                                                        <td>{event?.name}</td>
                                                                        <td>{moment(event?.date).format('DD MMMM YYYY')}</td>

                                                                        <td>

                                                                            <div className="d-flex">
                                                                                <Link
                                                                                    to={`/event-edit/${event?.id}`}
                                                                                    className="btn btn-primary shadow btn-xs sharp me-1"
                                                                                >
                                                                                    <i className="fas fa-share"></i>
                                                                                </Link>

                                                                            </div>

                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }



                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-6">
                                <div className="card">
                                    <div class="card-header"><div class="card-title h5">Donations</div></div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="report-table" >
                                                    <Table responsive >
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <strong>#</strong>
                                                                </th>

                                                                <th>
                                                                    <strong>DATE</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>STATUS</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>PRICE</strong>
                                                                </th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                campaignData?.donations?.map((donation) => (
                                                                    <tr>
                                                                        <td>
                                                                            <strong>{donation.id}</strong>
                                                                        </td>

                                                                        <td>{moment(donation.created_at).format('DD MMMM YYYY')}</td>
                                                                        <td>
                                                                            {donation.is_paid ? ("Payment recived") : ("Payment not recived")}
                                                                        </td>
                                                                        <td>₹{donation.amount}</td>
                                                                        <td>

                                                                            <div className="d-flex">
                                                                                <Link
                                                                                    to={`/donation-details/${donation.id}`}
                                                                                    className="btn btn-primary shadow btn-xs sharp me-1"
                                                                                >
                                                                                    <i className="fas fa-share"></i>
                                                                                </Link>

                                                                            </div>

                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }



                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="col-lg-6">
                                <div className="card">
                                    <div class="card-header"><div class="card-title h5">Subscribed Donors</div></div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="report-table" >
                                                    <Table responsive >
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <strong>#</strong>
                                                                </th>

                                                                <th>
                                                                    <strong>NAME</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>EMAIL</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>PHONE</strong>
                                                                </th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                campaignData?.subscribers?.map((subscriber) => (
                                                                    <tr>
                                                                        <td>
                                                                            <strong>{subscriber.id}</strong>
                                                                        </td>

                                                                        <td>{subscriber.name}</td>
                                                                        <td>
                                                                            {subscriber.email}
                                                                        </td>
                                                                        <td>{subscriber.phone}</td>
                                                                        <td>

                                                                            <div className="d-flex">
                                                                                <Link
                                                                                    to={`/donor-details/${subscriber.id}`}
                                                                                    className="btn btn-primary shadow btn-xs sharp me-1"
                                                                                >
                                                                                    <i className="fas fa-share"></i>
                                                                                </Link>

                                                                            </div>

                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }



                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div> */}
                        </div>
                    )
                }
            </PageLoader>
        </Fragment>
    )
}

export default CampaignView