import React, { Fragment, useEffect, useState } from 'react'
import PageLoader from '../../layouts/PageLoader'
import PageTitle from '../../layouts/PageTitle'
import moment from 'moment';
import DatePicker from "react-datepicker";
import { getCampaignById, updateCampaign } from '../../../helper/BackendHelper';
import swal from 'sweetalert';
import { Accordion, Button, Form } from 'react-bootstrap';
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useHistory, useParams } from 'react-router-dom';
import { notifyError } from '../../../helper/notification';



const initialData = {
    events: [
        {
            date: moment().format("YYYY-MM-DD")
        }
    ],
    start_date: moment().startOf('month').format('YYYY-MM-DD'),
    end_date: moment().endOf('month').format('YYYY-MM-DD')
};

const CampaignEdit = () => {

    const [formData, setformData] = useState(initialData);
    const [isLoading, setIsLoading] = useState(false);
    const [campaignData, setCampaignData] = useState();

    const router = useHistory();

    const { id } = useParams();


    useEffect(() => {
        if (id) {
            setIsLoading(true)
            getCampaign(id);
        }
    }, [id])

    const getCampaign = (id) => {
        getCampaignById(id).then((res) => {
            if (res?.status === 'success') {
                setCampaignData(res?.data)
                setIsLoading(false)

            }
        })
    }

    useEffect(() => {
        if (campaignData) {
            setformData(campaignData);
        }
    }, [campaignData])


    // console.log({ campaignData });




    const onChangeValue = (e) => {
        setformData({ ...formData, [e?.currentTarget?.name]: e?.currentTarget?.value })
    }


    const onEventDateSelect = (date, i) => {


        let newArray = formData?.events ? [...formData?.events] : []
        newArray[i] = newArray[i] ? { ...newArray[i], date: moment(date).format("YYYY-MM-DD") } : { date: moment(date).format("YYYY-MM-DD") };

        setformData({ ...formData, events: newArray })
    }

    const formSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        console.log({ formData });

        const res = await updateCampaign(id, formData);

        if (res?.status === 'success') {

            swal(`Campaigns Updated`, ``, "success")
            setIsLoading(false);
            router.push('/existing-campaigns')

        } else {

            if (res.isAxiosError) {

                if (res?.response?.data?.errors) {
                    Object.values(res?.response?.data?.errors).map((errors) => errors.map((error) => notifyError(error)))
                } else {
                    notifyError(res?.response?.data?.message)
                }
            } else {
                swal("Oops", `${res?.response?.data?.message ? res?.response?.data?.message : res?.message}`, "error")
            }
            setIsLoading(false);

        }

    }

    const eventDataChangeHandle = (value, name, i) => {

        let newArray = formData?.events ? [...formData?.events] : []
        newArray[i] = newArray[i] ? { ...newArray[i], [name]: value } : { [name]: value };
        setformData({ ...formData, events: newArray })

    }

    const removeThisEvent = (i) => {
        let newArray = [...formData?.events];
        newArray.splice(i, 1);
        setformData({ ...formData, events: newArray })

    }

    // console.log({ formData });



    return (
        <Fragment>
            <PageLoader isLoading={isLoading} >
                <div className="h-80">
                    {/* <PageTitle activeMenu="Calerdar" motherMenu="Event" /> */}
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="card">
                                <div className="card-header container-fluid" style={{ display: "block" }}>
                                    <div class="row">
                                        <div class="col-md-10">
                                            <h4 className="card-title">{(formData?.name && formData?.name !== "") ? formData?.name : "Campaign"} Details</h4>
                                        </div>

                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="basic-form">
                                        <form onSubmit={formSubmit}>
                                            <fieldset disabled={isLoading}>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="">Enter Campaign Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control input-default "
                                                                placeholder={"Name of the Campaign"}
                                                                name={`name`}
                                                                required
                                                                onChange={onChangeValue}
                                                                value={formData?.name}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <label htmlFor="">Select Campaign Date</label>
                                                        <div className="form-group mb-3">
                                                            <DateRangePicker initialSettings={{ startDate: moment(formData?.start_date).format('MM/DD/YYYY'), endDate: moment(formData?.end_date).format('MM/DD/YYYY') }} onCallback={(start, end, label) => {
                                                                setformData({
                                                                    ...formData,
                                                                    start_date: start?.format('YYYY-MM-DD'),
                                                                    endDate: end?.format('YYYY-MM-DD')
                                                                })
                                                            }} >
                                                                <input type="text" className="form-control input-daterange-timepicker" />
                                                            </DateRangePicker>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group mb-3">
                                                            <textarea
                                                                name={`description`}
                                                                className="form-control input-default "
                                                                placeholder={`Describe about Campaign `} rows="5"
                                                                onChange={onChangeValue}
                                                                required
                                                                value={formData?.description}

                                                            >
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group mb-3">
                                                            <Form.Check
                                                                type="switch"
                                                                id="isActive"
                                                                label="is Active"
                                                                onChange={(e) => setformData({
                                                                    ...formData,
                                                                    active: e.currentTarget.checked
                                                                })}
                                                                checked={formData?.active}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                                <Accordion className="accordion accordion-rounded-stylish accordion-bordered" defaultActiveKey="0">
                                                    {formData?.events.map((event, i) => (
                                                        <Accordion.Item key={i} eventKey={`${i}`}>
                                                            <Accordion.Header className="accordion-header accordion-header-primary">
                                                                {(event?.name !== "" && event?.name) ? event?.name : `Event ${i + 1}`}
                                                                <span className="accordion-header-indicator "></span>

                                                            </Accordion.Header>
                                                            <Accordion.Collapse eventKey={`${i}`} className="accordion__body">
                                                                <div className="accordion-body"><div className='event'>
                                                                    <div className="row">
                                                                        <div className="col-lg-6">
                                                                            <div className="form-group mb-3">
                                                                                <label htmlFor="">Select Event Name</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control input-default "
                                                                                    placeholder={"Name of the Event"}
                                                                                    name={`name`}
                                                                                    required
                                                                                    onChange={(e) => eventDataChangeHandle(e.currentTarget.value, e.currentTarget.name, i)}
                                                                                    value={event?.name}

                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-6">
                                                                            <label htmlFor="">Select Event Date</label>
                                                                            <div className="form-group mb-3">
                                                                                <DatePicker className="form-control" selected={new Date(event?.date)} onSelect={(date) => onEventDateSelect(date, i)} placeholderText="Select Event Date" dateFormat="yyyy-MM-dd" />
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                    <div className="row">

                                                                        <div className="col-lg-6">
                                                                            <div className="form-group mb-3">
                                                                                <textarea
                                                                                    name={`description`}
                                                                                    className="form-control input-default "
                                                                                    placeholder={`Event description`} rows="5"
                                                                                    onChange={(e) => eventDataChangeHandle(e.currentTarget.value, e.currentTarget.name, i)}

                                                                                    value={event?.description}

                                                                                >
                                                                                </textarea>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            {
                                                                                (formData?.events?.length - 1 == i)
                                                                                    ? (<Button variant="primary" onClick={() => setformData({
                                                                                        ...formData, events: [...formData?.events, {
                                                                                            date: moment().format("YYYY-MM-DD")
                                                                                        }]
                                                                                    })} >Add More Events</Button>)
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div></div>
                                                            </Accordion.Collapse>
                                                        </Accordion.Item>
                                                    ))}
                                                </Accordion>



                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <Button type='submit' variant="primary">{isLoading ? "Editing...." : "Edit Campaign"}</Button>

                                                    </div>

                                                </div>
                                            </fieldset>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageLoader>
        </Fragment>
    )
}

export default CampaignEdit