import React, { useEffect, useState } from 'react'
import { notifyError, notifySuccess } from '../../../helper/notification';
import { getConnect, imageUploadDhan } from '../../../helper/BackendHelper';
import { Button, Form } from 'react-bootstrap';
import moment from 'moment';

const baseUrl = process.env.REACT_APP_API_URL;


const DhanImageUplaod = ({ id, time }) => {

    const [isUploading, setIsUploading] = useState(false)
    const [imageFile, setImageFile] = useState();
    const [thisConnect, setThisConnect] = useState()

    console.log({ thisConnect });

    useEffect(() => {
        getImgs()
    }, [])

    const getImgs = () => {
        getConnect(id, time)?.then((res) => {
            // console.log({ res });
            setThisConnect(res?.data?.image)
        })
    }




    const uploadHandle = () => {

        if (imageFile) {
            setIsUploading(true)
            let fd = new FormData();
            fd.append('image', imageFile[0])

            imageUploadDhan(id, fd, time).then((res) => {

                if (res?.status === "success") {
                    // getEventData();
                    // console.log({ res });
                    getImgs()
                    notifySuccess(res?.message);
                    setIsUploading(false)
                } else {
                    // notifyError(res?.message);

                    if (res.isAxiosError) {

                        if (res?.response?.data?.errors) {
                            Object.values(res?.response?.data?.errors).map((errors) => errors.map((error) => notifyError(error)))
                        } else {
                            notifyError(res?.response?.data?.message)
                        }
                    } else {
                        notifyError(`${res?.response?.data?.message ? res?.response?.data?.message : res?.message}`)

                    }

                    // setIsLoading(false);


                }
            })
        }

    }


    return (
        <div>
            <Form.Label>Select Image for {moment(time)?.format('DD MMM YYYY')}</Form.Label>
            <div className="row">
                <div className="col-lg-8">
                    <Form.Group controlId="formFile" className="mb-3">

                        <Form.Control type="file" onChange={(e) => setImageFile(e.currentTarget.files)} />
                    </Form.Group>

                </div>
                <div className="col-lg-4">

                    <Button variant="primary" onClick={uploadHandle} disabled={isUploading} >{isUploading ? "Uploading" : "Upload"}</Button>
                </div>
            </div>

            {
                thisConnect && (

                    <div className="image-holder">
                        <picture>
                            <img src={`${baseUrl}/${thisConnect}`} alt="image" />
                        </picture>
                    </div>
                )


            }

        </div>
    )
}

export default DhanImageUplaod