import React, { Fragment, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Button, Dropdown, Form } from "react-bootstrap";




import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";


import Pagination from "react-js-pagination";
import { DonetionsIsLoading, DonetionsListWithPaggination } from "../../../store/selectors/DonationSelector";
import { DonetionInitialLoad, DonetionPaggination } from "../../../store/thunk/DonetionThunk";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import PageLoader from "../../layouts/PageLoader";
import DateRangePicker from "react-bootstrap-daterangepicker";



const Donetions = ({ donetions, isLoading }) => {

   // console.log({ donetions });

   const dispatch = useDispatch();

   const [searchQuary, setSearchQuary] = useState("");
   const [dateQuary, setDateQuary] = useState();
   const [statusQuary, setStatusQuary] = useState();

   useEffect(() => {
      dispatch(DonetionInitialLoad());

      // getDonetions().then((res) => console.log({ res }))

   }, [])

   useEffect(() => {
      if (dateQuary && statusQuary && searchQuary) {
         dispatch(DonetionPaggination({ q: searchQuary, pageNumber: 1, dateQuary, statusQuary }))
      } else if (dateQuary && statusQuary) {
         dispatch(DonetionPaggination({ pageNumber: 1, dateQuary, statusQuary }))
      } else if (searchQuary && statusQuary) {
         dispatch(DonetionPaggination({ q: searchQuary, pageNumber: 1, statusQuary }))
      } else if (statusQuary) {
         dispatch(DonetionPaggination({ q: searchQuary, pageNumber: 1, statusQuary }))
      } else if (dateQuary) {
         dispatch(DonetionPaggination({ q: searchQuary, pageNumber: 1, dateQuary }))
      } else {
         dispatch(DonetionPaggination({ q: searchQuary, pageNumber: 1 }))
      }


   }, [searchQuary, dateQuary, statusQuary])

   const clearFilter = () => {
      setSearchQuary("");
      setDateQuary()
      setStatusQuary()
   }

   // console.log((donetions));





   return (
      <Fragment>
         <PageLoader isLoading={isLoading} >

            {/* <PageTitle activeMenu="Donetions List" motherMenu="Donetions" /> */}

            <div className="row">
               <div className="col-lg-12">
                  <div className="card">
                     <div className="card-body">
                        <div className="row align-items-center">
                           <div className="col-lg-4">
                              <div className="input-group search-area">
                                 <input type="text"
                                    className={`form-control active`}
                                    placeholder=" Search by Name"
                                    onChange={(e) => setSearchQuary(e.currentTarget.value)}
                                    value={searchQuary}
                                 />
                                 <span className="input-group-text" >
                                    <i className="flaticon-381-search-2"></i>
                                 </span>
                              </div>
                           </div>
                           <div className="col-lg-3">
                              <div className="input-group">

                                 <DateRangePicker
                                    onCallback={(start, end, label) => {
                                       setDateQuary({
                                          fromDate: start?.format('YYYY-MM-DD'),
                                          toDate: end?.format('YYYY-MM-DD')
                                       })
                                    }}

                                    initialSettings={{
                                       autoUpdateInput: false,
                                       locale: {
                                          cancelLabel: 'Clear',
                                       },
                                    }}

                                    onApply={(event, picker) => {
                                       picker.element.val(
                                          picker.startDate.format('MM/DD/YYYY') +
                                          ' - ' +
                                          picker.endDate.format('MM/DD/YYYY')
                                       );
                                    }}

                                    onCancel={(event, picker) => {
                                       picker.element.val('');
                                    }}

                                 >
                                    <input type="text" className="form-control input-daterange-timepicker" placeholder="Select Date" />
                                 </DateRangePicker>
                              </div>
                           </div>
                           <div className="col-lg-3">
                              <div className="input-group">
                                 <Form.Check
                                    type="switch"
                                    id="isActive"
                                    label="View Paid Donation only"
                                    onChange={(e) => setStatusQuary(e.currentTarget.checked)}
                                    checked={statusQuary}
                                 />
                              </div>
                           </div>
                           <div className="col-lg-2">
                              <div className="input-group">

                                 <Button onClick={clearFilter}>Clear Filter</Button>
                              </div>
                           </div>
                        </div>
                        <br />
                        <div className="table-responsive">
                           <table className="table mb-0 table-striped">
                              <thead>
                                 <tr>

                                    <th>Name</th>
                                    <th>Amount</th>
                                    <th>Path of Payment</th>
                                    <th>Date & Time</th>
                                    <th>Payment method</th>
                                    <th>Status</th>
                                    <th></th>
                                 </tr>
                              </thead>
                              <tbody id="Donetions">

                                 {
                                    donetions?.data?.map((donetion, index) => {
                                       let path = donetion?.path ? donetion?.path : (donetion?.pledged_meal ? "MILESTONES" : donetion?.connect ? "CONNECT" : "MAGIC POT");
                                       return (
                                          <tr className="btn-reveal-trigger" key={index}>

                                             <td className="py-3">
                                                <Link to={`/donation-details/${donetion?.id}`}>
                                                   <div className="media d-flex align-items-center">

                                                      <div className="media-body">
                                                         <h5 className="mb-0 fs--1">
                                                            {donetion?.donate_by?.name}
                                                         </h5>
                                                      </div>
                                                   </div>
                                                </Link>
                                             </td>
                                             <td className="py-2">
                                                ₹{donetion?.amount}
                                             </td>
                                             <td className="py-2">
                                                {donetion?.path !== null && donetion?.path !== undefined && donetion?.magic_pot !== null ? donetion?.path : path}

                                             </td>
                                             <td className="py-2 ">

                                                {format(new Date(donetion?.created_at), "dd-MM-yyyy,  p")}
                                             </td>
                                             <td className="py-2 ">

                                                {donetion?.paymentmethod?.name}
                                             </td>
                                             <td className="py-2">
                                                {donetion?.is_paid ?
                                                   (<span className="badge badge-rounded badge-primary">Paid</span>) :
                                                   (<span className="badge badge-rounded badge-warning">Not Paid</span>)}
                                             </td>
                                             <td className="py-2">
                                                <Dropdown>
                                                   <Dropdown.Toggle variant="" className="btn btn-primary tp-btn-light sharp i-false">
                                                      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                         <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24"></rect>
                                                            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                                                            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                                                            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                                                         </g>
                                                      </svg>
                                                   </Dropdown.Toggle>
                                                   <Dropdown.Menu>

                                                      <Dropdown.Item ><Link to={`/donation-details/${donetion?.id}`} className="d-link">Process </Link></Dropdown.Item>


                                                      {/* <Dropdown.Item href="#" className="text-danger">
                                                      Delete
                                                   </Dropdown.Item> */}
                                                   </Dropdown.Menu>
                                                </Dropdown>
                                             </td>

                                          </tr>
                                       )
                                    })
                                 }

                              </tbody>
                           </table>
                        </div>
                        <br />
                        <div>
                           <Pagination
                              activePage={donetions?.current_page ? donetions?.current_page : 0}
                              itemsCountPerPage={donetions?.per_page ? donetions?.per_page : 0}
                              totalItemsCount={donetions?.total ? donetions?.total : 0}
                              onChange={(pageNumber) => {
                                 dispatch(DonetionPaggination({ q: searchQuary, pageNumber, date: dateQuary != null ? format(new Date(dateQuary), "yyyy-MM-dd") : "" }))
                              }}
                              pageRangeDisplayed={8}
                              itemClass="page-item"
                              linkClass="page-link"
                              firstPageText="First Page"
                              lastPageText="Last Page"
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </PageLoader>
      </Fragment>
   );
};


const mapStateToProps = (state) => {
   return {
      donetions: DonetionsListWithPaggination(state),
      isLoading: DonetionsIsLoading(state)
   };
};

export default connect(mapStateToProps)(Donetions);


